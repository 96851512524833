import React from 'react';
import {IconButton} from '@material-ui/core';

const ButtonDelete=({Icon,name,deleteFunction})=>{
    
    const deleteData=()=>{
        deleteFunction(name)
    }

    return(
        <IconButton onClick={deleteData}>
            {Icon}
        </IconButton>
    )
}

export default ButtonDelete;