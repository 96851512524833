import React from 'react'
import './index.css';

const Copyright=({children,color,backgroundColor})=>{
    return(
        <section className="copyright" style={{color:color,backgroundColor:backgroundColor}}>
            <div className="copyright-container">
                <div>
                    {children}
                    Copyright 2020. All Rights Reserved. Reaseguradora Patria S.A. Periférico Sur 2771, col.San Jerónimo Lidice 10200,CDMX,México
                </div>
            </div>
        </section>
    )
}

export default Copyright;