import React,{useState} from 'react';
import {NavLink} from 'react-router-dom';
import {NavNavigation,NavContainer,NavSesion,NavLinkSesion,NavMenu,NavLinkOverlay,NavOverlay} from '../StylesComponents/common-styled';
import CerrarSesion from '../CerrarSesion';
import IconMenu from '../IconMenu';
import './index.css';


const Homebar=({navigation})=>{
    const [classes,setClasees]=useState('')
    const [display,setDisplay]=useState('')
    const onClick=()=>{
        classes==='active'?setClasees(''):setClasees('active')
        display==='active-display'?setDisplay(''):setDisplay("active-display")
    }
    const styles={
        style:{
            color:"#fff"
        },
        activeStyle:{
            backgroundColor:"transparent",
            color:"yellow",
        }
    }
    return(
    <NavNavigation backgroundColor="#01539C">
        <NavContainer>
            <NavLink {...styles} exact to='/secciones'>
                <img src="/images/LogoPatriaW.png" alt="logo_patria" width={68}/>
            </NavLink>
            <div style={{width:"80%",display:"flex",justifyContent:"flex-end",alignItems:"center"}}>
                {navigation.map((nav,index)=>
                    nav.route!==""?
                    <NavSesion key={index} marginRight="20px">
                        <NavLink {...styles}exact to={nav.route}>
                            <NavLinkSesion >
                                {nav.title}
                            </NavLinkSesion>
                        </NavLink>
                    </NavSesion>:
                    <h4 key={index} style={{color:"#fff",marginRight:"20px"}}>{nav.title}</h4>
                )}
                <CerrarSesion/>
                {/*<ButtonStyled width="100px" height="35px" backgroundColor="#3898EC" color="#fff" borderRadius="3px">
                    Cerrar sesión
                </ButtonStyled>*/}
            </div>
            <NavMenu className={classes}>
                <div style={{fontSize:"30px"}} onClick={onClick}>
                    <IconMenu/>
                </div>
            </NavMenu>            
        </NavContainer>
        <NavOverlay className={display}>
            {navigation.map((nav,index)=>
                    nav.route!==""?
                    <nav key={index} style={{margin:"20px"}}>
                        <NavLink {...styles}exact to={nav.route}>
                            <NavLinkOverlay>
                                {nav.title}
                            </NavLinkOverlay>
                        </NavLink>
                    </nav>:
                    null
                )}
            <CerrarSesion className="button-menu"/>  
            {/*<ButtonStyled width="100px" height="35px" backgroundColor="#3898EC" color="#fff" borderRadius="3px" className="button-menu">
                Cerrar sesión
            </ButtonStyled>*/}
        </NavOverlay>
    </NavNavigation>
)}

export default Homebar;