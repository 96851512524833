import React from 'react';
import {Stepper,Step,StepButton,makeStyles} from '@material-ui/core';


const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  button: {
    marginRight: theme.spacing(1),
  },
  completed: {
    display: 'inline-block',
  },
}));




export default function HorizontalNonLinearStepper({activeStep,completed,handleComplete,getSteps,handleStep}) {
  const classes = useStyles();
  const steps = getSteps();

  


  return (
    <div className={classes.root}>
      <Stepper alternativeLabel nonLinear activeStep={activeStep}>
        {steps.map((label, index) => (
          <Step key={label}>
            <StepButton onClick={handleStep(index)} completed={completed[index]}>
              {label}
            </StepButton>
          </Step>
        ))}
      </Stepper>
    </div>
  );
}


