import React, { Fragment, useState, useMemo, useEffect } from 'react';
import ButtonNavigation from '../Viaticos/ButtonNavigation';
import { useMutation } from 'react-apollo';
import { DINAMIC_GET } from '../../mutation/mutation';
import DatePicker from 'react-date-picker';
import { NativeSelect, TextareaAutosize, Chip } from '@material-ui/core';
import Areas_Inv from './Areas_Inv';
import DivContainer from '../../layouts/Acuerdos/DivContainer';
import ImputResumen from './ImputResumen';
import '../components.css';
import { LabelCard } from '../StylesComponents/common-styled';
import { ArrowBackIos, Clear } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';

const Report = ({ onClick }) => {
    const [currentQuestion, setcurrentQuestion] = useState('');
    const [commentsAndTracing, setCommentsAndTracing] = useState('');
    const [steps, setSteps] = useState([]);
    const [disabledOne, setDisabledOne] = useState(true)
    const [disabledTwo, setDisabledTwo] = useState(true)
    
    const handleCommentsAndTracing = (option) => {
        switch (option) {
            case 'Resumen de Entorno':
                setCommentsAndTracing(option);
                setcurrentQuestion(option);
                break;
            case 'Resumen de Relación con Patria':
                setCommentsAndTracing(option);
                setcurrentQuestion(option);
                break;
            case 'Nuevo Seguimiento':
                onClick();
                break;

            default:
                break;
        }
    }

    const handleOnClickBack = () => {
        let index = steps.indexOf(currentQuestion);
        let step = index === 0 ? index : index - 1;
        if (index === 0) {
            setcurrentQuestion('');
            steps.splice(index, 1);
        } else {
            setcurrentQuestion(steps[step]);
            steps.splice(index, 1);
        }
    }
    useEffect(() => {
        if (currentQuestion !== "" && !steps.includes(currentQuestion)) setSteps([...steps, currentQuestion]);
    }, [currentQuestion])

    return (
        <Fragment>
            <div onClick={() => handleOnClickBack()} style={{ cursor: 'pointer' }}>
                <ArrowBackIos style={{ color: '#01539C' }} />
            </div>
            {currentQuestion === '' && (
                <DivContainer
                    title="Seguimiento"
                    subtitle="Revisa los comentarios y seguimiento registrados."
                >
                    {['Resumen de Entorno', 'Resumen de Relación con Patria', 'Nuevo Seguimiento']
                        .map((option, index) => (
                            <ButtonNavigation
                                text={option}
                                width="25%"
                                id="button-casual"
                                key={index}
                                onClick={() => handleCommentsAndTracing(option)}
                            />
                        ))}
                </DivContainer>
            )}
            {currentQuestion === 'Resumen de Entorno' && (
                <DivContainer
                    title="Seguimiento Relación con Patria"
                    subtitle="Resumen de Seguimiento sobre Relación con Patria"
                >
                    <ImputResumen
                        title="Comentarios de Relación con Patria:"
                        // subtitle={"subititlo"}
                        disabled={disabledOne}
                        setDisabled={() => setDisabledOne(!disabledOne)}
                        // value={answerByQuestion.commentOne}
                        setValue={(value) =>
                            // setAnswerByQuestion({ ...answerByQuestion, commentOne: value })
                            console.log(value)
                        }
                        flexDirection="column"
                    />
                    <ImputResumen
                        // title={generarReporte}
                        // status={answerByQuestion.status}
                        // subtitle={"subtitle2"}
                        // value={answerByQuestion.commentTwo}
                        // setValue={(value) => setAnswerByQuestion({ ...answerByQuestion, commentTwo: value })}
                        disabled={disabledTwo}
                        setDisabled={() => setDisabledTwo(!disabledTwo)}
                    />
                </DivContainer>
            )}
            {currentQuestion === 'Resumen de Relación con Patria' && (
                <DivContainer
                    title="Seguimiento Relación con Patria"
                    subtitle="Resumen de Seguimiento sobre Relación con Patria"
                >
                    <ImputResumen
                        title="Comentarios de Relación con Patria:"
                        // subtitle={"subititlo"}
                        disabled={disabledOne}
                        setDisabled={() => setDisabledOne(!disabledOne)}
                        // value={answerByQuestion.commentOne}
                        setValue={(value) =>
                            // setAnswerByQuestion({ ...answerByQuestion, commentOne: value })
                            console.log(value)
                        }
                        flexDirection="column"
                    />
                    <ImputResumen
                        // title={generarReporte}
                        // status={answerByQuestion.status}
                        // subtitle={"subtitle2"}
                        // value={answerByQuestion.commentTwo}
                        // setValue={(value) => setAnswerByQuestion({ ...answerByQuestion, commentTwo: value })}
                        disabled={disabledTwo}
                        setDisabled={() => setDisabledTwo(!disabledTwo)}
                    />
                </DivContainer>
            )}

        </Fragment>
    );
}

export default Report;