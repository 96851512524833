import { TextField, NativeSelect } from "@material-ui/core";
import React, { useState, useEffect, Fragment } from "react";
import { AcordeonContainerBody } from "../StylesComponents/common-styled";
import FormTypography from "./FormsTypography";
import { useQuery } from "react-apollo";
import { GETCOUNTRIES_SIREC } from "../../query/query";
import { BootstrapInput } from "../StylesComponents/material-ui-styled";

const InputTravel = ({
  placeholder,
  name,
  onChange,
  id,
  value,
  type,
  disabled,
}) => {
  return (
    <TextField
      fullWidth
      variant="outlined"
      placeholder={placeholder}
      name={name}
      onChange={onChange}
      id={id}
      value={value}
      type={type}
      disabled={disabled || false}
    />
  );
};

const SelectTravel = ({ id, name, onchangue, value }) => {
  const [cities, setCities] = useState([]);

  useQuery(GETCOUNTRIES_SIREC, {
    onCompleted({ idCatCountries }) {
      setCities(idCatCountries);
    },
  });
  if (id !== "0") {
    return (
      <NativeSelect
        input={<BootstrapInput />}
        id={id}
        name={name}
        value={value}
        onChange={(e) => {
          onchangue(e.target.value, e.target.name, e.target.id);
        }}
      >
        {cities &&
          cities.map((city, index) => (
            <option key={index} value={city.cPAI_NOMBRE}>
              {city.cPAI_NOMBRE}
            </option>
          ))}
      </NativeSelect>
    );
  } else return null;
};

const useFormTravels = ({
  title,
  text1,
  text2,
  text3,
  text4,
  text5,
  text6,
  text7,
  text8,
  id,
  handleChangue,
  valueInputs,
  handleChangueSelect,
  asistente,
}) => {
  return (
    <Fragment>
      <div style={{ marginTop: "20px" }}>
        <h3 style={{ textAlign: "center" }}>{title}</h3>
      </div>
      {valueInputs ? (
        <Fragment>
          <AcordeonContainerBody id="inputs-viaticos">
            <FormTypography text={text1 || "Origen"}>
              {asistente === true ? (
                <InputTravel
                  name="origen"
                  id={id}
                  value={valueInputs[id].origen}
                  onChange={handleChangue}
                  disabled={true}
                />
              ) : (
                <SelectTravel
                  name="origen"
                  id={id}
                  onchangue={handleChangueSelect}
                  value={valueInputs[id].origen}
                />
              )}
            </FormTypography>
            <FormTypography text={text2 || "Destino"}>
              {asistente === true ? (
                <InputTravel
                  name="destino"
                  id={id}
                  value={valueInputs[id].destino}
                  onChange={handleChangue}
                  disabled={true}
                />
              ) : (
                <SelectTravel
                  name="destino"
                  id={id}
                  onchangue={handleChangueSelect}
                  value={valueInputs[id].destino}
                />
              )}
            </FormTypography>
          </AcordeonContainerBody>
          <AcordeonContainerBody id="inputs-viaticos">
            <FormTypography text={text3 || "Aerolinea"}>
              <InputTravel
                placeholder="Ingresa el nombre de la aerolínea"
                name="aerolinea"
                onChange={handleChangue}
                id={id}
                value={valueInputs[id].aerolinea}
                disabled={asistente}
              />
            </FormTypography>
            <FormTypography text={text4 || "No. de vuelo"}>
              <InputTravel
                placeholder="Ingresa el número de vuelo"
                name="vuelo"
                onChange={handleChangue}
                id={id}
                value={valueInputs[id].vuelo}
                disabled={asistente}
              />
            </FormTypography>
          </AcordeonContainerBody>
          <AcordeonContainerBody id="inputs-viaticos">
            <FormTypography text={text5 || "URL cotización de vuelo"}>
              <InputTravel
                placeholder="Pega la url de la cotización"
                name="cotizacion"
                onChange={handleChangue}
                id={id}
                value={valueInputs[id].cotizacion}
                disabled={asistente}
              />
            </FormTypography>
            <FormTypography text={text6 || "Fecha de vuelo"}>
              <InputTravel
                placeholder="Agregar el horario de vuelo"
                name="fecha"
                onChange={handleChangue}
                id={id}
                type="date"
                value={valueInputs[id].fecha}
                disabled={asistente}
              />
            </FormTypography>
          </AcordeonContainerBody>
          <AcordeonContainerBody id="inputs-viaticos">
            <FormTypography text={text7 || "Horario de vuelo"}>
              <InputTravel
                placeholder="Agregar el horario de vuelo"
                name="horario"
                onChange={handleChangue}
                id={id}
                type="time"
                value={valueInputs[id].horario}
                disabled={asistente}
              />
            </FormTypography>
            <FormTypography text={text8 || "Precio de vuelo"}>
              <InputTravel
                placeholder="Ingresa el precio del vuelo"
                name="precio"
                onChange={handleChangue}
                id={id}
                value={valueInputs[id].precio}
                disabled={asistente}
                type="number"
              />
            </FormTypography>
          </AcordeonContainerBody>
        </Fragment>
      ) : null}
    </Fragment>
  );
};

export default useFormTravels;
