import React from 'react';
import {IconButton} from '@material-ui/core';

const DeleteEvent=({Icon,onClick,cREU_Id,cREU_IdOutlook})=>{
    const dispatchOnclick=()=>{
        onClick(cREU_Id,cREU_IdOutlook);
    }
    return(
        <IconButton onClick={dispatchOnclick}>
        {Icon}
        </IconButton>
    )
    
}
export default DeleteEvent;