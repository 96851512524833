import React, { useEffect, useState, useContext, Fragment } from "react";
import { Typography, Modal } from "@material-ui/core";
import {
  PageContainer,
  Subtitle,
  ContainerTwoInputs,
} from "../StylesComponents/common-styled";

import Card from "./Card";
import calendario from "../../assets/images/icons/calendario.svg";
import user from "../../assets/images/icons/usuario.svg";
import ButtonsInformacion from "./ButtonsInformacion";
import FormTravels from "./FormTravels";
import FormAlojamiento from "./FormAlojamiento";
import FormMateriales from "./FormMateriales";
import FormObservaciones from "./FormObservaciones";
import ReunionComplete from "./ReunionComplete";
import ButtonNavigation from "./ButtonNavigation";
import DialogViat from "./DialogViat";
import ModalUpdate from "./ModalUpdate";
import { getcTOBS_Id } from "../../funciones-auxiliares/viaticos";
import { useLazyQuery, useMutation } from "react-apollo";
import {
  GET_EVENTOSBYVIATICOS,
  GET_EVENTO_BY_CREU_ID,
  GET_INFOUSER_BYTYPE,
} from "../../query/query";
import {
  SAVE_VIATICOS,
  DYNAMIC_UPDATE,
  DYNAMIC_INSERT,
  FIRE_PUSH_NOTIFICATION,
} from "../../mutation/mutation";
import { AddCircle } from "@material-ui/icons";
import { green } from "@material-ui/core/colors";
import { MyContext } from "../Context";
import moment from "moment";

const useNewSolicitud = ({ cEMP_Id, handleComponent }) => {
  const [reuniones, setReuniones] = useState([]);
  const [data, setData] = useState({
    titulo: "",
    texto1: "",
    texto2: "",
    texto3: "",
  });
  const [data2, setData2] = useState({
    titulo: "",
    texto1: "",
    texto2: "",
    texto3: "",
  });
  const [value, setValue] = useState("Ida y vuelta");
  const [open, setOpen] = useState(false);
  const [updateModal, setUpdateModal] = useState(false);
  const [saveModal, setSaveModal] = useState(false);
  const [viaje, setViaje] = useState([
    {
      id: Math.floor(Math.random() * 100),
      origen: "",
      destino: "",
      aerolinea: "",
      vuelo: "",
      cotizacion: "",
      fecha: "",
      horario: "",
      precio: "",
    },
    {
      id: Math.floor(Math.random() * 100),
      origen: "",
      destino: "",
      aerolinea: "",
      vuelo: "",
      cotizacion: "",
      fecha: "",
      horario: "",
      precio: "",
    },
  ]);
  const [alojamiento, setAlojamiento] = useState([
    {
      hotel: "",
      urlCotizacion: "",
      fecha: "",
      precio: "",
    },
  ]);
  const [userNotificate, setUserNotificate] = useState([]);

  const [cTIP_Id, setCTIP_Id] = useState(1);
  const [getUsersNotificate] = useLazyQuery(GET_INFOUSER_BYTYPE, {
    onCompleted({ getUserByStatus }) {
      setUserNotificate(getUserByStatus);
    },
  });

  const [getEventsByViaticos] = useLazyQuery(GET_EVENTOSBYVIATICOS, {
    onCompleted({ getReunionbyViaticos }) {
      setReuniones(getReunionbyViaticos);
    },
  });

  const [getEventBycREU] = useLazyQuery(GET_EVENTO_BY_CREU_ID, {
    onCompleted({ getReunionBycREUID }) {
      setData({
        titulo: getReunionBycREUID.cREU_Titulo,
        texto1: `${getReunionBycREUID.cREU_FechaInicio}-${getReunionBycREUID.cREU_FechaFin}`,
        texto2: `${getReunionBycREUID.Pais}, ${getReunionBycREUID.Estado}`,
        texto3: getReunionBycREUID.cREU_TipoReunion,
      });
      setData2({
        titulo: getReunionBycREUID.Nombre_Empleado,
        texto1: `No. empleado ${getReunionBycREUID.cEMP_Id}`,
        texto2: "Suscripción",
        texto3: "Patria RE",
      });
    },
  });
  const context = useContext(MyContext);

  const setALojamientoAux = () => {
    setAlojamiento([
      {
        hotel: "",
        urlCotizacion: "",
        fecha: "",
        precio: "",
      },
    ]);
  };

  const handleTipoViatico = (tipo) => {
    setValue(tipo);
    let newViaje = [
      {
        origen: viaje[0].origen,
        destino: viaje[0].destino,
        aerolinea: viaje[0].aerolinea,
        vuelo: viaje[0].vuelo,
        cotizacion: viaje[0].cotizacion,
        fecha: viaje[0].fecha,
        horario: viaje[0].horario,
        precio: viaje[0].precio,
      },
    ];
    if (tipo === "Ida y vuelta") {
      if (context.state.viaticosProps.cVIT_Id !== "") {
        newViaje = [
          context.state.viaticosProps.viajes[0],
          context.state.viaticosProps.viajes[1],
        ];
      } else {
        newViaje = [
          ...newViaje,
          {
            origen: "",
            destino: "",
            aerolinea: "",
            vuelo: "",
            cotizacion: "",
            fecha: "",
            horario: "",
            precio: "",
          },
        ];
      }
      sessionStorage.setItem("cTIP_Id", 1);
      setViaje(newViaje);
      sessionStorage.setItem("travels", JSON.stringify(newViaje));
      setCTIP_Id(1);
    } else if (tipo === "Solo ida") {
      if (context.state.viaticosProps.cVIT_Id !== "") {
        newViaje = [context.state.viaticosProps.viajes[0]];
      }
      sessionStorage.setItem("cTIP_Id", 2);
      setViaje(newViaje);
      sessionStorage.setItem("travels", JSON.stringify(newViaje));
      setALojamientoAux();
      setCTIP_Id(2);
    } else {
      if (context.state.viaticosProps.cVIT_Id !== "") {
        newViaje = context.state.viaticosProps.viajes;
      }
      sessionStorage.setItem("cTIP_Id", 3);
      sessionStorage.setItem("travels", JSON.stringify(newViaje));
      setViaje(newViaje);
      setCTIP_Id(3);
    }
  };
  const isValidDate = (date) => {
    return moment(date).isValid();
  };

  const isValidYear = (date) => {
    const year = new Date().getFullYear();
    const dateYear = moment(date).year();

    if (dateYear < year) return false;
    else return true;
  };

  const handleChange = (e) => {
    if (e.target.name === "fecha") {
      if (isValidDate(e.target.value) && isValidYear(e.target.value)) {
        setViaje(
          viaje.map((data, index) => {
            if (index === parseInt(e.target.id, 10)) {
              //Se cambio ==
              data = { ...data, [e.target.name]: e.target.value };
            }
            return data;
          })
        );
        sessionStorage.setItem(
          "travels",
          JSON.stringify(
            viaje.map((data, index) => {
              if (index == e.target.id) {
                data = { ...data, [e.target.name]: e.target.value };
              }
              return data;
            })
          )
        );
      }
    } else {
      setViaje(
        viaje.map((data, index) => {
          if (index === parseInt(e.target.id, 10)) {
            //Se cambio ==
            data = { ...data, [e.target.name]: e.target.value };
          }
          return data;
        })
      );
      sessionStorage.setItem(
        "travels",
        JSON.stringify(
          viaje.map((data, index) => {
            if (index == e.target.id) {
              data = { ...data, [e.target.name]: e.target.value };
            }
            return data;
          })
        )
      );
    }
  };
  const openModal = () => {
    setUpdateModal(true);
  };

  const handleChangueInput = (value, name, id) => {
    console.log("NAME", name);
    const v = viaje.map((data, index) => {
      if (index == id) {
        if (name === "fecha") {
          console.log("FECHA");
          if (isValidDate(value) && isValidYear(value)) {
            console.log(value, "IS_VALID");
            data = { ...data, [name]: value };
          }
        } else {
          data = { ...data, [name]: value };
        }
      }
      return data;
    });
    setViaje(v);
    sessionStorage.setItem("travels", JSON.stringify(v));
  };

  const handleChangeAlojamiento = (e) => {
    const alog = alojamiento.map((data, index) => {
      if (index == e.target.id) {
        data = { ...data, [e.target.name]: e.target.value };
      }
      return data;
    });
    setAlojamiento(alog);
    sessionStorage.setItem("accommodation", JSON.stringify(alog));
  };

  const addEvent = () => {
    setViaje([
      ...viaje,
      {
        origen: "",
        destino: "",
        aerolinea: "",
        vuelo: "",
        cotizacion: "",
        fecha: "",
        horario: "",
        precio: "",
      },
    ]);
    setAlojamiento([
      ...alojamiento,
      {
        hotel: "",
        urlCotizacion: "",
        fecha: "",
        precio: "",
      },
    ]);
  };

  const deleteEvent = (index) => {
    const arr = viaje;
    arr.splice(index, 1);
    alojamiento.pop();
    setViaje([...arr]);
    setAlojamiento(alojamiento);
  };

  const editEvent = (evento) => {
    setData({
      titulo: evento.cREU_Titulo,
      texto1: `${evento.cREU_FechaInicio}-${evento.cREU_FechaFin}`,
      texto2: `${evento.Pais}, ${evento.Estado}`,
      texto3: evento.cREU_TipoReunion,
    });
    setData2({
      titulo: evento.Nombre_Empleado,
      texto1: `No. empleado ${cEMP_Id.cEMP_Id}`,
      texto2: "Suscripción",
      texto3: "Patria RE",
    });
    sessionStorage.setItem("cREU_Id", evento.cREU_Id);
  };

  const [saveProducts] = useMutation(SAVE_VIATICOS, {
    onCompleted({ createTravelExpenses }) {
      if (createTravelExpenses.message !== null) {
        setOpen(true);
      } else {
        handleComponent();
      }
    },
    onError({ graphQLErrors, networkError }) {
      graphQLErrors.map(({ message, locations, path }) =>
        console.log(
          `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
        )
      );

      if (networkError) console.log(`[Network error]: ${networkError}`);
    },
  });

  const [updateProducts] = useMutation(DYNAMIC_UPDATE, {
    onCompleted({ dynamicUpdate }) {},
    onError({ graphQLErrors, networkError }) {
      graphQLErrors.map(({ message, locations, path }) =>
        console.log(
          `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
        )
      );

      if (networkError) console.log(`[Network error]: ${networkError}`);
    },
  });

  const [insertProducts] = useMutation(DYNAMIC_INSERT, {
    onCompleted({ dynamicInsert }) {},
    onError({ graphQLErrors, networkError }) {
      graphQLErrors.map(({ message, locations, path }) =>
        console.log(
          `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
        )
      );

      if (networkError) console.log(`[Network error]: ${networkError}`);
    },
  });

  const getInputUpdate = (table, columns, conditions) => {
    return {
      table,
      columns,
      conditions,
    };
  };

  const [firePushNotification] = useMutation(FIRE_PUSH_NOTIFICATION, {
    onCompleted({ firePushNotification }) {},
    onError(error) {
      console.log(error);
    },
  });
  const PushNotification = ({ variables }) => {
    const { typeNotification, typeUserNotificate, cREU_Id } = variables;
    let input = {
      cEMP_Id: cEMP_Id.cEMP_Id,
      typeNotification,
      typeUserNotificate,
      cREU_Id,
    };
    firePushNotification({ variables: { input } });
  };

  const checkDates = () => {};

  const save = () => {
    try {
      const travels = JSON.parse(sessionStorage.getItem("travels"));
      const accommodation = JSON.parse(sessionStorage.getItem("accommodation"));
      let materials = JSON.parse(sessionStorage.getItem("materials"));
      let ITrequirement = JSON.parse(sessionStorage.getItem("ITrequiremen"));
      let promotional = JSON.parse(sessionStorage.getItem("promotionals"));
      const observations = JSON.parse(sessionStorage.getItem("observations"));
      const input = {
        cREU_Id: parseInt(sessionStorage.getItem("cREU_Id"), 10),
        cTIP_Id: parseInt(sessionStorage.getItem("cTIP_Id"), 10),
        travels: travels.map(
          (
            {
              origen,
              destino,
              aerolinea,
              vuelo,
              cotizacion,
              fecha,
              horario,
              precio,
            },
            index
          ) => {
            return {
              cVIA_Origen: origen,
              cVIA_Destino: destino,
              cVIA_Areolinea: aerolinea,
              cVIA_NoVuelo: vuelo,
              cVIA_URLVueloCot: cotizacion,
              cVIA_HorarioVuelo: horario.split(" ").join(""),
              cVIA_FechaVuelo: fecha.split("/").join("-"),
              cVIA_PrecioVuelo: precio,
              cTVI_Id: index % 2 === 0 && index !== travels.length ? 1 : 2,
            };
          }
        ),
        accommodation: accommodation.map(
          ({ hotel, urlCotizacion, fecha, precio }, index) => {
            return {
              cALO_Hotel: hotel,
              cALO_URLAlojamientoCot: urlCotizacion,
              cALO_Precio: precio,
              cALO_FechaAlojamiento: fecha.split("/").join("-"),
            };
          }
        ),
        materials: materials
          ? materials.map(({ cMAT_Descripcion, cMAT_Precio }) => {
              return { cMAT_Descripcion, cMAT_Precio };
            })
          : [],
        ITrequirement: ITrequirement
          ? ITrequirement.map(({ cREQT_Descripcion, cREQT_Precio }) => {
              return {
                cREQT_Descripcion,
                cREQT_Precio,
              };
            })
          : [],
        promotional: promotional
          ? promotional.map(({ cPRO_Descripcion, cPRO_Precio }) => {
              return {
                cPRO_Descripcion,
                cPRO_Precio,
              };
            })
          : [],
        observations: [
          {
            cOBS_Descripcion: observations[0].cOBS_Descripcion,
            cEMP_Id: observations[0].cEMP_Id,
            cTOBS_Id: getcTOBS_Id(cEMP_Id.profile),
          },
        ],
      };
      saveProducts({ variables: { input } }).then(() => {
        console.log("Guardado");
      });
    } catch (error) {
      console.error(error.message);
    }
  };

  const update = () => {
    let profile = cEMP_Id.profile;
    const travels = JSON.parse(sessionStorage.getItem("travels"));
    const accommodation = JSON.parse(sessionStorage.getItem("accommodation"));
    let materials = JSON.parse(sessionStorage.getItem("materials"));
    let ITrequirement = JSON.parse(sessionStorage.getItem("ITrequiremen"));
    let promotionals = JSON.parse(sessionStorage.getItem("promotionals"));
    const observations = JSON.parse(sessionStorage.getItem("observations"));
    if (travels) {
      travels.forEach((travel, index) => {
        const {
          aerolinea,
          cTVI_Id,
          cVIT_Id,
          cVIA_Id,
          cotizacion,
          destino,
          fecha,
          horario,
          origen,
          precio,
          vuelo,
        } = travel;
        if (cVIT_Id) {
          let columns = [
            { setkey: "cVIA_Areolinea", setval: aerolinea },
            { setkey: "cVIA_Destino", setval: destino },
            { setkey: "cVIA_FechaVuelo", setval: fecha.split("/").join("-") },
            {
              setkey: "cVIA_HorarioVuelo",
              setval: horario.split(" ").join(""),
            },
            { setkey: "cVIA_NoVuelo", setval: vuelo },
            { setkey: "cVIA_Origen", setval: origen },
            { setkey: "cVIA_URLVueloCot", setval: cotizacion },
            { setkey: "cVIA_PrecioVuelo", setval: precio },
          ];
          let conditions = [
            { valone: "cVIA_Id", valtwo: String(cVIA_Id), condition: "=" },
            {
              valone: "cVIT_Id",
              valtwo: String(cVIT_Id),
              condition: "=",
              logic: "AND",
            },
            {
              valone: "cTVI_Id",
              valtwo: String(cTVI_Id),
              condition: "=",
              logic: "AND",
            },
          ];
          let input = getInputUpdate("cVIACRM_ViajesCRM", columns, conditions);
          updateProducts({ variables: { input } });
        } else {
          let input = {
            table: "cVIACRM_ViajesCRM",
            columns: [
              "cVIT_Id",
              "cVIA_Origen",
              "cVIA_Destino",
              "cVIA_Areolinea",
              "cVIA_NoVuelo",
              "cVIA_URLVueloCot",
              "cVIA_HorarioVuelo",
              "cVIA_FechaVuelo",
              "cVIA_PrecioVuelo",
              "cTVI_Id",
            ],
            detailValues: [
              {
                values: [
                  "" + context.state.viaticosProps.cVIT_Id,
                  origen,
                  destino,
                  aerolinea,
                  vuelo,
                  cotizacion,
                  horario,
                  fecha,
                  precio,
                  `${index + 1}`,
                ],
              },
            ],
          };
          insertProducts({ variables: { input } });
        }
      });
    }
    if (accommodation) {
      accommodation.forEach((data) => {
        const { cALO_Id, cVIT_Id, fecha, hotel, precio, urlCotizacion } = data;
        if (cVIT_Id) {
          let columns = [
            { setkey: "cALO_Hotel", setval: hotel },
            { setkey: "cALO_Precio", setval: precio },
            { setkey: "cALO_FechaAlojamiento", setval: fecha },
            { setkey: "cALO_URLAlojamientoCot", setval: urlCotizacion },
          ];
          let conditions = [
            { valone: "cALO_Id", valtwo: String(cALO_Id), condition: "=" },
            {
              valone: "cVIT_Id",
              valtwo: String(cVIT_Id),
              condition: "=",
              logic: "AND",
            },
          ];
          let input = getInputUpdate("cALO_Alojamiento", columns, conditions);
          updateProducts({ variables: { input } });
        } else {
          let input = {
            table: "cALO_Alojamiento",
            columns: [
              "cVIT_Id",
              "cALO_Hotel",
              "cALO_Precio",
              "cALO_URLAlojamientoCot",
              "cALO_FechaAlojamiento",
            ],
            detailValues: [
              {
                values: [
                  "" + context.state.viaticosProps.cVIT_Id,
                  hotel,
                  precio,
                  urlCotizacion,
                  fecha,
                ],
              },
            ],
          };
          insertProducts({ variables: { input } });
        }
      });
    }
    if (materials) {
      materials.forEach((material) => {
        const { cMAT_Id, cMAT_Descripcion, cMAT_Precio, cVIT_Id } = material;
        if (cVIT_Id) {
          let columns = [
            { setkey: "cMAT_Descripcion", setval: cMAT_Descripcion },
            { setkey: "cMAT_Precio", setval: cMAT_Precio },
          ];
          let conditions = [
            { valone: "cMAT_Id", valtwo: String(cMAT_Id), condition: "=" },
            {
              valone: "cVIT_Id",
              valtwo: String(cVIT_Id),
              condition: "=",
              logic: "AND",
            },
          ];
          let input = getInputUpdate("cMAT_Materiales", columns, conditions);
          updateProducts({ variables: { input } });
        } else {
          let input = {
            table: "cMAT_Materiales",
            columns: ["cVIT_Id", "cMAT_Descripcion", "cMAT_Precio"],
            detailValues: [
              {
                values: [
                  "" + context.state.viaticosProps.cVIT_Id,
                  cMAT_Descripcion,
                  cMAT_Precio,
                ],
              },
            ],
          };
          insertProducts({ variables: { input } });
        }
      });
    }
    if (ITrequirement) {
      ITrequirement.forEach((requirement) => {
        const {
          cREQT_Descripcion,
          cREQT_Id,
          cREQT_Precio,
          cVIT_Id,
        } = requirement;
        if (cVIT_Id) {
          let columns = [
            { setkey: "cREQT_Descripcion", setval: cREQT_Descripcion },
            { setkey: "cREQT_Precio", setval: cREQT_Precio },
          ];
          let conditions = [
            { valone: "cREQT_Id", valtwo: String(cREQT_Id), condition: "=" },
            {
              valone: "cVIT_Id",
              valtwo: String(cVIT_Id),
              condition: "=",
              logic: "AND",
            },
          ];
          let input = getInputUpdate(
            "cREQT_RequerimientoTI",
            columns,
            conditions
          );
          updateProducts({ variables: { input } });
        } else {
          let input = {
            table: "cREQT_RequerimientoTI",
            columns: ["cVIT_Id", "cREQT_Descripcion", "cREQT_Precio"],
            detailValues: [
              {
                values: [
                  "" + context.state.viaticosProps.cVIT_Id,
                  cREQT_Descripcion,
                  cREQT_Precio,
                ],
              },
            ],
          };
          insertProducts({ variables: { input } });
        }
      });
    }
    if (promotionals) {
      promotionals.forEach((promotional) => {
        const { cPRO_Descripcion, cPRO_Id, cPRO_Precio, cVIT_Id } = promotional;
        if (cVIT_Id) {
          let columns = [
            { setkey: "cPRO_Descripcion", setval: cPRO_Descripcion },
            { setkey: "cPRO_Precio", setval: cPRO_Precio },
          ];
          let conditions = [
            { valone: "cPRO_Id", valtwo: String(cPRO_Id), condition: "=" },
            {
              valone: "cVIT_Id",
              valtwo: String(cVIT_Id),
              condition: "=",
              logic: "AND",
            },
          ];
          let input = getInputUpdate("cPRO_Promocionales", columns, conditions);
          updateProducts({ variables: { input } });
        } else {
          let input = {
            table: "cPRO_Promocionales",
            columns: ["cVIT_Id", "cPRO_Descripcion", "cPRO_Precio"],
            detailValues: [
              {
                values: [
                  "" + context.state.viaticosProps.cVIT_Id,
                  cPRO_Descripcion,
                  cPRO_Precio,
                ],
              },
            ],
          };
          insertProducts({ variables: { input } });
        }
      });
    }
    if (observations) {
      observations.forEach((observation) => {
        let cTOBS_Id = getcTOBS_Id(profile);
        const { cEMP_Id, cOBS_Descripcion, cOBS_Id, cVIT_Id } = observation;
        if (cVIT_Id && cOBS_Id) {
          let columns = [
            { setkey: "cOBS_Descripcion", setval: cOBS_Descripcion },
          ];
          let conditions = [
            { valone: "cOBS_Id", valtwo: String(cOBS_Id), condition: "=" },
            {
              valone: "cVIT_Id",
              valtwo: String(cVIT_Id),
              condition: "=",
              logic: "AND",
            },
            {
              valone: "cEMP_Id",
              valtwo: String(cEMP_Id),
              condition: "=",
              logic: "AND",
            },
          ];
          let input = getInputUpdate(
            "cOBS_ObservacionesViaticos",
            columns,
            conditions
          );
          updateProducts({ variables: { input } });
        } else {
          let input = {
            table: "cOBS_ObservacionesViaticos",
            columns: ["cVIT_Id", "cEMP_Id", "cOBS_Descripcion", "cTOBS_Id"],
            detailValues: [
              {
                values: [
                  "" + context.state.viaticosProps.cVIT_Id,
                  "" + cEMP_Id,
                  cOBS_Descripcion,
                  "" + cTOBS_Id,
                ],
              },
            ],
          };
          insertProducts({ variables: { input } });
        }
      });
    }
    setUpdateModal(false);
    handleComponent();
  };

  const getViaticos = () => {
    switch (context.state.viaticosProps.cTIP_Id) {
      case 1:
        setValue("Ida y vuelta");
        break;
      case 2:
        setValue("Solo ida");
        break;
      case 3:
        setValue("Múltiples destinos");
        break;
      default:
        console.log("Nada");
    }
    const viajes = context.state.viaticosProps.viajes.map((viaje) => {
      return {
        ...viaje,
        origen: viaje.cVIA_Origen,
        destino: viaje.cVIA_Destino,
        aerolinea: viaje.cVIA_Areolinea,
        vuelo: viaje.cVIA_NoVuelo,
        cotizacion: viaje.cVIA_URLVueloCot,
        fecha: viaje.cVIA_FechaVuelo.slice(0, 10),
        horario: viaje.cVIA_HorarioVuelo.slice(11, 16),
        precio: viaje.cVIA_PrecioVuelo,
      };
    });
    const alojamientos = context.state.viaticosProps.alojamientos.map(
      (alojamiento) => {
        return {
          ...alojamiento,
          hotel: alojamiento.cALO_Hotel,
          urlCotizacion: alojamiento.cALO_URLAlojamientoCot,
          fecha: alojamiento.cALO_FechaAlojamiento.slice(0, 10),
          precio: alojamiento.cALO_Precio,
        };
      }
    );
    setViaje(viajes);
    setAlojamiento(alojamientos);
  };

  useEffect(() => {
    sessionStorage.clear();
    getUsersNotificate({ variables: { cGRP_Nombre: "asistente" } });
    getEventsByViaticos({
      variables: {
        cEMP_Id: cEMP_Id.cEMP_Id,
        cREU_FechaInicio: new Date().toISOString().slice(0, 10),
      },
    });
    sessionStorage.setItem("cTIP_Id", 1);
    if (context.state.viaticosProps.cVIT_Id !== "") {
      getViaticos();
      getEventBycREU({
        variables: { cREU_Id: context.state.viaticosProps.cREU_Id },
      });
    }
  }, []);
  return (
    <PageContainer marginTop="20px" width="70%" id="button-navigation">
      <DialogViat
        open={open}
        handleClose={() => {
          setOpen(false);
          handleComponent();
        }}
      />
      <ModalUpdate
        open={updateModal}
        handleClose={() => setUpdateModal(false)}
        update={update}
      />
      {/* <Modal open={saveModal} onClose={() => setSaveModal(false)}>
        <div style={{ width: "60%", backgroundColor: "#FFFFFF" }}>
          Guardado exitosamente
        </div>
      </Modal> */}
      <Subtitle color="#000">Registra los viáticos de tu viaje</Subtitle>
      <ContainerTwoInputs
        flexDirection="column"
        width="25%"
        margin="40px 0px 0px 0px"
      >
        {context.state.viaticosProps.cVIT_Id === "" ? (
          <Fragment>
            <Typography>ID o título de la reunión</Typography>
            <ReunionComplete Events={reuniones} editEvent={editEvent} />
          </Fragment>
        ) : null}
      </ContainerTwoInputs>
      <ContainerTwoInputs
        width="100%"
        margin="20px 0px"
        id="viaticos-solicitud"
      >
        <Card data={data} img={calendario} />
        <Card data={data2} img={user} />
      </ContainerTwoInputs>
      <h3>Importante</h3>
      <p>
        Antes de continuar con el registro de tu viaje no olvides visitar esta
        página para conocer las recomendaciones para tu viaje
      </p>
      <a
        href="https://guiadelviajero.sre.gob.mx/index.php/informacion-por-destino"
        style={{ color: "#3898EC", textAlign: "center" }}
      >
        Visitar Guia del viajero
      </a>
      <ButtonsInformacion
        title="Información de transporte"
        text1="Ida y vuelta"
        text2="Solo ida"
        text3="Múltiples destinos"
        handleType={handleTipoViatico}
      />
      {viaje &&
        viaje.map((viaj, index) => {
          return (
            <div key={index}>
              <FormTravels
                title={
                  cTIP_Id === 1 && index === 0
                    ? "Viaje de ida"
                    : cTIP_Id === 1 && index === 1
                    ? "Viaje de vuelta"
                    : cTIP_Id === 3
                    ? `Tramo ${index + 1}`
                    : "Viaje de ida"
                }
                handleChangue={handleChange}
                id={cTIP_Id === 1 ? index : cTIP_Id === 2 ? 0 : index}
                valueInputs={viaje}
                handleChangueSelect={handleChangueInput}
              />
              {value === "Múltiples destinos" && viaje.length > 1 && (
                <button
                  style={{ position: "absolute", margin: "5px 0" }}
                  type="button"
                  onClick={() => deleteEvent(index)}
                >
                  Eliminar Tramo
                </button>
              )}
            </div>
          );
        })}
      {value === "Múltiples destinos" ? (
        <div
          style={{ display: "flex", justifyContent: "flex-end", width: "90%" }}
        >
          <ContainerTwoInputs width="140px">
            <AddCircle style={{ color: green[500] }} onClick={addEvent} />
            <p>Agregar tramo</p>
          </ContainerTwoInputs>
        </div>
      ) : null}
      {alojamiento &&
        alojamiento.map((aloj, index) => {
          return cTIP_Id !== 2 ? (
            <FormAlojamiento
              title={
                cTIP_Id === 1 && index === 0
                  ? "Información de alojamiento"
                  : `Alojamiento - Tramo ${index + 1}`
              }
              id={cTIP_Id === 1 ? 0 : index}
              valueInputs={alojamiento}
              handleChangue={handleChangeAlojamiento}
            />
          ) : null;
        })}
      {value === "Múltiples destinos" ? (
        <div
          style={{ display: "flex", justifyContent: "flex-end", width: "90%" }}
        >
          <ContainerTwoInputs width="140px">
            <AddCircle style={{ color: green[500] }} onClick={addEvent} />
            <p>Agregar tramo</p>
          </ContainerTwoInputs>
        </div>
      ) : null}
      <FormMateriales title="Equipo o materiales para viaje" cambio={value} />
      <FormObservaciones title="Observaciones" cEMP_Id={cEMP_Id.cEMP_Id} />
      <div
        style={{ marginTop: "20px", justifyContent: "center", display: "flex" }}
      >
        <ButtonNavigation
          text={
            context.state.viaticosProps.cVIT_Id !== "" ? "Editar" : "Guardar"
          }
          width="45%"
          backgroundColor="#3898EC"
          border="none"
          color="#fff"
          justifyContent="center"
          onClick={
            context.state.viaticosProps.cVIT_Id !== "" ? openModal : save
          }
          style={{ cursor: "pointer" }}
        />
      </div>
    </PageContainer>
  );
};

export default useNewSolicitud;
