import React, { Fragment, useMemo, useState, useEffect } from 'react';
import ButtonNavigation from '../Viaticos/ButtonNavigation';
import { useMutation } from 'react-apollo';
import { DINAMIC_GET } from '../../mutation/mutation';
import DatePicker from 'react-date-picker';
import { NativeSelect, TextareaAutosize, Chip } from '@material-ui/core';
import Areas_Inv from './Areas_Inv';
import DivContainer from '../../layouts/Acuerdos/DivContainer';
import ImputResumen from './ImputResumen';
import '../components.css';
import { LabelCard } from '../StylesComponents/common-styled';
import { ArrowBackIos, Clear } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';

//components
import LastStep from './componentsRelacion/LastStep';
import DropdownAreas from './componentsRelacion/DropdownAreas';


const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        justifyContent: 'flex-start',
        flexWrap: 'wrap',
        '& > *': {
            margin: theme.spacing(0.5),
        },
    },
}));

const SeguimientoRelPatria = () => {
    const classes = useStyles();
    const [saveIdResponseQuestions, setSaveIdResponseQuestions] = useState({})
    const [currentQuestion, setCurrentQuestion] = useState('');
    const [disabledOne, setDisabledOne] = useState(true);
    const [disabledTwo, setDisabledTwo] = useState(true);
    const [answerByQuestion, setAnswerByQuestion] = useState({
        commentOne: '',
        commentTwo: '',
        status: ''
    })
    const [formAreas, setFormAreas] = useState({
        Direccion: '',
        Finanzas: '',
        Operaciones: '',
        Suscripcion: ''
    })
    const [generarReporte, setGenerarReporte] = useState('');
    const [tema, setTema] = useState('');
    const [comments, setComments] = useState('')
    const [areas, setAreas] = useState([]);

    const [seguimiento, setSeguimiento] = useState([]);
    const [optionsSegReporte, setOptionsSegReporte] = useState([]);
    const [optionsSegDarReporte, setOptionsSegDarReporte] = useState([]);
    const [optionsTomarAcciones, setOptionsTomarAcciones] = useState([]);
    const [optStatusSeguimiento, setOptStatusSeguimiento] = useState([])


    const [getSeguimiento] = useMutation(DINAMIC_GET, {
        onCompleted({ dynamicGet }) {
            setSeguimiento(JSON.parse(dynamicGet.response));
        },
        onError(error) {
            console.log(error);
        },
    });
    const [getSeguimientoReporte] = useMutation(DINAMIC_GET, {
        onCompleted({ dynamicGet }) {
            setOptionsSegReporte(JSON.parse(dynamicGet.response));
        },
        onError(error) {
            console.log(error);
        },
    });
    const [getSeguimientoDarReporte] = useMutation(DINAMIC_GET, {
        onCompleted({ dynamicGet }) {
            setOptionsSegDarReporte(JSON.parse(dynamicGet.response));
        },
        onError(error) {
            console.log(error);
        },
    });
    const [getTomarAcciones] = useMutation(DINAMIC_GET, {
        onCompleted({ dynamicGet }) {
            setOptionsTomarAcciones(JSON.parse(dynamicGet.response));
        },
        onError(error) {
            console.log(error);
        },
    });
    const [getStatusSeg] = useMutation(DINAMIC_GET, {
        onCompleted({ dynamicGet }) {
            setOptStatusSeguimiento(JSON.parse(dynamicGet.response));
        },
        onError(error) {
            console.log(error);
        },
    });

    const seguimientoInput = useMemo(
        () => ({
            variables: {
                input: {
                    table: 'cSEGR_SeguimientoRelacion',
                    columns: ['cSEGR_Id', 'cSEGR_Descripcion'],
                },
            },
        }),
        []
    );
    const segReporteInput = useMemo(
        () => ({
            variables: {
                input: {
                    table: 'cTEMSR_TemasSeguimientoRelacion',
                    columns: ['*'],
                    conditions: [{
                        valone: 'cTEMSR_Tipo',
                        valtwo: `${1}`,
                        condition: '=',
                    }]
                },
            },
        }),
        []
    );
    const segDeseasDarReporteInput = useMemo(
        () => ({
            variables: {
                input: {
                    table: 'cACCSR_AccionesSeguimientoRelacion',
                    columns: ['*'],
                    conditions: [{
                        valone: 'cTEMSR_Id',
                        valtwo: `${1}`,
                        condition: '=',
                    }]
                },
            },
        }),
        []
    );
    const tomarAccionesInput = useMemo(
        () => ({
            variables: {
                input: {
                    table: 'cACCSR_AccionesSeguimientoRelacion',
                    columns: ['*'],
                    conditions: [{
                        valone: 'cTEMSR_Id',
                        valtwo: `${2}`,
                        condition: '=',
                    }]
                },
            },
        }),
        []
    );
    const statusSeguimientoInput = useMemo(
        () => ({
            variables: {
                input: {
                    table: 'cESTS_EstatusSeguimiento',
                    columns: ['*']
                },
            },
        }),
        []
    );

    const handleOnClickBack = (currentQuestion) => {
        // let index = saveAdvanceQuestion.indexOf(currentQuestion);
        // let stepAux = index === 0 ? index : index - 1;
        // if (index === 0) {
        //     setCurrentQuestion('tema');
        //     setStep(step - 1);
        //     saveAdvanceQuestion.splice(index, 1);
        // } else {
        //     setCurrentQuestion(saveAdvanceQuestion[stepAux]);
        //     setStep(step - 1);
        //     saveAdvanceQuestion.splice(index, 1);
        // }
    }
    const handleChange = ({ target }) => {
        console.log('handleChange', target.value)
        const { name, value } = target;
        setFormAreas({
            ...formAreas,
            [name]: value
        })
    }

    const deleteSubarea = (area) => {
        setAreas(areas.filter(item => item !== area));
    }

    useEffect(() => {
        getSeguimiento(seguimientoInput);
        getSeguimientoReporte(segReporteInput);
        getSeguimientoDarReporte(segDeseasDarReporteInput);
        getTomarAcciones(tomarAccionesInput);
        getStatusSeg(statusSeguimientoInput);
    }, [])
    useEffect(() => {
        const valuesObj = Object.values(formAreas);
        setAreas(valuesObj.filter(val => val !== ''));
    }, [formAreas]);
    return (
        <Fragment>
            <div onClick={() => handleOnClickBack(currentQuestion)} style={{ cursor: 'pointer' }}>
                <ArrowBackIos style={{ color: '#01539C' }} />
            </div>
            {currentQuestion === '' && (
                <DivContainer
                    title="Seguimiento Relación con Patria"
                    subtitle="Aparte de lo comentado, ¿deseas generar un reporte de seguimiento?"
                >
                    {seguimiento.map(({ cSEGR_Id, cSEGR_Descripcion }, index) => (
                        <ButtonNavigation
                            text={cSEGR_Descripcion}
                            width="25%"
                            id="button-casual"
                            key={index}
                            onClick={() => {
                                setGenerarReporte(cSEGR_Descripcion);
                                setSaveIdResponseQuestions({
                                    ...saveIdResponseQuestions,
                                    cSEGR_Id,
                                });
                                setCurrentQuestion(cSEGR_Descripcion);
                            }}
                        />
                    ))}
                </DivContainer>
            )}
            {(currentQuestion === 'Acuerdo' || currentQuestion === 'Solicitud' || currentQuestion === 'Oportunidad' || currentQuestion === 'Otro') && (
                <DivContainer
                    title="Seguimiento Relación con Patria"
                    subtitle={`¿Qué seguimiento deseas dar a tu ${generarReporte}?`}
                >
                    {optionsSegReporte.map(({ cTEMSR_Id, cTEMSR_Descripcion }, index) => (
                        <ButtonNavigation
                            text={cTEMSR_Descripcion}
                            width="25%"
                            id="button-casual"
                            key={index}
                            onClick={() => {
                                setTema(cTEMSR_Descripcion)
                                setSaveIdResponseQuestions({
                                    ...saveIdResponseQuestions,
                                    cTEMSR_Id,
                                });
                                setCurrentQuestion(cTEMSR_Descripcion);
                            }}
                        />
                    ))}
                </DivContainer>
            )}
            {currentQuestion === 'Ninguno' && (
                <DivContainer
                    title="Seguimiento Relación con Patria"
                    subtitle={`Resumen de Relación con Patria`}
                >
                    <LastStep
                        txtBtn="Continuar a Seguimiento"
                        setState={setComments}
                        state={comments}
                    // onClick={}
                    />
                    <ButtonNavigation
                        text='Volver a Relación con Patria'
                        style={{ cursor: 'pointer' }}
                    // onClick={() => backRelacionPatria()}
                    />
                </DivContainer>
            )}
            {currentQuestion === 'Analizar Información' && (
                <DivContainer
                    title="Seguimiento Relación con Patria"
                    subtitle={`¿Qué seguimiento deseas dar a tu ${generarReporte}?`}
                >
                    {optionsSegDarReporte.map(({ cACCSR_Id, cACCSR_Descripcion }, index) => (
                        <ButtonNavigation
                            text={cACCSR_Descripcion}
                            width="25%"
                            id="button-casual"
                            key={index}
                            onClick={() => {

                                setSaveIdResponseQuestions({
                                    ...saveIdResponseQuestions,
                                    cACCSR_Id,
                                });
                                setCurrentQuestion(cACCSR_Descripcion);
                            }}
                        />
                    ))}
                </DivContainer>
            )}
            {currentQuestion === 'Acciones de Seguimiento' && (
                <DivContainer
                    title="Seguimiento Relación con Patria"
                    subtitle={`¿Qué acciones deseas tomar?`}
                >
                    {optionsTomarAcciones.map(({ cACCSR_Id, cACCSR_Descripcion }, index) => (
                        <ButtonNavigation
                            text={cACCSR_Descripcion}
                            width="25%"
                            id="button-casual"
                            key={index}
                            onClick={() => {

                                setSaveIdResponseQuestions({
                                    ...saveIdResponseQuestions,
                                    cACCSR_Id,
                                });
                                setCurrentQuestion(cACCSR_Descripcion);
                            }}
                        />
                    ))}
                </DivContainer>
            )}
            {currentQuestion === 'Solicitud de Atención o Seguimiento de \nOtra Area' && (
                <DivContainer
                    title="Seguimiento Relación con Patria"
                >
                    <DropdownAreas
                        onClick={setCurrentQuestion}
                        paramsOnclick="comments"
                        handleChange={handleChange}
                        state={formAreas}
                        txtBtn="Guardar y continuar"
                    />
                </DivContainer>
            )}
            {
                (currentQuestion === 'Enviar Charola o Regalo a Cedente/ Corredor' ||
                    currentQuestion === 'Temas Pendientes' || currentQuestion === 'Renovación' ||
                    currentQuestion === 'Nuevo Contrato' || currentQuestion === 'comments')
                && (
                    <DivContainer
                        title="Seguimiento Relación con Patria"
                        subtitle={`Comentarios para el ${generarReporte} sobre ${tema}.`}
                    >
                        <LastStep
                            onClick={setCurrentQuestion}
                            paramsOnclick="nextSatatusSeg"
                            txtBtn="Guardar y continuar"
                            setState={setComments}
                            state={comments}
                        />
                    </DivContainer>
                )
            }
            {currentQuestion === 'nextSatatusSeg' && (
                <DivContainer
                    title="Seguimiento Relación con Patria"
                    subtitle={`¿Cuál es el estatus de tu ${generarReporte}?`}
                >
                    {optStatusSeguimiento.map(({ cESTS_Id, cESTS_Descripcion }, index) => (
                        <ButtonNavigation
                            text={cESTS_Descripcion}
                            width="25%"
                            id="button-casual"
                            key={index}
                            onClick={() => {
                                setAnswerByQuestion({
                                    ...answerByQuestion,
                                    status: cESTS_Descripcion
                                })
                                setSaveIdResponseQuestions({
                                    ...saveIdResponseQuestions,
                                    cESTS_Id,
                                });
                                setCurrentQuestion(cESTS_Descripcion);
                            }}
                        />
                    ))}
                </DivContainer>
            )}
            {(currentQuestion === 'Pendiente' || currentQuestion === 'En proceso' || currentQuestion === 'Concluido') && (
                <DivContainer
                    title="Seguimiento Relación con Patria"
                >
                    <DropdownAreas
                        onClick={setCurrentQuestion}
                        paramsOnclick="resumenSegRelPatria"
                        handleChange={handleChange}
                        state={formAreas}
                        txtBtn="Guardar y continuar"
                    />
                </DivContainer>
            )}
            {currentQuestion === 'resumenSegRelPatria' && (
                <>
                    <DivContainer
                        title="Seguimiento Relación con Patria"
                        subtitle="Resumen de Seguimiento sobre Relación con Patria"
                    >
                        <ImputResumen
                            title="Comentarios de Relación con Patria:"
                            // subtitle={"subititlo"}
                            disabled={disabledOne}
                            setDisabled={() => setDisabledOne(!disabledOne)}
                            value={answerByQuestion.commentOne}
                            setValue={(value) =>
                                setAnswerByQuestion({ ...answerByQuestion, commentOne: value })
                            }
                            flexDirection="column"                        
                        />
                        <ImputResumen
                            title={generarReporte}
                            status={answerByQuestion.status}
                            // subtitle={"subtitle2"}
                            value={answerByQuestion.commentTwo}
                            setValue={(value) => setAnswerByQuestion({ ...answerByQuestion, commentTwo: value })}
                            disabled={disabledTwo}
                            setDisabled={() => setDisabledTwo(!disabledTwo)}
                        />
                    </DivContainer>
                    {
                        areas.length > 0 ?
                            <div style={{ width: '90%', display: 'flex', flexDirection: 'column', textAlign: 'initial' }}>
                                <h4 style={{ color: '#3898EC' }}>Áreas</h4>
                                <div className={classes.root}>
                                    {
                                        areas &&
                                        areas.map((area, index) =>
                                            <Chip label={area} style={{ backgroundColor: '#3898EC', color: '#FFFF' }} onDelete={() => deleteSubarea(area)} deleteIcon={<Clear />} key={index} />
                                        )
                                    }
                                </div>
                            </div> : null
                    }
                    <ButtonNavigation
                        text="Guardar y continuar a Seguimiento"
                        backgroundColor="#3898EC"
                        border="#3898EC"
                        color="#FFFFFF"
                        width="75%"
                    // onClick={() => saveInfoByIds('relaciónPatria')}
                    />
                    <ButtonNavigation
                        text="Guardar y registrar nuevo Seguimiento"
                        backgroundColor="#FFFFFF"
                        border="#FFFFFF"
                        color="black"
                        width="75%"
                    // onClick={() => saveInfoByIds('nuevoSeguimiento')}
                    />
                    <ButtonNavigation
                        text="Volver a Relación con Patria"
                        backgroundColor="#FFFFFF"
                        border="#FFFFFF"
                        color="black"
                        width="75%"
                    // onClick={() => onClickReturnEntorno()}
                    />
                </>
            )}
        </Fragment>
    );
}

export default SeguimientoRelPatria;