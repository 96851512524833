import React,{useState, Fragment} from 'react';
import { FormControl, FormControlLabel, Radio, RadioGroup, withStyles } from '@material-ui/core';

const RadioCustom=withStyles({
  root:{
    color:'#000',
    '&$checked':{
      color:'#000'
    },
  },
  checked:{}
})((props)=><Radio color='default'{...props}/>);


const ButtonsInformacion=({title,text1,text2,text3,handleType,cTIp_Id})=>{
  const [value,setValue]=useState(text1);
  const handleChange=(event)=>{
    handleType(event.target.value);
    setValue(event.target.value);
  };
  return(
    <Fragment>
    <div style={{marginTop:'20px'}}>
      <h3 style={{textAlign:'center'}}>{title}</h3>
    </div>
      {
        cTIp_Id?
          <FormControl component='fieldset'>
            <RadioGroup aria-label='tarvels' name='travels' value={value} onChange={handleChange} style={{display:'flex',flexDirection:'row'}}>

              <FormControlLabel value={text1} control={<RadioCustom/>} label={text1} disabled={cTIp_Id==1?false:true}/>
              <FormControlLabel value={text2} control={<RadioCustom/>} label={text2} disabled={cTIp_Id==2?false:true}/>
              <FormControlLabel value={text3} control={<RadioCustom/>} label={text3} disabled={cTIp_Id==3?false:true}/>
            </RadioGroup>
          </FormControl>:
          <FormControl component='fieldset'>
            <RadioGroup aria-label='tarvels' name='travels' value={value} onChange={handleChange} style={{display:'flex',flexDirection:'row'}}  >
              <FormControlLabel value={text1} control={<RadioCustom/>} label={text1}/>
              <FormControlLabel value={text2} control={<RadioCustom/>} label={text2}/>
              <FormControlLabel value={text3} control={<RadioCustom/>} label={text3}/>
            </RadioGroup>
      </FormControl>
      }
      
    </Fragment>
    
  )
}

export default ButtonsInformacion;