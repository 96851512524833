import React,{forwardRef} from 'react';
import {Button,Dialog,DialogActions,DialogContent,DialogContentText,DialogTitle,Slide,TextareaAutosize} from '@material-ui/core';


const Transiction = forwardRef(function Transition(props,ref){
    return <Slide direction='up' ref={ref} {...props}/>
})

const DialogComponent =({open,handleClose,title,text,text2,textButtonA,textButtonB,handleClick})=>{
return(
      <Dialog open={open} TransictionComponent={Transiction} keepMountend onClose={handleClose} aria-labelledby="alert-dialog-slide-title" aria-describedby="alert-dialog-slide-description">
          <DialogTitle style={{textAlign:"center"}}>{title}</DialogTitle>
          <DialogContent>
            <DialogContentText style={{textAlign:"center",marginBottom:'0px'}}>{text}</DialogContentText>
            <DialogContentText style={{textAlign:"center"}}>{text2}</DialogContentText>
            <TextareaAutosize style={{width:'100%',height:'100px'}}/>
          </DialogContent>
          <DialogActions style={{justifyContent:"center"}}>
            <Button onClick={handleClose} style={{color:'#3898EC',border:'solid 1px #3898EC',width:"45%"}}>
                {textButtonA}
            </Button>
            <Button onClick={handleClick} style={{color:"white",backgroundColor:"#3898EC",width:"45%"}}>
                {textButtonB}
            </Button>
          </DialogActions>
      </Dialog>
)
}

export default DialogComponent;