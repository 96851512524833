import React, {Fragment} from 'react';
import {AcordeonContainerBody} from '../StylesComponents/common-styled';
import FormTypography from './FormsTypography';
import {Typography } from '@material-ui/core';


const FormAlojamiento=({title,value,lugar,pos})=>{
  const {cALO_Hotel,cALO_URLAlojamientoCot,cALO_Precio}=value[pos];
  return(
    <Fragment>
      <div style={{marginTop:'20px'}}>
        <h3 style={{textAlign:'center'}}>{title}</h3>
      </div>
      <AcordeonContainerBody id='inputs-viaticos'>
        <FormTypography text='Hotel:' direction="row">
          <Typography><strong>{cALO_Hotel}</strong></Typography>
        </FormTypography>
        <FormTypography text='Lugar:' direction="row">
          <Typography><strong>{lugar}</strong></Typography>
        </FormTypography>
      </AcordeonContainerBody>
      <AcordeonContainerBody id='inputs-viaticos'>
        <FormTypography text='Precio de alojamiento:' direction="row">
        <Typography><strong>{`$${cALO_Precio}`}</strong></Typography>
        </FormTypography>
        <FormTypography text='URL de alojamiento:' direction="row">
        <Typography><strong>{cALO_URLAlojamientoCot}</strong></Typography>
        </FormTypography>
      </AcordeonContainerBody>
      </Fragment>
  )
}

export default FormAlojamiento;