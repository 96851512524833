import React, { useState, useEffect, useContext, Fragment } from 'react';
import {AcordeonContainerBody,ContainerTwoInputs} from '../StylesComponents/common-styled';
import { TextField } from '@material-ui/core';
import FormTypography from './FormsTypography';
import { AddCircle } from '@material-ui/icons';
import { green } from '@material-ui/core/colors';
import { MyContext } from '../Context';

const InputTravel=({placeholder,name,onChange,id,value,type,disabled})=>{
  return (
    <TextField fullWidth variant='outlined' placeholder={placeholder} name={name} onChange={onChange} id={id} value={value} type={type} disabled={disabled}/>
  );
};

const useFormMateriales=({title,text1,text2,text3,cambio,asistente})=>{

  const [materiales,setMateriales]=useState(
    [
      {
        cMAT_Descripcion:'',
        cMAT_Precio:''
      }
    ]
  );
  const [requerimientoTI,setRequerimientoTI]=useState(
    [
      {
        cREQT_Descripcion:'',
        cREQT_Precio:''
      }
    ]
  );

  const [promocionales,setPromocionales]=useState(
    [
      {
        cPRO_Descripcion:'',
        cPRO_Precio:'',
      }
    ]
  );
  const context=useContext(MyContext);
  const addPapeleria=()=>{
    setMateriales([...materiales,{cMAT_Descripcion:'',cMAT_Precio:''}]);
  }
  const addPromocional=()=>{
    setPromocionales([...promocionales,{cPRO_Descripcion:'',cPRO_Precio:''}])
  }
  const addRequerimiento=()=>{
    setRequerimientoTI([...requerimientoTI,{cREQT_Descripcion:'',cREQT_Precio:''}]);
  }
  const onChanguePape=(e)=>{
    setMateriales(materiales.map((data,index)=>{
      if(index==e.target.id){
        data={...data,[e.target.name]:e.target.value}
      }
      return data;
    }))
    sessionStorage.setItem('materials',JSON.stringify(materiales.map((data,index)=>{
      if(index==e.target.id){
        data={...data,[e.target.name]:e.target.value}
      }
      return data;
    })))
  }

  const onChangueProm=(e)=>{
    setPromocionales(promocionales.map((data,index)=>{
      if(index==e.target.id){
        data={...data,[e.target.name]:e.target.value}
      }
      return data;
    }))
    sessionStorage.setItem('promotionals',JSON.stringify(promocionales.map((data,index)=>{
      if(index==e.target.id){
        data={...data,[e.target.name]:e.target.value}
      }
      return data;
    })))
  }

  const onChangueReq=(e)=>{
    setRequerimientoTI(requerimientoTI.map((data,index)=>{
      if(index==e.target.id){
        data={...data,[e.target.name]:e.target.value}
      }
      return data;
    }))
    sessionStorage.setItem('ITrequiremen',JSON.stringify(requerimientoTI.map((data,index)=>{
      if(index==e.target.id){
        data={...data,[e.target.name]:e.target.value}
      }
      return data;
    })))
  }

  useEffect(()=>{
    setMateriales(
      [
        {
          cMAT_Descripcion:'',
          cMAT_Precio:''
        }
      ]
    );
    setRequerimientoTI(
      [
        {
          cREQT_Descripcion:'',
          cREQT_Precio:''
        }
      ]
    );
  
    setPromocionales(
      [
        {
          cPRO_Descripcion:'',
          cPRO_Precio:'',
        }
      ]
    );
    if(context.state.viaticosProps.cREU_Id!==""){
      const materialesV=context.state.viaticosProps.materiales.map(material=>{
        return({
          ...material,
          cMAT_Descripcion:material.cMAT_Descripcion,
          cMAT_Precio:material.cMAT_Precio
        })
      })
      const promocionalesV=context.state.viaticosProps.promocionales.map(promocionalV=>{
        return({
          ...promocionalV,
          cPRO_Descripcion:promocionalV.cPRO_Descripcion,
          cPRO_Precio:promocionalV.cPRO_Precio
        })
      })
      const requerimientosTiV=context.state.viaticosProps.requerimientoTI.map(requerimientoTiV=>{
        return({
          ...requerimientoTiV,
          cREQT_Descripcion:requerimientoTiV.cREQT_Descripcion,
          cREQT_Precio:requerimientoTiV.cREQT_Precio
        })
      })
      setMateriales(materialesV);
      setPromocionales(promocionalesV);
      setRequerimientoTI(requerimientosTiV);
      console.log("Context Material====",materialesV)
      
    }
    console.log('FormMateriales=====>',context.state.viaticosProps)
  },[cambio])
  return(
    <Fragment>
      <div style={{marginTop:'20px'}}>
        <h3 style={{textAlign:'center'}}>{title}</h3>
      </div>
      <AcordeonContainerBody flexDirection='column' id='inputs-viaticos'>
        <AcordeonContainerBody width='100%' flexWrap='wrap'>
          {materiales.map((material,index)=>
            <Fragment>
              <FormTypography text={text1 || 'Materiales y/o papelería'}>
                <InputTravel placeholder='Ej, Pizarrón, papeleria, Tag, etc.' name='cMAT_Descripcion' id={index} onChange={onChanguePape} value={material.cMAT_Descripcion} disabled={asistente}/>
              </FormTypography>
              <FormTypography text={text1 || ' Precio materiales y/o papelería'}>
              <InputTravel placeholder='Ingresa el precio del Material' name='cMAT_Precio' id={index} onChange={onChanguePape} value={material.cMAT_Precio} type='number' disabled={asistente}/>
            </FormTypography>
            </Fragment>
          )}
        </AcordeonContainerBody>
        {
          asistente===true?null:
          <ContainerTwoInputs width='15%'>
            <AddCircle style={{color:green[500]}} onClick={addPapeleria}/>
            <p>Agregar material</p>
          </ContainerTwoInputs>
        }
        
      </AcordeonContainerBody>
      <AcordeonContainerBody flexDirection='column' id='inputs-viaticos'>
        <AcordeonContainerBody width='100%' flexWrap='wrap'>
          {promocionales.map((prom,index)=>
            <Fragment>
              <FormTypography text={text2 || 'Promocionales'}>
                <InputTravel placeholder='Ingresa el tipo de promocional' name='cPRO_Descripcion' id={index} onChange={onChangueProm} value={prom.cPRO_Descripcion} disabled={asistente}/>
              </FormTypography>
              <FormTypography text={text2 || 'Precio promocional'}>
              <InputTravel placeholder='Ingresa el precio del promocional' name='cPRO_Precio' id={index} onChange={onChangueProm} value={prom.cPRO_Precio} type='number' disabled={asistente}/>
            </FormTypography>
            </Fragment>
          )}
        </AcordeonContainerBody>
        {
          asistente===true?null:
          <ContainerTwoInputs width='15%'>
            <AddCircle style={{color:green[500]}} onClick={addPromocional}/>
            <p>Agregar material</p>
          </ContainerTwoInputs>
        }
        
      </AcordeonContainerBody>
      <AcordeonContainerBody flexDirection='column' id='inputs-viaticos'>
        <AcordeonContainerBody width='100%' flexWrap='wrap'>
          {requerimientoTI.map((req,index)=>
          <Fragment>
            <FormTypography text={text3 || 'Requerimiento TI'}>
              <InputTravel placeholder='Paquete celular, cañon, proyector, etc.' name='cREQT_Descripcion' id={index} onChange={onChangueReq} value={req.cREQT_Descripcion} disabled={asistente}/>
            </FormTypography>
            <FormTypography text={text3 || 'Precio requerimiento TI'}>
              <InputTravel placeholder='Ingresa el precio del requerimiento TI' name='cREQT_Precio' id={index} onChange={onChangueReq} value={req.cREQT_Precio} type='number' disabled={asistente}/>
            </FormTypography>
          </Fragment>  
          )}
        </AcordeonContainerBody>
        {
          asistente===true?null:
          <ContainerTwoInputs width='15%'>
            <AddCircle style={{color:green[500]}} onClick={addRequerimiento}/>
            <p>Agregar material</p>
          </ContainerTwoInputs>
        }
      </AcordeonContainerBody>
    </Fragment>
  )
}

export default useFormMateriales;