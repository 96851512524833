import React from 'react';
import revision from '../../assets/images/icons/revision.svg';
import aprobada from '../../assets/images/icons/aprobada.svg';
import cancelada from '../../assets/images/icons/cancelada.svg';
import autorizacion from '../../assets/images/icons/autorizacion.svg';
import avion5 from '../../assets/images/icons/avion5.svg';
import avion4 from '../../assets/images/icons/avion4.svg';
import avion3 from '../../assets/images/icons/avion3.svg';
import avion2 from '../../assets/images/icons/avion2.svg';
import avion1 from '../../assets/images/icons/avion1.svg';
import avionG from '../../assets/images/icons/avionG.svg';

const CardPendientes=({cREU_Id,cVIT_Status_Comp,cVIT_Status_Viat})=>{
  return(
    <div style={{width:'95%',height:'max-content',border:'1px solid #e5e5e5:borderRadius:5px',boxShadow: '0px 4px 4px rgba(0,0,0,0.25)',display:'flex',justifyContent:'center',flexDirection:'column',alignItems:'center',marginBottom:'20px'}}>
      <div style={{width:'100%',height:'32px', borderBottom:'1px solid #C8C8C8',display:'flex'}}>
      <div style={{width:'50%',display:'flex',justifyContent:'left',marginLeft:"10px",alignContent:"center"}}>
            <p><strong>{`Reunión No. ${cREU_Id}`}</strong></p>
          </div>
      <div style={{width:'50%',display:'flex',alignContent:"center",justifyContent:"flex-end",marginRight:"10px"}}>
            <p style={{marginRight:'10px'}}><strong>{cVIT_Status_Viat==='1'?'En revisión':cVIT_Status_Viat==='2'?'En autorización':cVIT_Status_Viat==='3'?'Rechazada':cVIT_Status_Viat==='4'?'Autorizada':null}</strong></p>
            <img src={cVIT_Status_Viat==='1'?revision:cVIT_Status_Viat==='2'?autorizacion:cVIT_Status_Viat==='3'?cancelada:cVIT_Status_Viat==='4'?aprobada:null} width='21px' height='21px' alt='alert'/>
          </div>
      </div>
      <img src={cVIT_Status_Comp==='0'?avionG:cVIT_Status_Comp==='1'?avion1:cVIT_Status_Comp==='2'?avion2:cVIT_Status_Comp==='3'?avion3:cVIT_Status_Comp==='4'?avion4:cVIT_Status_Comp==='5'?avion5:null} width='35%' alt='avion'/>
    </div>
  )
}

export default CardPendientes;