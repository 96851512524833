import { useMutation } from 'react-apollo';
import React, { useEffect, useMemo, useState, useContext } from 'react';
import { DINAMIC_GET, DYNAMIC_INSERT } from '../../mutation/mutation';
import ButtonNavigation from '../Viaticos/ButtonNavigation';
import DivContainer from '../../layouts/Acuerdos/DivContainer';
import { TextareaAutosize, NativeSelect, Chip } from '@material-ui/core';
import ToggleButtons from '../Viaticos/ToggleButons';
import Areas_Inv from './Areas_Inv';
import ImputResumen from './ImputResumen';
import { MyContext } from '../Context';
import { LabelCard } from '../StylesComponents/common-styled';
import { BootstrapInput } from '../StylesComponents/material-ui-styled';
import { ArrowBackIos, Clear } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'flex-start',
    flexWrap: 'wrap',
    '& > *': {
      margin: theme.spacing(0.5),
    },
  },
}));
export default function Seguimiento({ onClick, onClickReturnEntorno, onClickReturnRelacionPatria, onGoBack, titleSeguimiento = 'Seguimiento Entorno' }) {
  const classes = useStyles();
  const [value, setValue] = useState('');
  const [seguimiento, setSeguimiento] = useState([]);
  const [ramo, setRamo] = useState([]);
  const [seguimientoEntor, setSeguimientoEntor] = useState([]);
  const [subtemaSeguimiento, setSubtemaSeguimiento] = useState([]);
  const [registroSolicitud, setRegistroSolicitud] = useState([]);
  const [statusSeguimiento, setStatusSeguimiento] = useState([]);
  const [cREP_Descripcion, setCREP_Descripcion] = useState('');
  const [cRAMC_Descripcion, setCRAMC_Descripcion] = useState('');
  const [cREGSS_Descripcion, setCREGSS_Descripcion] = useState('');
  const [cTEMS_Id, setCTEMS_Id] = useState('0');
  const [cSUBTS_Id, setCSUBTS_Id] = useState('0');
  const [cESTS_Id, setCESTS_Id] = useState();
  const [cESTS_Descripcion, setCESTS_Descripcion] = useState('');
  const [cSUBTS_Descripcion, setCSUBTS_Descripcion] = useState('');
  const [sComentarios, setSComentarios] = useState('');

  const [areas, setAreas] = useState([]);
  const [subArea, setSubArea] = useState([]);
  const [open, setOpen] = useState(false);

  const [disabledRelacion, setDisabledRelacion] = useState(true);
  const [disabledAcuerdo, setDisabledAcuerdos] = useState(true);
  const [steps, setSteps] = useState([]);
  const [saveIdResponseQuestions,setSaveIdResponseQuestions] = useState({})
  const context = useContext(MyContext).state;
  const clearContext = useContext(MyContext);

  const seguimientoInput = useMemo(
    () => ({
      variables: {
        input: {
          table: 'cREPS_ReporteSeguimiento',
          columns: ['cREP_Id', 'cREP_Descripcion'],
        },
      },
    }),
    []
  );

  const ramoInput = useMemo(
    () => ({
      variables: {
        input: {
          table: 'cRAMC_RamoCRM',
          columns: ['cRAMC_Id', 'cRAMC_Descripcion'],
        },
      },
    }),
    []
  );

  const seguimientoEntorInput = useMemo(
    () => ({
      variables: {
        input: {
          table: 'cTEMS_TemasSeguimiento',
          columns: ['cTEMS_Id', 'cTEMS_Descripcion'],
        },
      },
    }),
    []
  );

  const subSeguimiento = useMemo(
    () => ({
      variables: {
        input: {
          table: 'cSUBTS_SubtemasSeguimiento',
          columns: ['cSUBTS_Id', 'cTEMS_Id', 'cSUBTS_Descripcion'],
          conditions: [
            {
              valone: 'cTEMS_Id',
              valtwo: '' + cTEMS_Id,
              condition: '=',
            },
          ],
        },
      },
    }),
    [cTEMS_Id]
  );
  const registroSol = useMemo(
    () => ({
      variables: {
        input: {
          table: 'cREGSS_RegistroSolicitudSeguimiento',
          columns: ['cREGSS_Id', 'cSUBTS_Id', 'cREGSS_Descripcion'],
          conditions: [
            {
              valone: 'cSUBTS_Id',
              valtwo: '' + cSUBTS_Id,
              condition: '=',
            },
          ],
        },
      },
    }),
    [cSUBTS_Id]
  );

  const status = useMemo(
    () => ({
      variables: {
        input: {
          table: 'cESTS_EstatusSeguimiento',
          columns: ['cESTS_Id', 'cESTS_Descripcion'],
        },
      },
    }),
    []
  );

  const area = useMemo(
    () => ({
      variables: {
        input: {
          table: 'cARE_AREASCRM',
          columns: ['cARE_Id', 'cARE_Descripcion'],
        },
      },
    }),
    []
  );

  const subAre = useMemo(
    () => ({
      variables: {
        input: {
          table: 'cSUBA_SubAreasCRM',
          columns: ['cSUBA_Id', 'cARE_Id', 'cSUBA_Descripcion'],
        },
      },
    }),
    []
  );  

  useEffect(() => {
    getSeguimiento(seguimientoInput);
    getRamos(ramoInput);
    getSeguimientoEntor(seguimientoEntorInput);
    getArea(area);
    getSubArea(subAre);
    getStatus(status);
  }, []);
  useEffect(() => {
    getSubSeguimiento(subSeguimiento);
  }, [subSeguimiento]);
  useEffect(() => {
    getRegistroSolicitud(registroSol);
  }, [registroSol]);

  const [saveIdResponse] = useMutation(DYNAMIC_INSERT, {
    onCompleted({ dynamicInsert }) {
      console.log("guardado ", JSON.parse(dynamicInsert.response));
    },
    onError(error) {
      console.log(error);
    },
  });
  const [getSeguimiento] = useMutation(DINAMIC_GET, {
    onCompleted({ dynamicGet }) {
      setSeguimiento(JSON.parse(dynamicGet.response));
    },
    onError(error) {
      console.log(error);
    },
  });

  const [getRamos] = useMutation(DINAMIC_GET, {
    onCompleted({ dynamicGet }) {
      setRamo(JSON.parse(dynamicGet.response));
    },
    onError(error) {
      console.log(error);
    },
  });

  const [getSeguimientoEntor] = useMutation(DINAMIC_GET, {
    onCompleted({ dynamicGet }) {
      setSeguimientoEntor(JSON.parse(dynamicGet.response));
    },
  });

  const [getSubSeguimiento] = useMutation(DINAMIC_GET, {
    onCompleted({ dynamicGet }) {
      setSubtemaSeguimiento(JSON.parse(dynamicGet.response));
    },
  });

  const [getRegistroSolicitud] = useMutation(DINAMIC_GET, {
    onCompleted({ dynamicGet }) {
      setRegistroSolicitud(JSON.parse(dynamicGet.response));
    },
  });

  const [getStatus] = useMutation(DINAMIC_GET, {
    onCompleted({ dynamicGet }) {
      setStatusSeguimiento(JSON.parse(dynamicGet.response));
    },
  });

  const [getArea] = useMutation(DINAMIC_GET, {
    onCompleted({ dynamicGet }) {
      setAreas(JSON.parse(dynamicGet.response));
    },
  });

  const [getSubArea] = useMutation(DINAMIC_GET, {
    onCompleted({ dynamicGet }) {
      setSubArea(
        JSON.parse(dynamicGet.response).map((dynamic) => {
          return { ...dynamic, status: false };
        })
      );
    },
  });

  const saveInfoByIds = (nextStep) => {
    const {
      cREP_Id: cRES_Id,
      cRAMC_Id = 0,
      cTEMS_Id = 0,
      cSUBTS_Id = 0,
      cREGSS_Id= 0,
      cESTS_Id = 0,
      cARE_Id = 0,
      cSUBA_Id = 0
    } = saveIdResponseQuestions;
    // console.log("input save",saveIdResponseQuestions);
    let input = {
      input: {
        table: 'cSEGE_SeguimientoEntorno',
        columns: ['cRES_Id', 'cRAMC_Id', 'cTEMS_Id', 'cSUBTS_Id', 'cREGSS_Id', 'cESTS_Id', 'cARE_Id', 'cSUBA_Id'],
        detailValues: [
          {
            values: [String(cRES_Id), String(cRAMC_Id), String(cTEMS_Id), String(cSUBTS_Id), String(cREGSS_Id), String(cESTS_Id), String(cARE_Id), String(cSUBA_Id)]
          }
        ]
      }
    }
    // console.log("input save",input);
    saveIdResponse({
      variables: input
    })
    if (nextStep === 'relaciónPatria') onClickReturnRelacionPatria();
    else {
      setSaveIdResponseQuestions({});      
      setValue('');
      setSteps([]);
      clearContext.clearState();
    };   
  }

  const onchange = ({ value }) => {
    const { cARE_Id, cSUBA_Id } = JSON.parse(value);
    setSaveIdResponseQuestions({
      ...saveIdResponseQuestions,
      cARE_Id,
      cSUBA_Id,
    })
    setSubArea(
      subArea.map((sArea) => {
        if (sArea.cARE_Id == cARE_Id && sArea.cSUBA_Id == cSUBA_Id) {
          return { ...sArea, status: !sArea.status };
        } else {
          return { ...sArea };
        }
      })
    );
  };

  const deleteSubarea=(cSUBA_Id,cARE_Id)=>{
    setSubArea(subArea.map((sArea)=>{
      if(sArea.cARE_Id==cARE_Id && sArea.cSUBA_Id==cSUBA_Id){
        return{...sArea,status:!sArea.status}
      }
      else{
        return{...sArea}
      }
    }))
  }

  const previousQuestion = () => {
    let index = steps.indexOf(value);
    let step = index === 0 ? index : index - 1; 
    if (index === 0) {
      setValue('');
      steps.splice(index, 1);
    } else {
      setValue(steps[step]);
      steps.splice(index, 1);
    }
  }
  useEffect(() => {    
    if (value !== "" && !steps.includes(value)) setSteps([...steps, value]);
    console.log("steps",steps)
  }, [value])

  return (
    <div style={{ width: '100%' }} className="registro-container">
      {steps.length > 0 ? (
        <div onClick={() => previousQuestion()} style={{ cursor: 'pointer' }}>
          <ArrowBackIos style={{ color: '#01539C' }} />          
        </div>
      ) : (
        <div onClick={() => onGoBack()} style={{ cursor: 'pointer' }}>
          <ArrowBackIos style={{ color: '#01539C' }} />
        </div>
      )}
      {value === '' && (
        <DivContainer
          title={titleSeguimiento}
          subtitle="Aparte de lo comentado, ¿deseas generar un reporte de seguimiento?"
        >
          {seguimiento.map(({cREP_Id, cREP_Descripcion}, index) => (
            <ButtonNavigation
              text={cREP_Descripcion}
              width="25%"
              id="button-casual"
              key={index}
              onClick={() => {
                if (cREP_Descripcion !== 'Ninguno') {
                  setCREP_Descripcion(cREP_Descripcion);
                  setValue('ramo');
                  setSaveIdResponseQuestions({
                    ...saveIdResponseQuestions,
                    cREP_Id
                  })
                } else {
                  setValue('ramo_ninguno');
                  setSaveIdResponseQuestions({
                    ...saveIdResponseQuestions,
                    cREP_Id
                  })
                }
              }}
            />
          ))}
        </DivContainer>
      )}
      {value === 'ramo_ninguno' && (
        <DivContainer
          title="Resumen de Seguimiento sobre Entorno "
          subtitle="Comentarios de Entorno"
        >
          <div style={{ width: 380 }}>
            <hr />
          </div>
          <p>
            {context.saveAcuerdos.cTIPR_Descripcion
              ? context.saveAcuerdos.cTIPR_Descripcion
              : context.saveAcuerdos.cSUBE_Descripcion
              ? context.saveAcuerdos.cSUBE_Descripcion
              : 'Sin Ultimo Ramo Elegido'}
          </p>
          <div style={{ backgroundColor: '#E5E5E5', width: 380 }}>
            <LabelCard
              style={{
                marginTop: 10,
                backgroundColor: '#858585',
                color: '#FFF',
                paddingLeft: 10,
              }}
              width="150px"
              height="35px"
            >
              {context.saveAcuerdos.cENT_Descripcion
                ? context.saveAcuerdos.cENT_Descripcion
                : 'Sin Ramo Elegido'}
            </LabelCard>

            <p style={{ paddingLeft: 10 }}>
              {context.saveAcuerdos.cCOMA_Descripcion
                ? context.saveAcuerdos.cCOMA_Descripcion
                : 'Sin Comentarios'}
            </p>
          </div>
          <ButtonNavigation
            text="Guardar y Continuar con Relación"
            width="25%"
            id="button-casual"
            backgroundColor="#3898EC"
            color="white"
            onClick={() => onClick()}
          />
          <ButtonNavigation
            text="Volver a Entorno"
            width="25%"
            id="button-casual"
            onClick={() => onClickReturnEntorno()}
          />
        </DivContainer>
      )}
      {value === 'ramo' && (
        <DivContainer
          title={titleSeguimiento}
          subtitle="¿A qué ramo aplica tu seguimiento?"
        >
          {ramo.map(({cRAMC_Id, cRAMC_Descripcion}, index) => (
            <ButtonNavigation
              text={cRAMC_Descripcion}
              width="25%"
              id="button-casual"
              key={index}
              onClick={() => {
                setValue('seguimiento');
                setCRAMC_Descripcion(cRAMC_Descripcion);
                setSaveIdResponseQuestions({
                  ...saveIdResponseQuestions,
                  cRAMC_Id
                })
              }}
            />
          ))}
        </DivContainer>
      )}
      {value === 'seguimiento' && (
        <DivContainer
          title={titleSeguimiento}
          subtitle={`¿Qué seguimiento deseas dar a tu ${cREP_Descripcion}?`}
        >
          {seguimientoEntor.map(({cTEMS_Id, cTEMS_Descripcion}, index) => {
            return (
              <ButtonNavigation
                text={cTEMS_Descripcion}
                width="25%"
                id="button-casual"
                key={index}
                onClick={() => {
                  if (cTEMS_Id != 5) {
                    setCTEMS_Id('' + cTEMS_Id);
                    setSaveIdResponseQuestions({
                      ...saveIdResponseQuestions,
                      cTEMS_Id
                    })
                    setValue('subTema');
                  } else {
                    setValue('comentarios');
                    setCREGSS_Descripcion(cTEMS_Descripcion);
                    setSaveIdResponseQuestions({
                      ...saveIdResponseQuestions,
                      cTEMS_Id
                    })
                  }
                }}
              />
            );
          })}
        </DivContainer>
      )}
      {value === 'subTema' && (
        <DivContainer
        title={titleSeguimiento}
        subtitle={
          cTEMS_Id === "4" 
          ? '¿Qué acciones deseas tomar?' 
          : cTEMS_Id === "3" 
          ? `¿Qué siguiente paso deseas registrar para tu ${cREP_Descripcion}?` 
          : `¿Qué seguimiento deseas dar a tu ${cREP_Descripcion}?`}
      >
        {subtemaSeguimiento.map(({cSUBTS_Id, cSUBTS_Descripcion}, index) => {
          return (
            <ButtonNavigation
              text={cSUBTS_Descripcion}
              width="25%"
              id="button-casual"
              key={index}
              onClick={() => {
                if (cSUBTS_Id >= 3) {
                  setCSUBTS_Id(cSUBTS_Id);
                  setValue('registro');
                  setCSUBTS_Descripcion(cSUBTS_Descripcion);
                  setSaveIdResponseQuestions({
                    ...saveIdResponseQuestions,
                    cSUBTS_Id
                  })
                } else {
                  setSaveIdResponseQuestions({
                    ...saveIdResponseQuestions,
                    cSUBTS_Id
                  })
                  setCSUBTS_Id(cSUBTS_Id);
                  setCSUBTS_Descripcion(cSUBTS_Descripcion);
                  setValue('comentarios');
                }
              }}
            />
          );
        })}
      </DivContainer>
      )}
      {value === 'registro' && (
        <DivContainer
          title={titleSeguimiento}
          subtitle={
            cSUBTS_Id === 3
              ? '¿Deseas solicitar aprobación al Comité de Suscripción?'
              : cSUBTS_Id >= 4 && cSUBTS_Id <= 8
              ? `¿Qué siguiente paso deseas registrar para ${cRAMC_Descripcion}?`
              : cSUBTS_Id === 9
              ? '¿Qué información deseas enviar?'
              : 'Elige las áreas involucradas'
          }
        >
          {cSUBTS_Id === 3 ||
          (cSUBTS_Id >= 4 && cSUBTS_Id <= 8) ||
          cSUBTS_Id === 9 ? (
            registroSolicitud.map(({cREGSS_Id, cSUBTS_Id, cREGSS_Descripcion}, index) => (
              <ButtonNavigation
                text={cREGSS_Descripcion}
                width="25%"
                id="button-casual"
                key={index}
                onClick={() => {
                  setValue('comentarios');
                  setCREGSS_Descripcion(cREGSS_Descripcion);
                  setSaveIdResponseQuestions({
                    ...saveIdResponseQuestions,
                    cREGSS_Id
                  })
                }}
              />
            ))
          ) : (
            <div>
              <label>Direccion General</label>
              <NativeSelect input={<BootstrapInput />} fullWidth>
                <option value="">Control Interno</option>
                <option value="">Director General</option>
                <option value="">Planeación y Coordinación</option>
                <option value="">Transformación Operativa</option>
              </NativeSelect>
              <label>Finanzas</label>
              <NativeSelect input={<BootstrapInput />} fullWidth>
                <option value="">Actuaría</option>
                <option value="">Contabilidad</option>
                <option value="">Fiscal</option>
                <option value="">Modelación / Pricing</option>
                <option value="">Tesorería</option>
              </NativeSelect>
              <label>Operaciones</label>
              <NativeSelect input={<BootstrapInput />} fullWidth>
                <option value="">Administración Técnica</option>
                <option value="">Pull de Asistentes</option>
                <option value="">Servicios de Oficina</option>
                <option value="">Siniestros</option>
                <option value="">Retrocesiones</option>
              </NativeSelect>
              <label>Suscripción</label>
              <NativeSelect input={<BootstrapInput />} fullWidth>
                <option value="">Contratos Daños</option>
                <option value="">Facultativo Daños</option>
                <option value="">Fianzas</option>
                <option value="">Lineas Especiales</option>
                <option value="">Oficina Chile</option>
                <option value="">Personas</option>
                <option value="">Ultramar</option>
              </NativeSelect>
              <ButtonNavigation
                text="Otra Area"
                width="25%"
                style={{ marginTop: 30 }}
                id="button-casual"
                onClick={() => {
                  setValue('comentarios');
                }}
              />
            </div>
          )}
        </DivContainer>
      )}
      {value === 'comentarios' && (
        <>
          <DivContainer
            title={titleSeguimiento}
            subtitle={`Comentarios para el seguimiento de ${cREP_Descripcion} sobre ${cREGSS_Descripcion || cSUBTS_Descripcion} `}
          >
            <TextareaAutosize
              rowsMin={10}
              style={{ width: '90%' }}
              placeholder="Escribe tus comentarios"
              value={sComentarios}
              onChange={({ target }) => setSComentarios(target.value)}
            />
            <ToggleButtons />
            <ButtonNavigation
              text="Guardar y continuar"
              backgroundColor="#3898EC"
              border="#3898EC"
              color="#FFFFFF"
              width="75%"
              onClick={() => setValue('status')}
            />
          </DivContainer>
          {
            cREGSS_Descripcion === 'Condolencias' || cREGSS_Descripcion === 'Felicitación' ?
              <div style={{ width: '90%', display: 'flex', flexDirection: 'column', textAlign: 'initial' }} onClick={() => setOpen(true)}>
                <h4 style={{ color: '#3898EC' }}>Áreas</h4>
                <div className={classes.root}>
                  {
                    subArea &&
                    subArea.map(({ cSUBA_Descripcion, cSUBA_Id, cARE_Id, status }, index) =>
                      {
                        console.log("mapeo ==",cSUBA_Descripcion, cSUBA_Id, cARE_Id, status)
                        if(cSUBA_Descripcion === 'Planeación y Coordinación'){
                          return <Chip label={cSUBA_Descripcion} style={{ backgroundColor: '#3898EC', color: '#FFFF' }} key={index} />
                        }
                      }
                    )
                  }
                </div>
              </div> : null
          }
        </>
      )}
      {value === 'status' && (
        <DivContainer
          title={titleSeguimiento}
          subtitle={`¿Cuál es el estado de tu ${cREP_Descripcion}?`}
        >
          {statusSeguimiento.map(({cESTS_Id, cESTS_Descripcion}, index) => (
            <ButtonNavigation
              text={cESTS_Descripcion}
              width="25%"
              id="button-casual"
              key={index}
              onClick={() => {
                setValue('resumen');
                setOpen(true);
                setCESTS_Descripcion(cESTS_Descripcion);
                setSaveIdResponseQuestions({
                  ...saveIdResponseQuestions,
                  cESTS_Id
                })
              }}
            />
          ))}
        </DivContainer>
      )}
      {value === 'resumen' && (
        <>
          <DivContainer
            title={titleSeguimiento}
            subtitle="Resumen de seguimiento sobre Entorno"
          >
            <ImputResumen
              title="Cometarios de Entorno"
              subtitle={context?.saveAcuerdos.cSUBE_Descripcion || cREGSS_Descripcion || cSUBTS_Descripcion}
              disabled={disabledRelacion}
              setDisabled={() => setDisabledRelacion(!disabledRelacion)}
              value={context?.saveAcuerdos.cCOMA_Descripcion}
              setValue={(value) =>
                context?.setSaveAcuerdos({ cCOMA_Descripcion: value })
              }
              flexDirection="column"
              ramo={context?.saveAcuerdos.cENT_Descripcion || cRAMC_Descripcion}
            />
            <ImputResumen
              title="Acuerdo"
              status={cESTS_Descripcion}
              subtitle={cSUBTS_Descripcion || cREGSS_Descripcion}
              value={sComentarios}
              setValue={(value) => setSComentarios(value)}
              disabled={disabledAcuerdo}
              setDisabled={() => setDisabledAcuerdos(!disabledAcuerdo)}
            />
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <h4>Ramo</h4>
              <span
                style={{
                  width: '120px',
                  height: '25px',
                  backgroundColor: '#85858585',
                  color: '#FFFFFF',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  borderRadius: '4px',
                }}
              >
                {context.saveAcuerdos.cENT_Descripcion || cRAMC_Descripcion}
              </span>
            </div>
          </DivContainer>
          {
            subArea.length > 0 ?
              <div style={{ width: '90%', display: 'flex', flexDirection: 'column', textAlign: 'initial' }} onClick={() => setOpen(true)}>
                <h4 style={{ color: '#3898EC' }}>Áreas</h4>
                <div className={classes.root}>
                  {
                    subArea &&
                    subArea.map(({ cSUBA_Descripcion, cSUBA_Id, cARE_Id, status }, index) =>
                      status ?
                        <Chip label={cSUBA_Descripcion} style={{ backgroundColor: '#3898EC', color: '#FFFF' }} onDelete={() => deleteSubarea(cSUBA_Id, cARE_Id)} deleteIcon={<Clear />} key={index} /> : null
                    )
                  }
                </div>
              </div> : null
          }
          <ButtonNavigation
            text="Guardar y continuar a Relación con Patria"
            backgroundColor="#3898EC"
            border="#3898EC"
            color="#FFFFFF"
            width="75%"
            onClick={() => saveInfoByIds('relaciónPatria')}
          />
          <ButtonNavigation
            text="Guardar y registrar nuevo Seguimiento"
            backgroundColor="#FFFFFF"
            border="#FFFFFF"
            color="black"
            width="75%"
            onClick={() => saveInfoByIds('nuevoSeguimiento')}
          />
          <ButtonNavigation
            text="Volver a Entorno"
            backgroundColor="#FFFFFF"
            border="#FFFFFF"
            color="black"
            width="75%"
            onClick={() => onClickReturnEntorno()}
          />
        </>
      )}
      <Areas_Inv
        areas={areas}
        subArea={subArea}
        open={open}
        setOpen={() => setOpen(false)}
        onChangue={onchange}
      />
    </div>
  );
}
