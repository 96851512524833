import React,{forwardRef} from 'react';
import {Button,Dialog,DialogActions,DialogContent,DialogContentText,DialogTitle,Slide} from '@material-ui/core';
import { NativeSelect } from '@material-ui/core';
import { BootstrapInput } from '../StylesComponents/material-ui-styled';
import '../components.css';
const Transiction = forwardRef(function Transition(props,ref){
  return <Slide direction='up' ref={ref} {...props}/>
});
const ModalUpdate=({open,handleClose,update})=>{
  return(
    <Dialog open={open} TransitionComponent={Transiction} keepMounted onClose={handleClose} aria-labelledby="alert-dialog-slide-title" aria-describedby="alert-dialog-slide-description">
      <DialogTitle style={{textAlign:"center"}}>Cambios en la solicitud de viáticos</DialogTitle>
      <DialogContent>
            <div style={{flexDirection:'column',display:'flex'}}>
              <DialogContentText style={{textAlign:"center"}}>Indica por favor el motivo de los cambios realizados en la solicitud de tus viáticos</DialogContentText>
             <h3>Motivo de cambio</h3>
             <NativeSelect
               input={<BootstrapInput/>}>
                 <option>Asuntos Personales</option>
                 <option>Otro</option>
               </NativeSelect>
            </div>
      </DialogContent>
      <DialogActions className="buttons-modal-viaticos" style={{justifyContent:"center"}}>
      <Button className="button-modal-viaticos" style={{color:'#fff',backgroundColor:'#3898EC',width:'45%'}} onClick={update} >Guardar</Button>
      <Button className="button-modal-viaticos" onClick={handleClose} style={{color:'#3898EC',border:'solid 1px   #3898EC',width:"45%"}}>
                Cancelar
            </Button>
      </DialogActions>
    </Dialog>
  )
}

export default ModalUpdate;