import React,  {useState} from 'react';
import Homebar from './Homebar';
import { navigation } from '../funciones-auxiliares/programar-reunion';
import { Page } from './StylesComponents/common-styled';
import './components.css';
import FirstColumnSnapshot from './SnapshotComponents/FirstColumn';
import SecondColumnSnapshot from './SnapshotComponents/SecondColumn';
import { useMutation } from '@apollo/react-hooks';
import { DINAMIC_GET } from '../mutation/mutation';

export default function Snapshot() {
  const [territorioOptions, setTerritorioOptions] = useState([]);
  const [countryOptions, setCountryOptions] = useState([]);
  const [operationOptions, setOperationOptions] = useState([]);

  const [getTerritorio] = useMutation(DINAMIC_GET, {
    onCompleted({ dynamicGet }) {
      setTerritorioOptions(JSON.parse(dynamicGet.response));
    },
    onError(err) {
      console.log('Error', err);
    },
  });

  const [getCountryOptions] = useMutation(DINAMIC_GET, {
    onCompleted({ dynamicGet }) {
      setCountryOptions(JSON.parse(dynamicGet.response));
    },
    onError(err) {
      console.log('Error', err);
    },
  });

  const [getOperationOptions] = useMutation(DINAMIC_GET, {
    onCompleted({ dynamicGet }) {
      setOperationOptions(JSON.parse(dynamicGet.response));
    },
    onError(err) {
      console.log('Error', err);
    },
  });

  React.useEffect(() => {
    getTerritorio({
      variables: {
        input: {
          table: 'cTER_Territorio',
          columns: ['cTER_Nombre'],
        },
      },
    });
    getCountryOptions({
      variables: {
        input: {
          table: 'cPAI_Pais',
          columns: ['cPAI_Nombre'],
        },
      },
    });
    getOperationOptions({
      variables: {
        input: {
          table: 'cGRM_GrupoRamo',
          columns: ['cGRM_Nombre'],
        },
      },
    });
  }, []);

  return (
    <Page>
      <Homebar navigation={navigation} />
      <div className="snapshot">
        <div className="snapshot__content">
          <FirstColumnSnapshot
            territorioOptions={territorioOptions}
            countryOptions={countryOptions}
            operationOptions={operationOptions}
          />
          <SecondColumnSnapshot />
        </div>
      </div>
    </Page>
  );
}
