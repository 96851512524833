export default {
    facultativo: [
        {
            id: 122,
            description: 'Suscripción',
        },
        {
            id: 22,
            description: 'Apetito de Riesgo',
        },
        {
            id: 32,
            description: 'Necesidades',
        }
    ],
    pendientesPatria: [
        {
            id: 4,
            description: 'Contratos Automáticos',
        },
        {
            id: 5,
            description: 'Facultativo',
        },
    ],
    presentacionPatria: [
        {
            id: 6,
            description: 'Resultados',
        },
        {
            id: 7,
            description: 'Estrategia',
        },
        {
            id: 8,
            description: 'Calificación Financiera',
        },
        {
            id: 9,
            description: 'Cambios Organizacionales',
        },
        {
            id: 10,
            description: 'Fortalezas Patria',
        },
        {
            id: 11,
            description: 'Áreas de Mejora',
        }
    ],
    capacitacionSeminarios: [
        {
            id: 12,
            description: 'Considerarlos para Próximo Seminario',
        },
        {
            id: 13,
            description: 'Plática/Presentación',
        },
        {
            id: 14,
            description: 'Otro',
        }
    ],
    impuestoRetenidoExt: [
        {
            id: 15,
            description: 'Al corriente',
        },
        {
            id: 16,
            description: 'Incompleto',
        },
    ],
    expedientePLD: [
        {
            id: 17,
            description: 'Al corriente',
        },
        {
            id: 18,
            description: 'Incompleto',
        },
        {
            id: 19,
            description: 'Actualización',
        },
    ],
    //data step 2
    afterLimitDate: [
        {
            id: 133,
            description: 'Se mantiene sin cambios',
        },
        {
            id: 134,
            description: 'Cambios',
        },
        {
            id: 135,
            description: 'Oportunidades para Patria',
        },
        {
            id: 136,
            description: 'Líder y Otros Participantes',
        },
    ],
    estrategia: [
        {
            id: 401,
            description: 'Apetito de Riesgo',
        },
        {
            id: 402,
            description: 'Crecimiento',
        },
        {
            id: 403,
            description: 'Diversificación',
        },
        {
            id: 404,
            description: 'Otros',
        }
    ],
    fortalezasAndAreasMejora: [
        {
            id: 405,
            description: 'Disponibilidad',
        },
        {
            id: 406,
            description: 'Pago de Siniestros',
        },
        {
            id: 407,
            description: 'Comunicación/ Presencia',
        },
        {
            id: 408,
            description: 'Tiempo de Respuesta',
        },
        {
            id: 409,
            description: 'Otro',
        }
    ],
    platicaPresentacion: [
        {
            id: 410,
            description: 'Suscripción/Reaseguro',
        },
        {
            id: 411,
            description: 'Siniestros',
        },
        {
            id: 412,
            description: 'Riesgos/Actuaría/Pricing',
        }
    ],
    //step 3
    cambios: [
        {
            id: 201,
            description: 'Calendario Renovación',
        },
        {
            id: 202,
            description: 'Límites',
        },
        {
            id: 203,
            description: 'Estructura',
        },
        {
            id: 204,
            description: 'Primas Estimadas',
        },
        {
            id: 205,
            description: 'Cúmulos Catastróficos',
        },
        {
            id: 206,
            description: 'Clausulado',
        },
        {
            id: 207,
            description: 'Aceptaciones Especiales',
        },
        {
            id: 208,
            description: 'Otros Cambios',
        }
    ],
    oportunidadesPatria: [
        {
            id: 209,
            description: 'Contratos Actuales',
        },
        {
            id: 210,
            description: 'Nuevos Contratos',
        }
    ]
}