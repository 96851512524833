import React from 'react';
import Home from './components/Home';
import Login from './components/Login';
import HomeUser from './components/HomeUser';
import Viaticos from './components/Viaticos';
import Reuniones from './components/Reuniones';
import ProgramarReunion from './components/ProgramarReunion';
import './App.css';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom';
import Session from './components/Session';
import Context from './components/Context';
import { Acuerdos } from './components/Acuerdos';
import Snapshot from './components/Snapshot';
const App = ({ refetch, session }) => {
  const { obtenerUsuario } = session;

  const mensaje = obtenerUsuario ? (
    `${obtenerUsuario.cEMP_Nombre}`
  ) : (
    <Redirect to="/login" />
  );
  console.log(mensaje);
  return (
    <Router>
      <Context>
        <Switch>
          <Route exact path="/" render={() => <Home session={session} />} />
          <Route
            exact
            path="/login"
            render={() => <Login refetch={refetch} session={session} />}
          />
          <Route
            exact
            path="/secciones"
            render={() => <HomeUser session={session} />}
          />
          <Route
            exact
            path="/secciones/viaticos"
            render={() => <Viaticos session={session} />}
          />
          <Route
            exact
            path="/secciones/reuniones"
            render={() => <Reuniones session={session} />}
          />
          <Route
            exact
            path="/secciones/reuniones/programar-reunion"
            render={() => <ProgramarReunion session={session} />}
          />
          <Route
            exact
            path="/secciones/reuniones/snapshots"
            render={(props) => <Snapshot {...props} />}
          />
          <Route exact path="/secciones/reuniones/suscripcion-siniestros" />
          <Route
            exact
            path="/secciones/reuniones/acuerdos-viaje"
            render={(props) => <Acuerdos session={session} {...props} />}
          />
          <Route exact path="/secciones/reuniones/reuniones-programadas" />
        </Switch>
      </Context>
    </Router>
  );
};

const RootSession = Session(App);

export { RootSession };
