import React, { useEffect, useState, useContext, Fragment } from "react";
import {
  PageContainer,
  Subtitle,
  ContainerTwoInputs,
} from "../StylesComponents/common-styled";

import Card from "./Card";
import calendario from "../../assets/images/icons/calendario.svg";
import user from "../../assets/images/icons/usuario.svg";
import descargar from "../../assets/images/icons/descargar.svg";
import upload from "../../assets/images/icons/upload.svg";
import ButtonsInformacion from "./ButtonsInformacion";
import { TextareaAutosize, Typography } from "@material-ui/core";
import ButtonNavigation from "./ButtonNavigation";
import CardPendientes from "./CardPendientes";
import {
  getcTOBS_Id,
  getRol,
  totalViaticos,
  totalMateriales,
} from "../../funciones-auxiliares/viaticos";
import { useLazyQuery, useMutation } from "react-apollo";
import {
  GET_EVENTOSBYVIATICOS,
  GET_EVENTO_BY_CREU_ID,
} from "../../query/query";
import { DYNAMIC_INSERT, DYNAMIC_UPDATE } from "../../mutation/mutation";
import { MyContext } from "../Context";
import ToggleButtons from "./ToggleButons";
import InfoViaje from "./InfoViaje";
import InfoAlojamiento from "./InfoAlojamiento";
import InfoMateriales from "./InfoMateriales";
import CardUpDown from "./CardUpDown";

const useComprobacion = ({ cEMP_Id, handleComponent, title }) => {
  const [reuniones, setReuniones] = useState([]);
  const [data, setData] = useState({
    titulo: "",
    texto1: "",
    texto2: "",
    texto3: "",
  });
  const [data2, setData2] = useState({
    titulo: "",
    texto1: "",
    texto2: "",
    texto3: "",
  });
  const [viajes, setViajes] = useState([]);
  const [alojamientos, setAlojamientos] = useState([]);
  const [cTIP_Id, setcTIP_Id] = useState();
  const [value, setValue] = useState();

  const [materiales, setMateriales] = useState([]);
  const [requerimientoTI, setRequerimientoTI] = useState([]);
  const [promocionales, setPromocionales] = useState([]);

  const [getEventsByViaticos] = useLazyQuery(GET_EVENTOSBYVIATICOS, {
    onCompleted({ getReunionbyViaticos }) {
      setReuniones(getReunionbyViaticos);
    },
  });

  const [getEventBycREU] = useLazyQuery(GET_EVENTO_BY_CREU_ID, {
    onCompleted({ getReunionBycREUID }) {
      setData({
        titulo: getReunionBycREUID.cREU_Titulo,
        texto1: `${getReunionBycREUID.cREU_FechaInicio}-${getReunionBycREUID.cREU_FechaFin}`,
        texto2: `${getReunionBycREUID.Pais}, ${getReunionBycREUID.Estado}`,
        texto3: getReunionBycREUID.cREU_TipoReunion,
      });
      setData2({
        titulo: getReunionBycREUID.Nombre_Empleado,
        texto1: `No. empleado ${getReunionBycREUID.cEMP_Id}`,
        texto2: "Suscripción",
        texto3: "Patria RE",
      });
    },
  });
  const context = useContext(MyContext);
  const [insertProducts] = useMutation(DYNAMIC_INSERT, {
    onCompleted({ dynamicInsert }) {
      if (dynamicInsert.statusCode === 200) {
        handleComponent();
      }
    },
    onError({ graphQLErrors, networkError }) {
      graphQLErrors.map(({ message, locations, path }) =>
        console.log(
          `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
        )
      );

      if (networkError) console.log(`[Network error]: ${networkError}`);
    },
  });

  const [updateStatus] = useMutation(DYNAMIC_UPDATE, {
    onCompleted({ dynamicUpdate }) {},
    onError({ graphQLErrors, networkError }) {
      graphQLErrors.map(({ message, locations, path }) =>
        console.log(
          `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
        )
      );

      if (networkError) console.log(`[Network error]: ${networkError}`);
    },
  });

  const getInputUpdate = (table, columns, conditions) => {
    return {
      table,
      columns,
      conditions,
    };
  };

  const saveDirector = async (e) => {
    let input;
    let observaciones = sessionStorage.getItem("obsevaciones");
    if (observaciones !== null) {
      input = {
        table: "cOBS_ObservacionesViaticos",
        columns: ["cVIT_Id", "cEMP_Id", "cOBS_Descripcion", "cTOBS_Id"],
        detailValues: [
          {
            values: [
              "" + context.state.viaticosProps.cVIT_Id,
              "" + cEMP_Id.cEMP_Id,
              sessionStorage.getItem("obsevaciones"),
              "" + getcTOBS_Id(cEMP_Id.profile),
            ],
          },
        ],
      };
      await insertProducts({ variables: { input } });
    }
    let columns = [
      {
        setkey: "cVIT_Status_VIAT",
        setval: "" + e.target.id,
      },
    ];
    let conditions = [
      {
        valone: "cREU_Id",
        valtwo: "" + context.state.viaticosProps.cREU_Id,
        condition: "=",
      },
      {
        valone: "cVIT_Id",
        valtwo: "" + context.state.viaticosProps.cVIT_Id,
        condition: "=",
        logic: "AND",
      },
    ];
    input = getInputUpdate("cVIT_Viaticos", columns, conditions);
    await updateStatus({ variables: { input } }).then(({ data }) => {
      if (data.dynamicUpdate.statusCode === 200) {
        handleComponent();
      }
    });
  };

  const handleChange = ({ target }) => {
    console.log(target.value);
    sessionStorage.setItem("obsevaciones", target.value);
  };

  useEffect(() => {
    sessionStorage.clear();
    getEventsByViaticos({
      variables: {
        cEMP_Id: cEMP_Id.cEMP_Id,
        cREU_FechaInicio: new Date().toISOString().slice(0, 10),
      },
    });
    sessionStorage.setItem("cTIP_Id", 1);
    console.log(cEMP_Id.profile);
    console.log("context====>", context.state.viaticosProps);
    if (context.state.viaticosProps.cVIT_Id !== "") {
      setViajes(context.state.viaticosProps.viajes);
      setAlojamientos(context.state.viaticosProps.alojamientos);
      setcTIP_Id(context.state.viaticosProps.cTIP_Id);
      setMateriales(context.state.viaticosProps.materiales);
      setPromocionales(context.state.viaticosProps.promocionales);
      setRequerimientoTI(context.state.viaticosProps.requerimientoTI);
      getEventBycREU({
        variables: { cREU_Id: context.state.viaticosProps.cREU_Id },
      });
    }
  }, []);
  return (
    <PageContainer marginTop="20px" width="70%" id="button-navigation">
      <Subtitle color="#000">
        {"Realiza la comprobación de tus gastos" || title}
      </Subtitle>
      <CardPendientes
        cVIT_Status_Viat={context.state.viaticosProps.cVIT_Status_VIAT}
        cREU_Id={context.state.viaticosProps.cREU_Id}
        cVIT_Status_Comp={context.state.viaticosProps.cVIT_Status_COMP}
      />
      <ContainerTwoInputs
        width="100%"
        margin="20px 0px"
        id="viaticos-solicitud"
      >
        <Card data={data} img={calendario} />
        <Card data={data2} img={user} />
      </ContainerTwoInputs>
      <ButtonsInformacion
        title="Tarjeta a comprobar"
        text1="Amex"
        text2="Tarjeta Corporativa"
        text3="Ambas Tarjetas"
        cTIp_Id={context.state.viaticosProps.cTIP_Id}
      />
      {value === "Amex" ? null : (
        <CardUpDown
          title="AMEX"
          text="Anexar documentos de movimientos"
          text2="Subir archivos de gastos"
        >
          {" "}
          <img src={upload} alt="upload" width="100px" />{" "}
        </CardUpDown>
      )}
      {viajes &&
        viajes.map((viaje, index) => {
          return (
            <InfoViaje
              title={
                cTIP_Id === 1 && index === 0
                  ? "Viaje de Ida"
                  : cTIP_Id === 1 && index === 1
                  ? "Viaje de Vuelta"
                  : cTIP_Id === 3
                  ? `Tramo ${index + 1}`
                  : "Viaje Ida"
              }
              info={viaje}
            />
          );
        })}
      {alojamientos &&
        alojamientos.map((alojamiento, index) => {
          console.log("Alojamiento=====>", alojamiento);
          return (
            <InfoAlojamiento
              title={
                cTIP_Id === 1
                  ? "Informacion de alojamiento"
                  : `Alojamiento - Tramo ${index + 1}`
              }
              value={alojamiento}
            />
          );
        })}
      <InfoMateriales title="Equipo o materiales para viaje" cambio={value} />
      <div style={{ marginTop: "20px" }}>
        <h3>Total del viaje</h3>
        <div style={{ display: "flex", flexDirection: "row" }}>
          <Typography>Precio:</Typography>
          <Typography
            style={{ color: "#3898EC", marginLeft: "20px" }}
          >{`$${totalViaticos(
            viajes,
            alojamientos,
            totalMateriales(materiales, promocionales, requerimientoTI)
          )}`}</Typography>
        </div>
      </div>
      {context.state.viaticosProps.observaciones.map((data) => {
        console.log("Data observaciones", data);
        return (
          <Fragment>
            <div style={{ marginTop: "20px" }}>
              <h3 style={{ textAlign: "left" }}>{`Observaciones - ${getRol(
                data.cTOBS_Id
              )}`}</h3>
            </div>
            <TextareaAutosize
              rowsMin={10}
              style={{ width: "90%" }}
              id="text-mensaje"
              name="area"
              value={`${data2.titulo} :
${data.cOBS_Descripcion}`}
              disabled
            />
          </Fragment>
        );
      })}
      <div style={{ marginTop: "20px" }}>
        <h3 style={{ textAlign: "left" }}>observaciones</h3>
      </div>
      <TextareaAutosize
        rowsMin={10}
        style={{ width: "90%" }}
        id="text-mensaje"
        name="observaciones"
        onChange={handleChange}
        value={data.cOBS_Descripcion}
        placeholder="Escribe comentarios del viaje ..."
      />
      {/* <ToggleButtons/> */}
      <div
        style={{
          marginTop: "20px",
          justifyContent: "center",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <ButtonNavigation
          text="Autorizar"
          width="45%"
          backgroundColor="#3898EC"
          border="none"
          color="#fff"
          justifyContent="center"
          onClick={saveDirector}
          id={4}
        />
        <ButtonNavigation
          text="Rechazar"
          width="45%"
          backgroundColor="#fff"
          border="none"
          color="#3898EC"
          justifyContent="center"
          onClick={saveDirector}
          id={3}
        />
        <ButtonNavigation
          text="Regresar al Solicitante"
          width="45%"
          backgroundColor="#fff"
          border="none"
          color="#3898EC"
          justifyContent="center"
          onClick={saveDirector}
          id={1}
        />
        <ButtonNavigation
          text="Regresar a Asistente"
          width="45%"
          backgroundColor="#fff"
          border="none"
          color="#3898EC"
          justifyContent="center"
          onClick={saveDirector}
          id={0}
        />
      </div>
      <h3 style={{ marginTop: "25px" }}>
        <strong>DESCARGAR DETALLES DE VIAJE</strong>
      </h3>
      <p>
        Realiza la descarga de la solicitud de viáticos con el detalle de
        precios y observaciones.
      </p>
      <div style={{ color: "#3898Ec", display: "flex", alignItems: "center" }}>
        <img src={descargar} alt="download" width="100px" />
        <p>Descargar solicitud</p>
      </div>
    </PageContainer>
  );
};

export default useComprobacion;
