import { Chip, TextareaAutosize } from '@material-ui/core'
import React,{useState,useEffect, useContext} from 'react'
import ToggleButtons from '../Viaticos/ToggleButons.js'
import upload from '../../assets/images/icons/upload.svg'
import { Clear, PhotoCamera } from '@material-ui/icons'
import ButtonNavigation from '../Viaticos/ButtonNavigation.js'
import '../components.css'
import { makeStyles } from '@material-ui/core/styles';
import {useMutation} from 'react-apollo'
import {DINAMIC_GET,DYNAMIC_INSERT} from '../../mutation/mutation'
import Areas_Inv from './Areas_Inv.js';
import {MyContext} from '../Context';


const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'flex-start',
    flexWrap: 'wrap',
    '& > *': {
      margin: theme.spacing(0.5),
    },
  },
}));

function doClick() {
  const formUpload = document.getElementsByClassName('form-file');
  if(formUpload){
    formUpload[0].click()
  }
}

export default function Comentarios({cSUBE_Id,setCTIPR_Id,onClick1}) {
  const [areas,setAreas]=useState()
  const [subArea,setSubArea]=useState()
  const [open,setOpen]=useState(cSUBE_Id===2 ? true : false)
  const [cACU_Id,setCACU_Id]=useState()

  const classes = useStyles();
  
  const context=useContext(MyContext)

  const [getArea]=useMutation(DINAMIC_GET,{
    onCompleted({dynamicGet}){
      setAreas(JSON.parse(dynamicGet.response))
    }
  })

  const [saveEntorno] = useMutation(DYNAMIC_INSERT,{
    onCompleted({dynamicInsert}){
      console.log(JSON.parse(dynamicInsert.response))
        setCACU_Id(JSON.parse(dynamicInsert.response).cACU_Id)
    },
    onError({ graphQLErrors, networkError }) {

      graphQLErrors.map(({ message, locations, path }) =>
          console.log(
              `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
          ),
      );

      if (networkError) console.log(`[Network error]: ${networkError}`);
    }
  })

  const [saveComentario] = useMutation(DYNAMIC_INSERT,{
    onCompleted({dynamicInsert}){
      console.log(dynamicInsert)
      if(dynamicInsert.statusCode===200){
        console.log(dynamicInsert)
      }
    },
    onError({ graphQLErrors, networkError }) {

      graphQLErrors.map(({ message, locations, path }) =>
          console.log(
              `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
          ),
      );

      if (networkError) console.log(`[Network error]: ${networkError}`);
    }
  })

  const [getSubArea]=useMutation(DINAMIC_GET,{
    onCompleted({dynamicGet}){
      setSubArea(JSON.parse(dynamicGet.response).map((dynamic)=>{
        return({...dynamic,status:false})
      }))
    }
  })

  const onchange=({value})=>{
    const {cARE_Id,cSUBA_Id}=JSON.parse(value)
    console.log(cARE_Id)
    console.log(cSUBA_Id)
    setSubArea(subArea.map((sArea)=>{
      if(sArea.cARE_Id==cARE_Id && sArea.cSUBA_Id==cSUBA_Id){
        return{...sArea,status:!sArea.status}
      }
      else{
        return{...sArea}
      }
    }))
  }

  const deleteSubarea=(cSUBA_Id,cARE_Id)=>{
    setSubArea(subArea.map((sArea)=>{
      if(sArea.cARE_Id==cARE_Id && sArea.cSUBA_Id==cSUBA_Id){
        return{...sArea,status:!sArea.status}
      }
      else{
        return{...sArea}
      }
    }))
  }

  const save =async()=>{
    let input={
      table:'cACU_CuerdosVieje',
      columns:[
      ],
      detailValues:[{
        values:[
          
        ]
      }]
    }
    if(context.state.saveAcuerdos.cTIPRE_Id!==''){
      input.columns.push('cTIPRE_Id')
      input.detailValues[0].values.push(''+context.state.saveAcuerdos.cTIPRE_Id)
    }
    if(context.state.saveAcuerdos.CVIS_Id!==''){
      input.columns.push('cVIS_Id')
      input.detailValues[0].values.push(''+context.state.saveAcuerdos.CVIS_Id)
    }
    if(context.state.saveAcuerdos.cEMP_Id!==''){
      input.columns.push('cEMP_Id')
      input.detailValues[0].values.push(''+context.state.saveAcuerdos.cEMP_Id)
    }
    if(context.state.saveAcuerdos.cREU_Id!==''){
      input.columns.push('cREU_Id')
      input.detailValues[0].values.push(''+context.state.saveAcuerdos.cREU_Id)
    }
    if(context.state.saveAcuerdos.cENT_Id!==''){
      input.columns.push('cENT_Id')
      input.detailValues[0].values.push(''+context.state.saveAcuerdos.cENT_Id)
    }
    if(context.state.saveAcuerdos.cTEM_Id!==''){
      input.columns.push('cTEM_Id')
      input.detailValues[0].values.push(''+context.state.saveAcuerdos.cTEM_Id)
    }
    if(context.state.saveAcuerdos.cSUBE_Id!==''){
      input.columns.push('cSUBE_Id')
      input.detailValues[0].values.push(''+context.state.saveAcuerdos.cSUBE_Id)
    }
    if(context.state.saveAcuerdos.cTIPR_Id!==''){
      input.columns.push('cTIPR_Id')
      input.detailValues[0].values.push(''+context.state.saveAcuerdos.cTIPR_Id)
    }
    if(context.state.saveAcuerdos.cSUBE_Descripcion!==''){
      input.columns.push('cSUBE_Descripcion')
      input.detailValues[0].values.push(''+context.state.saveAcuerdos.cSUBE_Descripcion)
    }
    // await saveEntorno(
    //   {variables:{input}}
    // ).then(({data})=>{
    //   const response=JSON.parse(data.dynamicInsert.response).cACU_Id
    //   input.table='cCOMA_ComentariosAcuerdo'
    // input.columns=['cACU_Id','cCOMA_Descripcion']
    // input.detailValues=[{
    //   values:[
    //     ''+response,
    //     context.state.saveAcuerdos.cCOMA_Descripcion
    //   ]
    // }]
    // saveComentario(
    //   {variables:{input}}
    // ).then(onClick1())
    
    // })
    onClick1()

  }
  
  useEffect(()=>{
    getArea({
      variables:{
        input:{
          table:"cARE_AREASCRM",
          columns:["cARE_Id","cARE_Descripcion"]
        }
      }
    })
    getSubArea({
      variables:{
        input:{
          table:"cSUBA_SubAreasCRM",
          columns:["cSUBA_Id","cARE_Id","cSUBA_Descripcion"]
        }
      }
    })
  },[])

  return (
    <div style={{width:'100%',textAlign:'-webkit-center'}} className='registro-container'>
      <div style={{display:'flex',flexDirection:'column',alignItems:'center',marginBottom:'25px'}}>
        <TextareaAutosize rowsMin={10} style={{width:'90%'}}  placeholder="Escribe tus comentarios" onChange={({target})=>context.setSaveAcuerdos({cCOMA_Descripcion:target.value})}/>
        <ToggleButtons/>
      </div>
      {
        setCTIPR_Id===9?
        <div style={{display:'flex'}}>
          <div style={{display:'flex',alignItems:'center',margin:"25px"}}>
            {/* <img src={upload} width='50px' alt='upload'/>
            <h4>Subir documento</h4> */}
            <input type='file' required multiple style={{position:'absolute',left:'-1000em'}} className='form-file'/>
            <a style={{display:'flex'}} onClick={doClick}>
              <img src={upload} width='50px' alt='upload'/>
              <h4>Subir documento</h4>
            </a>
          </div>
          <div style={{display:'flex',alignItems:'center',margin:"25px"}}>
            <PhotoCamera style={{color:'#3898EC',fontSize:'40px'}}/>
            <h4>Tomar foto</h4>
          </div>
        </div>:null
      }
      {
        cSUBE_Id===2?
        <div style={{width:'90%',display:'flex',flexDirection:'column',textAlign:'initial'}} onClick={()=>setOpen(true)}>
          <h4 style={{color:'#3898EC'}}>Áreas</h4>
          <div className={classes.root}>
          {
              subArea&&
              subArea.map(({cSUBA_Descripcion,cSUBA_Id,cARE_Id,status},index)=>
              status?
                <Chip label={cSUBA_Descripcion} style={{backgroundColor:'#3898EC',color:'#FFFF'}} onDelete={()=>deleteSubarea(cSUBA_Id,cARE_Id)} deleteIcon={<Clear/>} key={index}/>:null
              )
          }
          </div>
        </div>:null
      }
      <ButtonNavigation text='Guardar y continuar' backgroundColor='#3898EC' border='#3898EC' color='#FFFFFF' width='75%' onClick={save}/>
      <Areas_Inv areas={areas} subArea={subArea} open={open} setOpen={()=>setOpen(false)} onChangue={onchange}/> 
    </div>
  )
}
