export const getDateFormat=(date)=>{
  const options = {  year: 'numeric', month: 'short', day: '2-digit' };
  const regex=/ de/gi;
  if(date!==undefined){
    let inicio=new Date(date);
  inicio.setDate(inicio.getDate()+1);
  inicio=inicio.toISOString().slice(0,10);
  inicio=new Date(`${inicio.slice(0,9)}${parseInt(inicio.slice(9,10))}`).toLocaleDateString("es-MX",options)
  inicio=inicio.replace(regex,'');
  inicio=`${inicio.slice(0,6)} ${inicio.slice(-4)}`;
  return inicio;
  }  
  else{
    return null;
  }
}

export const getcTOBS_Id=(profile)=>{
  let cTOBS_Id;
  switch(profile){
    case 60://usuario
      cTOBS_Id=1;
      break;
    case 61://asistente
      cTOBS_Id=2;
      break;
    case 63://jefeDirecto 62
      cTOBS_Id=3;
      break;
    case 64://Director 63
      cTOBS_Id=4;
      break;
    case 65://Contabilidad 64
      cTOBS_Id=5;
      break;
    default:cTOBS_Id=null;
  }
  return cTOBS_Id;
}

export const getRol=(cTOBS_Id)=>{
  let rol;
  switch(cTOBS_Id){
    case 1:
      rol="Solicitante";
      break;
    case 2:
      rol="Asistente";
      break;
    case 3:
      rol="Jefe";
      break;
    case 4:
      rol="Director";
      break;
    case 5:
      rol="Contabilidad";
      break;
    default:rol="";
  }
  return rol;
}

export const totalMateriales=(materiales,promocionales,requerimientos)=>{
  let tMaterial=0;
  let tPromocional=0;
  let tRequerimiento=0;
  materiales.forEach(({cMAT_Precio})=>{//SE cambio map
    tMaterial=tMaterial+parseInt(cMAT_Precio,10);
  })
  promocionales.forEach(({cPRO_Precio})=>{//SE cambio map
    tPromocional=tPromocional+parseInt(cPRO_Precio,10);
  })
  requerimientos.forEach(({cREQT_Precio})=>{//SE cambio map
    tRequerimiento=tRequerimiento+parseInt(cREQT_Precio,10);
  })
  console.log("Materiales suma",tMaterial+tPromocional+tRequerimiento)
  return tMaterial+tPromocional+tRequerimiento;
}

export const totalViaticos=(viajes,alojamiento,material)=>{
  let tViajes=0;
  let tAlojamiento=0;
  viajes.forEach(({cVIA_PrecioVuelo})=>{//SE cambio map
    console.log("PRECIO VUELO",cVIA_PrecioVuelo);
    tViajes=tViajes+parseInt(cVIA_PrecioVuelo,10);
  })
  alojamiento.forEach(({cALO_Precio})=>{//SE cambio map
    console.log("precio alojamiento",cALO_Precio)
    tAlojamiento=tAlojamiento+parseInt(cALO_Precio,10);
  })
  console.log(tViajes+tAlojamiento)
  return(tViajes+tAlojamiento+material);
}