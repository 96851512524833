import React from 'react';
import { NativeSelect } from '@material-ui/core';
import ButtonNavigation from '../../Viaticos/ButtonNavigation';

const DropdownAreas = ({ onClick, paramsOnclick = {}, handleChange, state, txtBtn = "Otra Area" }) => {
    return (
        <div
            style={{
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
            }}
        >
            <h3>¿Qué áreas están involucradas?</h3>
            <NativeSelect
                style={{ width: '100%', marginTop: 10 }}
                name='Direccion'
                value={state['Direccion']}
                onChange={handleChange}
            >
                <option value="">Dirección General</option>
                <option value="Control Interno">Control Interno</option>
                <option value="Director General">Director General</option>
                <option value="Planeación y Coordinación">
                    Planeación y Coordinación
                </option>
                <option value="Transformación Operativa">
                    Transformación Operativa
                </option>
            </NativeSelect>
            <NativeSelect
                style={{ width: '100%', marginTop: 10 }}
                name='Finanzas'
                value={state['Finanzas']}
                onChange={handleChange}
            >
                <option value="">Finanzas</option>
                <option value="Actuaría">Actuaría</option>
                <option value="Contabilidad">Contabilidad</option>
                <option value="Fiscal">Fiscal</option>
                <option value="Modelación / Pricing">Modelación / Pricing</option>
                <option value="Tesorería">Tesorería</option>
            </NativeSelect>
            <NativeSelect
                style={{ width: '100%', marginTop: 10 }}
                name='Operaciones'
                value={state['Operaciones']}
                onChange={handleChange}
            >
                <option value="">Operaciones</option>
                <option value="Administración Técnica">Administración Técnica</option>
                <option value="Pull de Asistentes">Pull de Asistentes</option>
                <option value="Servicios de Oficina">Servicios de Oficina</option>
                <option value="Siniestros">Siniestros</option>
                <option value="Retrocesiones">Retrocesiones</option>
            </NativeSelect>
            <NativeSelect
                style={{ width: '100%', marginTop: 10, marginBottom: 15 }}
                name='Suscripcion'
                value={state['Suscripcion']}
                onChange={handleChange}
            >
                <option value="">Suscripción</option>
                <option value="Contratos Daños">Contratos Daños</option>
                <option value="Facultativo Daños">Facultativo Daños</option>
                <option value="Fianzas">Fianzas</option>
                <option value="Líneas Especiales">Líneas Especiales</option>
                <option value="Oficina Chile">Oficina Chile</option>
                <option value="Personas">Personas</option>
                <option value="Ultramar">Ultramar</option>
            </NativeSelect>
            <ButtonNavigation
                text={txtBtn}
                onClick={() => onClick(paramsOnclick)}
            />
        </div>
    );
}

export default DropdownAreas;