import React,{forwardRef} from 'react';
import {Button,Dialog,DialogActions,DialogContent,DialogContentText,DialogTitle,Slide} from '@material-ui/core';
const Transiction = forwardRef(function Transition(props,ref){
  return <Slide direction='up' ref={ref} {...props}/>
});

const DialogViat=({open,handleClose})=>{
  return(
    <Dialog open={open} TransitionComponent={Transiction} keepMounted onClose={handleClose} aria-labelledby="alert-dialog-slide-title" aria-describedby="alert-dialog-slide-description">
      <DialogTitle style={{textAlign:"center"}}>No se pudo crear tu solicitud</DialogTitle>
      <DialogContent>
            <div style={{flexDirection:'row',display:'flex'}}>
              <DialogContentText style={{textAlign:"center"}}>Ya existe una solicitud de viático para la reunión</DialogContentText>
            </div>
      </DialogContent>
      <DialogActions style={{justifyContent:"center"}}>
      <Button onClick={handleClose} style={{color:'#3898EC',border:'solid 1px   #3898EC',width:"45%"}}>
                Cerrar
            </Button>
      </DialogActions>
    </Dialog>
  )
}

export default DialogViat;