import React, {Fragment} from 'react';
import { AcordeonContainerBody } from '../StylesComponents/common-styled';
import {  Typography } from '@material-ui/core';
import FormTypography from './FormsTypography';

const infoViaje=({title,info,pos})=>{
  const {cVIA_Origen,cVIA_Destino,cVIA_Areolinea,cVIA_NoVuelo,cVIA_URLVueloCot,cVIA_HorarioVuelo,cVIA_PrecioVuelo} = info[pos];
  return(
    <Fragment>
      <div style={{marginTop:'20px'}}>
        <h3 style={{textAlign:'center'}}>{title}</h3>
      </div>
      {
        info?
        <Fragment>
          <AcordeonContainerBody>
            <FormTypography text="Origen:" direction="row">
              <Typography><strong>{cVIA_Origen}</strong></Typography>
            </FormTypography>
            <FormTypography text="Destino:" direction="row">
              <Typography><strong>{cVIA_Destino}</strong></Typography>
            </FormTypography>
          </AcordeonContainerBody>
          <AcordeonContainerBody>
            <FormTypography text="Aerolinea:" direction="row">
              <Typography><strong>{cVIA_Areolinea}</strong></Typography>
            </FormTypography>
            <FormTypography text="No. de vuelo:" direction="row">
              <Typography><strong>{cVIA_NoVuelo}</strong></Typography>
            </FormTypography>
          </AcordeonContainerBody>
          <AcordeonContainerBody>
            <FormTypography text="Horario de vuelo:" direction="row">
              <Typography><strong>{`${cVIA_HorarioVuelo?cVIA_HorarioVuelo.slice(11,16):''} hrs`}</strong></Typography>
            </FormTypography>
            <FormTypography text="Precio de vuelo:" direction="row">
              <Typography><strong>{`$ ${cVIA_PrecioVuelo}`}</strong></Typography>
            </FormTypography>
          </AcordeonContainerBody>
          <AcordeonContainerBody>
            <FormTypography text="URL de cotización:" direction="row">
              <Typography><strong>{cVIA_URLVueloCot}</strong></Typography>
            </FormTypography>
          </AcordeonContainerBody>

        </Fragment>:null
      }
    </Fragment>
  )
}

export default infoViaje;