import React, { useEffect, useState, useContext, Fragment } from 'react';
import ButtonNavigation from '../Viaticos/ButtonNavigation';
import { useMutation } from 'react-apollo';
import { DINAMIC_GET } from '../../mutation/mutation';
import Comentarios from './Comentarios';
import { MyContext } from '../Context';
import { ArrowBackIos } from '@material-ui/icons';
export default function Entorno({ onClick1, onGoBack }) {
  const context = useContext(MyContext);
  const [entorno, setEntorno] = useState();
  const [input, setInput] = useState({
    table: 'cENT_Entorno',
    columns: ['cENT_Descripcion', 'cENT_Id'],
  });
  const [lastInput, setLastInput] = useState({
    table: 'cENT_Entorno',
    columns: ['cENT_Descripcion', 'cENT_Id'],
  });
  const [lastId, setLastId] = useState(null);
  const [cENT_Id, setCent_Id] = useState(0);
  const [cENT_Descripcion, setDescripcion] = useState();
  const [cTEM_Descripcion, setCTem_Descripcion] = useState();
  const [cTEM_Id, setCTem_Id] = useState(0);
  const [comentarios, setComentarios] = useState(false);
  const [cSUBE_Id, setCSUBE_Id] = useState(0);
  const [cSUBE_Descripcion, setCSUBE_Descripcion] = useState();
  const [cTIPR_Id, setCTIPR_Id] = useState(0);

  const [getEntorno] = useMutation(DINAMIC_GET, {
    onCompleted({ dynamicGet }) {
      setEntorno(JSON.parse(dynamicGet.response));
      console.log(JSON.parse(dynamicGet.response));
    },
  });

  /**
   * @name goBackOnEntorno
   * @param {Object} lastInput the input to reemake the query
   */
  const goBackOnEntorno = (id) => {
    console.log('BACK_ID', id);debugger
    switch (id) {
      case 1:
        setInput({
          table: 'cENT_Entorno',
          columns: ['cENT_Descripcion', 'cENT_Id'],
        });
        break;
      case 2:
        setInput({
          table: 'cTEM_TemasEntorno',
          columns: ['cTEM_Descripcion', 'cTEM_Id']
          // conditions: [
          //   {
          //     valone: 'cENT_Id',
          //     valtwo: `${cENT_Id}`,
          //     condition: '=',
          //   },
          // ],
        });
        break;
      case 3:
        setInput({
          table: 'cSUBE_SubtemasEntorno',
          columns: ['cSUBE_Id', 'cTEM_Id', 'cSUBE_Descripcion'],
          conditions: [
            {
              valone: 'cTEM_Id',
              valtwo: `${cTEM_Id}`,
              condition: '=',
            },
          ],
        });
        setComentarios(false);
        break;
      case 4:
        break;
    }
  };

  const changueRamo = ({ cENT_Id, cENT_Descripcion }) => {
    console.log('CHANGE_LAST', 'cENT_Entorno');debugger
    setInput({
      table: 'cTEM_TemasEntorno',
      columns: ['cTEM_Descripcion', 'cTEM_Id']
      // conditions: [
      //   {
      //     valone: 'cENT_Id',
      //     valtwo: `${cENT_Id}`,
      //     condition: '=',
      //   },
      // ],
    });
    setLastInput({
      table: 'cENT_Entorno',
      columns: ['cENT_Descripcion', 'cENT_Id'],
    });
    setCent_Id(cENT_Id);
    setDescripcion(cENT_Descripcion);
    context.setSaveAcuerdos({
      cENT_Id: cENT_Id,
      cENT_Descripcion: cENT_Descripcion,
    });
  };

  const changueTema = ({ cTEM_Descripcion, cTEM_Id }) => {
    setCTem_Descripcion(cTEM_Descripcion);
    setCTem_Id(cTEM_Id);
    setInput({
      table: 'cSUBE_SubtemasEntorno',
      columns: ['cSUBE_Id', 'cTEM_Id', 'cSUBE_Descripcion'],
      conditions: [
        {
          valone: 'cTEM_Id',
          valtwo: `${cTEM_Id}`,
          condition: '=',
        },
      ],
    });
    context.setSaveAcuerdos({ cTEM_Id: cTEM_Id });
  };

  const changueTemaF = (cSUBE_Id, cSUBE_Descripcion) => {
    setComentarios(true);
    setCSUBE_Id(cSUBE_Id);
    setCSUBE_Descripcion(cSUBE_Descripcion);
    context.setSaveAcuerdos({
      cSUBE_Id: cSUBE_Id,
      cSUBE_Descripcion: cSUBE_Descripcion,
    });
    if (parseInt(cSUBE_Id, 10) >= 30) {
      setInput({
        table: 'cTIPR_TipoRegistro',
        columns: ['cTIPR_Id', 'cSUBE_Id', 'cTIPR_Descripcion'],
        conditions: [
          {
            valone: 'cSUBE_Id',
            valtwo: `${cSUBE_Id}`,
            condition: '=',
          },
        ],
      });
    }
  };

  const changueTipo = (cTIPR_Id, cTIPR_Descripcion) => {
    context.setSaveAcuerdos({ cTIPR_Id: cTIPR_Id, cTIPR_Descripcion });
    setCSUBE_Id(0);
    setCTIPR_Id(cTIPR_Id);
  };

  useEffect(() => {
    console.log('CHANGE_ENTORNO');
    getEntorno({
      variables: {
        input,
      },
    });
  }, [input, getEntorno]);

  return (
    <div>
      {entorno && entorno.length >= 0 && entorno[0].cENT_Descripcion ? (
        <Fragment>
          <div onClick={() => onGoBack()} style={{ cursor: 'pointer' }}>
            <ArrowBackIos style={{ color: '#01539C' }} />
          </div>
          <h2>Entorno</h2>
          <h3>¿De qué ramo deseas hacer tu registro?</h3>
        </Fragment>
      ) : null}
      {entorno && entorno.length >= 0 && entorno[0].cTEM_Descripcion ? (
        <Fragment>
          <div onClick={() => goBackOnEntorno(1)} style={{ cursor: 'pointer' }}>
            <ArrowBackIos style={{ color: '#01539C' }} />
          </div>
          <div style={{ display: 'flex' }}>
            <h4 style={{ color: '#01539C' }}>Entorno</h4>
            <h4>&gt; {cENT_Descripcion}</h4>
          </div>
          <h4>¿De qué tema deseas hacer tu registro?</h4>
        </Fragment>
      ) : null}
      {entorno && entorno.length >= 0 && entorno[0].cSUBE_Id && !comentarios ? (
        <Fragment>
          <div onClick={() => goBackOnEntorno(2)} style={{ cursor: 'pointer' }}>
            <ArrowBackIos style={{ color: '#01539C' }} />
          </div>
          <div style={{ display: 'flex' }}>
            <h4 style={{ color: '#01539C' }}>Entorno</h4>
            <h4> &gt; {cENT_Descripcion}</h4>
            <h4> &gt; {cTEM_Descripcion}</h4>
          </div>
          <h4>¿De qué tema deseas hacer tu registro?</h4>
        </Fragment>
      ) : null}
      {comentarios && (
        <Fragment>
          <div onClick={() => goBackOnEntorno(3)} style={{ cursor: 'pointer' }}>
            <ArrowBackIos style={{ color: '#01539C' }} />
          </div>
          <div style={{ display: 'flex' }}>
            <h4 style={{ color: '#01539C' }}>Entorno</h4>
            <h4> &gt; {cENT_Descripcion}</h4>
            <h4> &gt; {cTEM_Descripcion}</h4>
            <h4> &gt; {cSUBE_Descripcion}</h4>
          </div>
          {parseInt(cSUBE_Id, 10) >= 30 ? (
            <h4>¿De que tema deseas hacer tu registro?</h4>
          ) : (
            <h4>Escribe un comentario sobre {cSUBE_Descripcion}</h4>
          )}
        </Fragment>
      )}
      <div
        style={{
          width: '100%',
          textAlign: 'center',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        {entorno && entorno.length >= 0 && entorno[0].cENT_Descripcion ? (
          <Fragment>
            {entorno.map((entor, index) => (
              <ButtonNavigation
                style={{ cursor: 'pointer' }}
                text={entor.cENT_Descripcion}
                key={index}
                onClick={() => changueRamo(entor)}
              />
            ))}
          </Fragment>
        ) : null}
        {entorno && entorno.length >= 0 && entorno[0].cTEM_Descripcion ? (
          <Fragment>
            {entorno.map((entor, index) => (
              <ButtonNavigation
                style={{ cursor: 'pointer' }}
                text={entor.cTEM_Descripcion}
                key={index}
                onClick={() => changueTema(entor)}
              />
            ))}
          </Fragment>
        ) : null}
        {entorno &&
        entorno.length >= 0 &&
        entorno[0].cSUBE_Id &&
        !comentarios ? (
          <Fragment>
            {entorno.map(({ cSUBE_Id, cSUBE_Descripcion }, index) => {
              if (cSUBE_Id === 12) {
                return (
                  <>
                    <ButtonNavigation
                      style={{ cursor: 'pointer' }}
                      text={'Primas No Vida'}
                      key={index}
                      onClick={() => changueTemaF(26, 'Primas No Vida')}
                    />
                    <ButtonNavigation
                      style={{ cursor: 'pointer' }}
                      text={cSUBE_Descripcion}
                      key={index}
                      onClick={() => changueTemaF(cSUBE_Id, cSUBE_Descripcion)}
                    />
                  </>
                );
              } else if (cSUBE_Id !== 26) {
                return (
                  <ButtonNavigation
                    style={{ cursor: 'pointer' }}
                    text={cSUBE_Descripcion}
                    key={index}
                    onClick={() => changueTemaF(cSUBE_Id, cSUBE_Descripcion)} />
                );
              }
            })}
          </Fragment>
        ) : null}
        {comentarios && parseInt(cSUBE_Id, 10) < 30 ? (
          <Comentarios
            cSUBE_Id={cSUBE_Id}
            setCTIPR_Id={cTIPR_Id}
            onClick1={onClick1}
          />
        ) : parseInt(cSUBE_Id, 10) >= 30 ? (
          <Fragment>
            {entorno.map(({ cTIPR_Id, cTIPR_Descripcion }, index) => (
              <ButtonNavigation
                style={{ cursor: 'pointer' }}
                text={cTIPR_Descripcion}
                key={index}
                onClick={() => changueTipo(cTIPR_Id, cTIPR_Descripcion)}
              />
            ))}
          </Fragment>
        ) : null}
      </div>
    </div>
  );
}

//     comentarios?
//     <Comentarios ramo={cENT_Descripcion} tema1={cTEM_Descripcion} tema2={tem} idComentario={idComentario}/>:null
//   }
// </div>
