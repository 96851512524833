import React from 'react';
import { VpnKey } from '@material-ui/icons';
import Slider from './Slider';
import '../components.css';


export default function FirstColumnSnapshot({
  territorioOptions,
  countryOptions,
  operationOptions,
}) {
  return (
    <div className="snapshot__content__column">
      <div className="snapshot__content__first-header">
        <img src="/images/logo_patria.png" alt="logo_patria" width={68} />
      </div>
      <span className="snapshot_filter">Filtros</span>
      <div className="snapshot__filters_container">
        <div className="snapshot__filters_container_content">Territorio</div>
        <div className="snapshot__filters_container_content">País</div>
        <div className="snapshot__filters_container_content">Operación</div>
        <select
          placeholder="Todas"
          className="snapshot__filters_container_select"
        >
          <option value="">Todas</option>
          {territorioOptions.map(({ cTER_Nombre }) => (
            <option value={cTER_Nombre}>{cTER_Nombre}</option>
          ))}
        </select>
        <select
          placeholder="Todas"
          className="snapshot__filters_container_select"
        >
          <option value="">Todas</option>
          {countryOptions.map(({ cPAI_Nombre }) => (
            <option value={cPAI_Nombre}>{cPAI_Nombre}</option>
          ))}
        </select>
        <select
          placeholder="Todas"
          className="snapshot__filters_container_select"
        >
          <option value="">Todas</option>
          {operationOptions.map(({ cGRM_Nombre }) => (
            <option value={cGRM_Nombre}>{cGRM_Nombre}</option>
          ))}
        </select>
      </div>
      <div className="snapshot__unlicensed_container">
        <div className="snapshot__unlicensed_content">
          <div style={{ paddingRight: 20 }}>
            <span style={{ padding: '0 5px', color: 'red' }}>Unlicensed</span>{' '}
            <VpnKey color="error" />
          </div>
        </div>
        <div className="snapshot__unlicensed_content">
          <div style={{ paddingRight: 20 }}>
            <span style={{ padding: '0 5px', color: 'red' }}>Unlicensed</span>{' '}
            <VpnKey color="error" />
          </div>
        </div>
      </div>
      <div className="snapshot__unlicensed_container">
        <div className="">
          <select style={{ width: '95%' }}>
            <option value="">Id de Cedente</option>
          </select>
        </div>
        <div className="">
          <select style={{ width: '95%' }}>
            <option value="">Bloque Económico</option>
          </select>
        </div>
      </div>
      <p className="snapshot__title">
        Detalles de cedentes por grupo financiero
      </p>
      <div className="snapshot__cedente-list-container">
        <div className="snapshot__cedente-list-button">Cedentes</div>
        <div className="snapshot__cedente-list-content">
          <p>AIG Insurance Company Puerto Rico</p>
          <p>AACHENER RUCK</p>
          <p>ABA Seguros SA de CV</p>
          <p>ABA Seguros SA de CV</p>
        </div>
      </div>
      <p className="snapshot__title">Periodo</p>
      <div className="snapshot__registry-date-container">
        <div className="snapshot__registry-title">Fecha de Registro</div>
        <div className="snapshot__select-dates-container">
         <input type="date" name="firstDate" /> 
          <input type="date" name="secondDate" style={{ marginLeft: 10 }} />
        </div>
      </div>
      {/* {<img
        src="/images/separador.jpeg"
        alt=""
        style={{ width: '97%', padding: '20px 0', height: 70 }}
      />} */}
      <Slider/>
      <div>
        <p className="snapshot__title">Indicadores financieros</p>
        <div className="snapshot__options-container">
          <div>
            <p>1. Primas emitidas</p>
            <p>2. Primas cedidas</p>
            <p>3. Gastos administrativos</p>
            <p>4. Siniestralidad a retencion</p>
          </div>
          <div style={{ paddingLeft: 10 }}>
            <p>5 .Resultado técnico</p>
            <p>6 .Utilidad operativa</p>
            <p>7. Utilidad final</p>
          </div>
        </div>
      </div>
      <div className="snapshot__pld-title">
        <h4 className="snapshot__title">Estatus PLD</h4>
        <h4 className="snapshot__title">Temas de Administración</h4>
        <h2>Actualizado</h2>
        <ol>
          <li>Envíos de estados de cuenta</li>
          <li>Liquidación de saldos</li>
        </ol>
      </div>
    </div>
  );
}

