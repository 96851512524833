import React from 'react';
import { TextareaAutosize } from '@material-ui/core';
import { LabelCard } from '../../StylesComponents/common-styled';
import ButtonNavigation from '../../Viaticos/ButtonNavigation';


const LastStepWithStatus = ({ onClick, status }) => {
    return (
        <React.Fragment>
            <div
                style={{
                    paddingBottom: 20,
                    width: '100%',
                    display: 'flex',
                    alignItems: 'flex-start',
                    flexDirection: 'column',
                }}
            >
                <TextareaAutosize
                    rowsMin={5}
                    style={{ width: '90%' }}
                    id="text-mensaje"
                    name="area"
                    placeholder="Comentarios"
                />
                <LabelCard
                    style={{
                        marginTop: 20,
                        backgroundColor: '#01539C',
                        color: '#FFF',
                        paddingLeft: 10,
                    }}
                >
                    {status}
                </LabelCard>
            </div>
            <ButtonNavigation text="Guardar" onClick={() => onClick()}/>
        </React.Fragment>
    );
}

export default LastStepWithStatus;