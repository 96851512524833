
import { useEffect, useState } from 'react';


export default () => {
    const [dataTable, setDataTable] = useState(null);
    const [p, setP] = useState(null)
    const [statusFilter, setStatusFilter] = useState('')

    const filterByString = (status) => {
        dataTable.sort((a, b) => {
            let fa = a.Title.toLowerCase(),
                fb = b.Title.toLowerCase();
            return fa < fb ? -1 : fa > fb ? 1 : 0;
        });
        setP(dataTable)
        console.log("FILTER", statusFilter)
    }

    const filterByPlace = (status) => {
        dataTable.sort((a, b) => {
            let fa = a.Lugar.toLowerCase(),
                fb = b.Lugar.toLowerCase();
            return fa < fb ? -1 : fa > fb ? 1 : 0;
        });

        setP(dataTable)

        console.log("FILTER", dataTable, statusFilter)
    }

    const filterNumber = (status) => {
        dataTable.sort((a, b) => {
            return a.cREU_Id - b.cREU_Id;
        });
        // setStatusFilter({ ...statusFilter, idMeet: status })
        setP(dataTable)
    }

    const filterByDate = (status) => {
        dataTable.sort((a, b) => {
            let da = new Date(a.Fecha),
                db = new Date(b.Fecha);
            return da - db;
        });
        // setStatusFilter({ ...statusFilter, date: status })
        setP(dataTable)
    }

    const filterByHour = (status) => {
        dataTable.sort((a, b) => {
            return Number(a.Hora.split(':').join("")) - Number(b.Hora.split(':').join(""));
        });
        // setStatusFilter({ ...statusFilter, hour: status })

        setP(dataTable)
    }

    useEffect(() => {
        setDataTable(p)
    }, [p])



    const actions = (action, filterActive) => {
        setDataTable(null);

        if (filterActive === statusFilter) {
            filterActive = "";
            setStatusFilter('')
            filterNumber()
            return;
        }
        setStatusFilter(filterActive)


        switch (action) {
            case 'filterByTitle':
                filterByString();
                break;
            case 'filterByPlace':
                filterByPlace()
                break;
            case 'filterByNumber':
                filterNumber()
                break;
            case 'filterByDate':
                filterByDate();
                break;
            case 'filterByHour':
                filterByHour();
                break;
            default:
                break;
        }
    }


    return { dataTable, setDataTable, statusFilter, actions }
}