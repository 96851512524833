import React, { useState, Fragment } from 'react';
import { navigation } from '../funciones-auxiliares/programar-reunion';
import Homebar from './Homebar';
import {
  AcordeonContainer,
  DivNavigationButton,
  Page,
  PageContainer,
  Subtitle,
  Title,
} from './StylesComponents/common-styled';
import ButtonNavigation from './Viaticos/ButtonNavigation';
import { BottomNavigation, BottomNavigationAction } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import './../assets/styles/css/master.css';
import {
  Business,
  Home,
  LibraryBooks,
  PermIdentity,
  PlaylistAddCheck,
  Public,
} from '@material-ui/icons';
import Footer from './Viaticos/Footer';
import RegistroVisita from './Acuerdos/RegistroVisita';
import Nota from './Acuerdos/Nota';
import NewReporte from './Acuerdos/NewReporte';
import NewReuProgramada from './Acuerdos/NewReuProgramada';
import Cedente from './Acuerdos/Cedente';
import Entorno from './Acuerdos/Entorno';
import NewReuCasual from './Acuerdos/NewReuCasual';
import Seguimiento from './Acuerdos/Seguimiento';
import Relacion from './Acuerdos/Relacion';
import { useHistory } from 'react-router';
import RelacionV2 from './Acuerdos/RelacionV2';
import SeguimientoRelPatria from './Acuerdos/SeguimientoRelPatria';
import Report from './Acuerdos/Report';
const useStyles = makeStyles({
  root: {
    width: '100%',
    height: 'auto',
  },
});

export const Acuerdos = ({ session }) => {
  const { obtenerUsuario } = session;
  const history = useHistory();
  const [titleSeguimiento, setTitleSeguimiento] = useState('Seguimiento Entorno')
  console.log('Usrioa?======>', obtenerUsuario);

  const classes = useStyles();
  const [value, setValue] = useState('');
  const [stateButton, setStateButton] = useState({
    first: true,
    second: false,
    third: false,
    four: false,
    five: false,
    six: false,
    sevent: false,
    eight: false,
    nine: false,
    ten: false,
    showSegRelPatria: false,
    showReport: false
  });
  const [lastPage, setLastPage] = useState('first');

  const handleChangue = (event, newValue) => {
    setTitleSeguimiento('Seguimiento Entorno')
    setValue(newValue);
  };

  const handleClickReuniones = () => {
    history.push('/secciones/reuniones');
  };
  return (
    <Fragment>
      <Page>
        <Homebar navigation={navigation} />
        <PageContainer>
          <Title>Reporte de Visitas</Title>
          <Subtitle>Consulta y registra los reportes de tus visitas.</Subtitle>
        </PageContainer>
        <AcordeonContainer
          flexDirection="row"
          justifyContent="space-around"
          id="viaticos-solicitud"
        >
          <DivNavigationButton
            width="20%"
            alignSelf="end"
            id="button-navigation"
          >
            <ButtonNavigation
              text="Registrar Visita"
              backgroundColor={stateButton.first ? '#3898EC' : null}
              border={stateButton.first ? '#3898EC' : null}
              color={stateButton.first ? '#FFF' : null}
              onClick={() =>
                setStateButton({ first: true, second: false, third: false })
              }
            />
            <ButtonNavigation
              text="Consultar Reporte"
              backgroundColor={stateButton.second ? '#3898EC' : null}
              border={stateButton.second ? '#3898EC' : null}
              color={stateButton.second ? '#FFF' : null}
              onClick={() =>
                setStateButton({ first: false, second: true, third: false })
              }
            />
            <ButtonNavigation
              text="Nota Rápida"
              backgroundColor={stateButton.third ? '#3898EC' : null}
              border={stateButton.third ? '#3898EC' : null}
              color={stateButton.third ? '#FFF' : null}
              onClick={() => {
                setStateButton({ first: false, second: false, third: true });
              }}
            />
          </DivNavigationButton>
          <div className="content-acuerdos">
            <div style={{ display: 'flex' }}>
              <BottomNavigation
                value={value}
                onChange={handleChangue}
                className={classes.root}
                showLabels
                id="button-navigate-acuerdo"
              >
                <BottomNavigationAction
                  label="Inicio"
                  value="inicio"
                  onClick={() => {
                    setStateButton({
                      first: true,
                      second: false,
                      third: false,
                      four: false,
                      five: false,
                      six: false,
                      sevent: false,
                      eight: false,
                    });
                  }}
                  icon={<Home />}
                />
                <BottomNavigationAction
                  label="Cedente"
                  value="cedente"
                  onClick={() => {
                    setStateButton({
                      first: true,
                      second: false,
                      third: false,
                      four: true,
                      five: false,
                      six: false,
                      sevent: false,
                      eight: false,
                    });
                  }}
                  icon={<PermIdentity />}
                />
                <BottomNavigationAction
                  label="Entorno"
                  value="entorno"
                  onClick={() => {
                    setStateButton({
                      first: true,
                      second: false,
                      third: false,
                      four: false,
                      five: false,
                      six: false,
                      sevent: true,
                      eight: false,
                    });
                  }}
                  icon={<Public />}
                />
                <BottomNavigationAction
                  label="Relación"
                  value="relacion"
                  icon={<Business />}
                  onClick={() =>
                    setStateButton({
                      first: false,
                      second: false,
                      third: false,
                      four: false,
                      five: false,
                      six: false,
                      sevent: false,
                      eight: false,
                      nine: false,
                      ten: true,
                    })
                  }
                />
                <BottomNavigationAction
                  label="Seguimiento"
                  value="seguimiento"
                  icon={<PlaylistAddCheck />}
                  onClick={() => {
                    setStateButton({
                      first: true,
                      second: false,
                      third: false,
                      four: false,
                      five: false,
                      six: false,
                      sevent: false,
                      eight: false,
                      nine: true,
                    });
                  }}
                />
                <BottomNavigationAction
                  label="Reporte"
                  value="reporte"
                  icon={<LibraryBooks />}
                  onClick={() => {
                    setStateButton({
                      first: false,
                      second: false,
                      third: false,
                      four: false,
                      five: false,
                      six: false,
                      sevent: false,
                      eight: false,
                      nine: false,
                      showReport: true
                    });
                  }}
                />
              </BottomNavigation>
            </div>
            {stateButton.first &&
              obtenerUsuario.cEMP_Nombre !== null &&
              !stateButton.four &&
              !stateButton.five &&
              !stateButton.six &&
              !stateButton.sevent &&
              !stateButton.eight &&
              !stateButton.nine &&
              !stateButton.ten ? (
              <RegistroVisita
                name={obtenerUsuario.cEMP_Nombre}
                onClick1={() => {
                  setStateButton({
                    first: true,
                    second: false,
                    third: false,
                    four: true,
                    five: false,
                    six: false,
                    sevent: false,
                    eight: false,
                  });
                  setValue('inicio');
                  setLastPage('first');
                }}
                onClickReuniones={handleClickReuniones}
              />
            ) : null}
            {stateButton.third && <Nota />}
            {stateButton.four && (
              <NewReporte
                onClick1={() => {
                  setStateButton({
                    first: true,
                    second: false,
                    third: false,
                    four: false,
                    five: true,
                    six: false,
                    sevent: false,
                    eight: false,
                  });
                  setValue('cedente');
                  setLastPage('four');
                }}
                onClick2={() => {
                  setStateButton({
                    first: true,
                    second: false,
                    third: false,
                    four: false,
                    five: false,
                    six: false,
                    sevent: false,
                    eight: true,
                  });
                  setLastPage('four');
                }}
                onGoBack={() => {
                  setStateButton({
                    first: true,
                    eight: false,
                    five: false,
                  });
                }}
              />
            )}
            {stateButton.five ? (
              <NewReuProgramada
                cEMP_Id={obtenerUsuario.cEMP_Id}
                onGoBack={() => {
                  setStateButton({ first: true, four: true, five: false });
                }}
                onClick={() => {
                  setStateButton({
                    first: true,
                    second: false,
                    third: false,
                    four: false,
                    five: false,
                    six: true,
                    sevent: false,
                    eight: false,
                  });
                  setLastPage('five');
                }}
              />
            ) : null}
            {stateButton.six ? (
              <Cedente
                onClick={() => {
                  setStateButton({
                    first: true,
                    second: false,
                    third: false,
                    four: true,
                    five: false,
                    six: false,
                    sevent: false,
                    eight: false,
                  });
                  setLastPage('six');
                }}
                onclick1={() => {
                  setStateButton({
                    first: true,
                    second: false,
                    third: false,
                    five: false,
                    six: false,
                    sevent: true,
                    eight: false,
                  });
                  setValue('entorno');
                  setLastPage('six');
                }}
                onGoBack={() => {
                  if (lastPage === 'five') {
                    setStateButton({
                      first: true,
                      six: false,
                      five: true,
                    });
                  } else {
                    setStateButton({
                      first: true,
                      six: false,
                    });
                  }
                }}
              />
            ) : null}
            {stateButton.sevent ? (
              <Entorno
                onClick1={() =>
                  setStateButton({
                    first: true,
                    second: false,
                    third: false,
                    four: false,
                    five: false,
                    six: false,
                    sevent: false,
                    eight: false,
                    nine: true,
                  })
                }
                onGoBack={() => {
                  if (lastPage === 'six') {
                    setStateButton({
                      first: true,
                      six: true,
                      sevent: false,
                    });
                  } else {
                    setStateButton({
                      first: true,
                      sevent: false,
                    });
                  }
                }}
              />
            ) : null}
            {stateButton.eight ? (
              <NewReuCasual
                onClick1={() =>
                  setStateButton({
                    first: true,
                    second: false,
                    third: false,
                    four: false,
                    five: false,
                    six: false,
                    sevent: true,
                    eight: false,
                    nine: false,
                  })
                }
                onGoBack={() => {
                  setStateButton({ first: true, eight: false, four: true });
                }}
              />
            ) : null}
            {stateButton.nine ? (
              <Seguimiento
                titleSeguimiento={titleSeguimiento}
                onClick={() =>
                  setStateButton({
                    first: true,
                    second: false,
                    third: false,
                    four: false,
                    five: false,
                    six: false,
                    sevent: false,
                    eight: false,
                    ten: true,
                  })
                }
                onClickReturnEntorno={() => {
                  setStateButton({
                    first: true,
                    second: false,
                    third: false,
                    four: false,
                    five: false,
                    six: false,
                    sevent: true,
                    eight: false,
                    ten: false,
                  });
                  setValue('entorno');
                }}
                onClickReturnRelacionPatria={() => {
                  setStateButton({
                    first: true,
                    second: false,
                    third: false,
                    four: false,
                    five: false,
                    six: false,
                    sevent: false,
                    eight: false,
                    ten: true,
                  });
                  setValue('relacion');
                }}
                onGoBack={() => {
                  if (lastPage === 'six') {
                    setStateButton({
                      first: true,
                      six: true,
                      nine: false,
                    });
                  } else {
                    setStateButton({
                      first: true,
                      nine: false,
                    });
                  }
                }}
              />
            ) : null}
            {stateButton.ten && (
              <RelacionV2
                onClick={() =>
                  setStateButton({
                    first: false,
                    second: false,
                    third: false,
                    four: false,
                    five: false,
                    six: false,
                    sevent: false,
                    eight: false,
                    nine: false,
                    ten: false,
                    showSegRelPatria: true
                  })
                }
              />
              // <Relacion 
              //   onClick={() =>
              //     setStateButton({
              //       first: true,
              //       second: false,
              //       third: false,
              //       four: false,
              //       five: false,
              //       six: false,
              //       sevent: false,
              //       eight: false,
              //       nine: true,
              //       ten: false,
              //     })
              //   }
              // />
            )}
            {stateButton.showSegRelPatria && (
              <SeguimientoRelPatria

              />
            )}
            {stateButton.showReport && (
              <Report
                onClick={() => {
                  setValue('seguimiento')
                  setTitleSeguimiento('Seguimiento')
                  setStateButton({
                    first: true,
                    second: false,
                    third: false,
                    four: false,
                    five: false,
                    six: false,
                    sevent: false,
                    eight: false,
                    nine: true,
                  })
                }}
              />
            )}
          </div>
        </AcordeonContainer>
      </Page>
      <Footer />
    </Fragment>
  );
};
