import React,{useState,Fragment} from 'react';
import {ApolloConsumer} from 'react-apollo';
import {withRouter} from 'react-router-dom';
import {ButtonStyled} from './StylesComponents/common-styled';
import DialogComponent from './Dialog';

const cerrarSesionUsuario=(cliente,history)=>{
    localStorage.removeItem('token','');
    sessionStorage.clear()
    cliente.resetStore();
    history.push('/');
}

const CerrarSesion=({className,history})=>{
    const [open,setOpen]=useState(false);
    const handleClickOpen=()=>{
        setOpen(true);
    }
    const handleClose=()=>{
        setOpen(false);
    }
    return(
    <ApolloConsumer>
        {cliente=>{
            return(
                <Fragment>
                    <ButtonStyled width="100px" height="35px" backgroundColor="#3898EC" color="#fff" borderRadius="3px" className={className} onClick={handleClickOpen}>
                        Cerrar sesión
                    </ButtonStyled>
                    <DialogComponent 
                        open={open} 
                        handleClose={handleClose} 
                        title='Cerrar sesión'
                        text='¿Estas seguro de querer terminar tu sesión?'
                        textButtonA='Cancelar'
                        textButtonB='Cerrar sesión'
                        handleClick={()=>cerrarSesionUsuario(cliente,history)}/>
                </Fragment>

            );
        }}
    </ApolloConsumer>
    )}
export default withRouter(CerrarSesion);