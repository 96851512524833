import React from 'react';

const CardUpDown=({children,title,text,text2})=>{
  return(
    <div style={{width:'50%',height:'max-content',display:'flex',justifyContent:'center',flexDirection:'column',alignItems:'flex-start',marginBottom:'20px',marginTop:"20px",textAlign:'start'}}>
      <h3>{title}</h3>
      <p>{text}</p>
      <div style={{width:'100%',display:'flex',alignItems:'center',color:'#3898EC'}}>
        {children}
        <p>{text2}</p>
      </div>
    </div>
  )
}

export default CardUpDown;