import React, { useContext, useEffect, useState } from 'react';
import { MyContext } from '../Context';
import ButtonNavigation from '../Viaticos/ButtonNavigation';
import CedenteCancelar from './CedenteCancelar';
import { useMutation } from 'react-apollo';
import { DINAMIC_GET } from '../../mutation/mutation';
import { ArrowBackIos } from '@material-ui/icons';

export default function Cedente({ onClick, onclick1, onGoBack }) {
  const context = useContext(MyContext);
  const [cancelacion, setCancelacion] = useState(false);
  const [showComments, setShowComments] = useState(false);
  const [statusReunion, setStatusReunion] = useState([]);

  const [getInfo] = useMutation(DINAMIC_GET, {
    onCompleted({ dynamicGet }) {
      setStatusReunion(JSON.parse(dynamicGet.response));
    },
  });

  const setStatus = (CVIS_Id, index) => {
    context.setSaveAcuerdos({ CVIS_Id: CVIS_Id });
    if (index === 0) {
      onclick1();
    } else if (index === 1) {
      setShowComments(true);
      setCancelacion(true);
    } else if (index === 3) {
      setCancelacion(true);
    }
  };

  useEffect(() => {
    getInfo({
      variables: {
        input: {
          table: 'CVIS_VisitaAcuerdo',
          columns: ['CVIS_Id', 'CVIS_Descripcion'],
        },
      },
    });
  }, []);

  return (
    <div style={{ width: '100%' }} className="registro-container">
      <div onClick={() => onGoBack()} style={{ cursor: 'pointer' }}>
        <ArrowBackIos style={{ color: '#01539C' }} />
      </div>
      <div style={{ display: 'flex' }}>
        <h4 style={{ color: '#01539C' }}>Nuevo Reporte de Visita </h4>
        <h4 style={{ color: '#01539C' }}>` &gt; Reunión Programada`</h4>
        <h4> &gt; {context.state.acuerdosProps.cREU_Titulo} </h4>
      </div>
      <div
        style={{ backgroundColor: '#E5E5E5', width: '100%', marginTop: '20px' }}
      >
        <h2>{context.state.acuerdosProps.cREU_Titulo} </h2>
        <h4>{`[Detalles,${context.state.acuerdosProps.cREU_Lugar},personas]`}</h4>
      </div>
      {!cancelacion ? (
        <div
          style={{
            width: '100%',
            textAlign: 'center',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <h2>¿Tu visita se llevó a cabo conforme a la agenda?</h2>
          {statusReunion.map((status, index) => (
            <ButtonNavigation
              width="70%"
              text={status.CVIS_Descripcion}
              style={{ cursor: 'pointer' }}
              onClick={() => setStatus(status.CVIS_Id, index)}
            />
          ))}
        </div>
      ) : showComments ? (
        <CedenteCancelar onClick={onClick} />
      ) : null}
    </div>
  );
}
