import React from 'react';

import '../components.css';
import { ResponsiveBar } from '@nivo/bar';

export default function SecondColumnSnapshot() {
  const data = [
    {
      id: 'Estadistica',
      Daños: 80,
      DañosColor: '#01539c',
      Fianzas: 90,
      FianzasColor: '#f26224',
      Vida: 10,
      VidaColor: '#dbc756',
    },
  ];

  const data2 = [
    {
      year: 2010,
      Daños: 80,
      Fianzas: 90,
      Vida: 20,
      DañosColor: '#01539c',
      FianzasColor: '#f26224',
      VidaColor: '#dbc756',
    },
    {
      year: 2010,
      Daños: 80,
      Fianzas: 90,
      Vida: 20,
      DañosColor: '#01539c',
      FianzasColor: '#f26224',
      VidaColor: '#dbc756',
    },
    {
      year: 2012,
      Daños: 70,
      Fianzas: 90,
      Vida: 20,
      DañosColor: '#01539c',
      FianzasColor: '#f26224',
      VidaColor: '#dbc756',
    },
    {
      year: 2014,
      Daños: 70,
      Fianzas: 40,
      Vida: 100,
      DañosColor: '#01539c',
      FianzasColor: '#f26224',
      VidaColor: '#dbc756',
    },
    {
      year: 2016,
      Daños: 70,
      Fianzas: 20,
      Vida: 20,
      DañosColor: '#01539c',
      FianzasColor: '#f26224',
      VidaColor: '#dbc756',
    },
  ];

  return (
    <div className="snapshot__content__column">
      <div className="snapshot__crm-title-container">
        <h2 style={{ color: '#01539c' }}>Plataforma CRM</h2>
        <div>
          <p>*Cifras en moneda nacional</p>
        </div>
      </div>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div className="snapshot__center" style={{ padding: '0 10px' }}>
          <p style={{ fontSize: 10 }}>$34,568,519,422</p>
          <p style={{ fontSize: 10 }}>Prima Tomada</p>
        </div>
        <div className="snapshot__center" style={{ padding: '0 10px' }}>
          <p style={{ fontSize: 10 }}>$6,310,796,115</p>
          <p style={{ fontSize: 10 }}>Prima cedida</p>
        </div>
        <div className="snapshot__center" style={{ padding: '0 10px' }}>
          <p style={{ fontSize: 10 }}>$6,310,796,115</p>
          <p style={{ fontSize: 10 }}>Siniestros tomados</p>
        </div>
        <div className="snapshot__center" style={{ padding: '0 10px' }}>
          <p style={{ fontSize: 10 }}>$6,310,796,115</p>
          <p style={{ fontSize: 10 }}>Siniestros cedidos</p>
        </div>
        <div className="snapshot__center" style={{ padding: '0 10px' }}>
          <p style={{ fontSize: 10 }}>$6,310,796,115</p>
          <p style={{ fontSize: 10 }}>Costo tomado</p>
        </div>
        <div className="snapshot__center" style={{ padding: '0 10px' }}>
          <p style={{ fontSize: 10 }}>$6,310,796,115</p>
          <p style={{ fontSize: 10 }}>Costo cedido</p>
        </div>
        <div className="snapshot__center" style={{ padding: '0 10px' }}>
          <p style={{ fontSize: 10 }}>$6,310,796,115</p>
          <p style={{ fontSize: 10 }}>Resultado</p>
        </div>
      </div>
      <div style={{ height: 160 }}>
        <ResponsiveBar
          data={data}
          keys={['Daños', 'Fianzas', 'Vida']}
          margin={{ top: 20, right: 130, bottom: 30, left: 20 }}
          padding={0.2}
          layout="horizontal"
          borderColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
          colors={({ id, data }) => data[`${id}Color`]}
          axisTop={null}
          axisRight={null}
          axisBottom={null}
          axisLeft={null}
          labelSkipWidth={12}
          labelSkipHeight={12}
          labelTextColor="white"
          legends={[
            {
              dataFrom: 'keys',
              anchor: 'right',
              direction: 'column',
              justify: false,
              translateX: 134,
              translateY: 0,
              itemsSpacing: 2,
              itemWidth: 100,
              itemHeight: 20,
              itemDirection: 'left-to-right',
              itemOpacity: 0.85,
              symbolSize: 20,
              effects: [
                {
                  on: 'hover',
                  style: {
                    itemOpacity: 1,
                  },
                },
              ],
            },
          ]}
          animate={true}
          motionStiffness={90}
          motionDamping={15}
        />
      </div>
      <div>
        <p className="snapshot__title">Estadística</p>
        <div style={{ height: 160 }}>
          <ResponsiveBar
            data={data2}
            keys={['Daños', 'Fianzas', 'Vida']}
            margin={{ top: 20, right: 130, bottom: 30, left: 30 }}
            padding={0.4}
            borderColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
            colors={({ id, data }) => data[`${id}Color`]}
            axisTop={null}
            groupMode="grouped"
            labelSkipWidth={12}
            labelSkipHeight={12}
            indexBy="year"
            labelTextColor="white"
            legends={[
              {
                dataFrom: 'keys',
                anchor: 'right',
                direction: 'column',
                justify: false,
                translateX: 134,
                translateY: 0,
                itemsSpacing: 2,
                itemWidth: 100,
                itemHeight: 20,
                itemDirection: 'left-to-right',
                itemOpacity: 0.85,
                symbolSize: 20,
                effects: [
                  {
                    on: 'hover',
                    style: {
                      itemOpacity: 1,
                    },
                  },
                ],
              },
            ]}
            animate={true}
            motionStiffness={90}
            motionDamping={15}
          />
        </div>
      </div>
      <div className="snapshot__negocios-vigentes-container">
        <p className="snapshot__title">Negocios vigentes</p>
        <p className="snapshot__title">Detalle de negocios vigentes</p>
        <div className="snapshot__negocios-vigentes-content">
          <div className="snapshot__center">
            <h4>566</h4>
            <p>Proporcionales</p>
          </div>
          <div className="snapshot__center">
            <h4>623</h4>
            <p>No proporcionales</p>
          </div>
          <div className="snapshot__center">
            <h4>1,341</h4>
            <p>Facultativo</p>
          </div>
        </div>
        <div>
          <table
            style={{
              overflowY: 'scroll',
              overflowX: 'scroll',
              border: 'solid 1px',
            }}
          >
            <tr>
              <th
                style={{
                  border: 'solid 1px',
                  color: 'white',
                  backgroundColor: '#01539c',
                }}
              >
                Referencia
              </th>
              <th
                style={{
                  border: 'solid 1px',
                  color: 'white',
                  backgroundColor: '#01539c',
                }}
              >
                Operación
              </th>
              <th
                style={{
                  border: 'solid 1px',
                  color: 'white',
                  backgroundColor: '#01539c',
                }}
              >
                Tipo de Reaseguro
              </th>
              <th
                style={{
                  border: 'solid 1px',
                  color: 'white',
                  backgroundColor: '#01539c',
                }}
              >
                Nombre
              </th>
            </tr>
            <tr>
              <td>356-784-1-2020</td>
              <td>Daños</td>
              <td>No proporcional</td>
              <td>Agriculture Stop</td>
            </tr>
          </table>
        </div>
      </div>
      <div className="snapshot__negocios-vigentes-container">
        <p className="snapshot__title">
          ISR Retenido en el extranjero V.S. ISR Recuperado
        </p>
        <p className="snapshot__title">Directorio</p>
      </div>
    </div>
  );
}
