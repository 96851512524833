import React,{useEffect, useState, Fragment} from 'react';
import CardProgress from './CardProgress';
import { GET_TRAVEL_EXPENSES_BY_IDUSER, GET_TRAVEL_EXPENSES_ALL } from '../../query/query';
import DialogViat from './DialogViat';
import { useLazyQuery } from 'react-apollo';

const ProgressSolicitud=({cEMP_Id,handleComponent,type,status})=>{
  const [viaticos,setViaticos]=useState([]);
  const [dialog,setDialog]=useState(false);

  const handleClose=()=>{
    setDialog(false);
  }
  const [getTravelExpenses]=useLazyQuery(
    GET_TRAVEL_EXPENSES_BY_IDUSER,{
      onCompleted({getTravelExpensesbyUser}){
        const aux=JSON.parse(getTravelExpensesbyUser);
        setViaticos(aux);
        console.log('Progress',aux);
      }
    }
  );

  const [TravelExpensesAll]=useLazyQuery(
    GET_TRAVEL_EXPENSES_ALL,{
      onCompleted({getTravelExpensesAll}){
        const aux=JSON.parse(getTravelExpensesAll);
        setViaticos(aux);
        console.log('Progress',aux);
      }
    }
  )

  useEffect(()=>{
    if(type===0){
      getTravelExpenses({variables:{cEMP_Id:cEMP_Id.cEMP_Id,cREU_FechaInicio:new Date().toISOString().slice(0,10)},pollInterval:1000});
    }
    else if(type===1){
      console.log(status)
      TravelExpensesAll({variables:{cREU_FechaInicio:new Date().toISOString().slice(0,10),cVIT_Status_VIAT:status},pollInterval:1000});
    }
    else if(type===2){
      getTravelExpenses({variables:{cEMP_Id:cEMP_Id.cEMP_Id,cREU_FechaInicio:new Date().toISOString().slice(0,10),cVIT_Status_VIAT:4},pollInterval:1000});
    }
    
  },[]);

  return(
    <Fragment>
      <div style={{width:'70%',minHeight:'65vh',display:'flex',flexWrap:'wrap',justifyContent:'space-around'}}>
      <div style={{textAlign:'center',width:'100%',marginBottom:'20px'}}>
        <h4>Solicitudes de viáticos</h4>
      </div>
      {
        viaticos&&
        viaticos.length===0?
        <Fragment>
          <CardProgress/>
          <CardProgress/>
        </Fragment>
        
        :
        viaticos.map(data=>{
          let inicio=data.cREU_FechaInicio;
          let fin=data.cREU_FechaFin;
          return(
            <CardProgress idViaticos={data.cVIT_Id} nombre={cEMP_Id.cEMP_Nombre} idReunion={data.cREU_Id} fechas={`${inicio} - ${fin}`} pais={data.cVIA_Destino} status={data.cVIT_Status_VIAT} progress={data.cVIT_Status_COMP}  handleClick={()=>handleComponent()}/>
          )
        }
          
        )
      }
      {
        
      }
      
      <DialogViat open={dialog} handleClose={handleClose}/>
    </div>
    </Fragment>
    
    
  )
}

export default ProgressSolicitud;