import React, { useState } from 'react';
import {TextField, makeStyles} from '@material-ui/core';
import {Autocomplete} from '@material-ui/lab'
const useStyles = makeStyles(theme=>({
    option:{
        color:'rgb(0, 0, 0)',
        backgroundColor:"#fff"
    }
}))
const SearchInvitadosSirec=({inputValue,data,getIdCargos,tipo,disabledSearch})=>{
    const classes=useStyles()
    const [value,setValue]=useState(inputValue);

    return(
        <Autocomplete
            freeSolo
            disableClearable
            inputValue={value}
            // onChange={(e,Data)=>{
            //         getIdCargos(Data.cRET_Id || Data.cCIA_Id || Data.cCOR_Id,tipo,Data)
            //         console.log('Datas',Data)
            //         setValue(Data.cRET_RazSocial);
            //     }         
            // }
            onChange={(e, Data) => {
                if (Data !== null) {
                    getIdCargos(Data.cRET_Id || Data.cCIA_Id || Data.cCOR_Id, tipo, Data)
                    console.log('Datas', Data)
                    setValue(Data.cRET_RazSocial);
                }
                else {
                    setValue('');
                }
            }
            }
            classes={{
                option:classes.option

            }}
            disabled={disabledSearch}
            options={data}
            getOptionLabel={(option)=>option.cRET_RazSocial || option.cCIA_RazSocial || option.cCOR_RazSocial || ""}
            renderInput={(params) => (
            <TextField
                {...params}
                onChange={(e)=>setValue(e.target.value)}
                margin="normal"
                variant="outlined"
                InputProps={{ ...params.InputProps, type: 'search'}}
                placeholder="oficina"
                id="search-invitados"
                className={`${value!="" && value!=undefined?'inputActive':''}`}
            />
            )}
        />
    )
}
export default SearchInvitadosSirec;