import React, { useEffect, useState ,useContext , Fragment } from 'react';
import { PageContainer, Subtitle , ContainerTwoInputs } from '../StylesComponents/common-styled';

import Card from './Card';
import calendario from '../../assets/images/icons/calendario.svg';
import user from '../../assets/images/icons/usuario.svg';
import descargar from '../../assets/images/icons/descargar.svg';
import ButtonsInformacion from './ButtonsInformacion';
import FormTravels from './FormTravels';
import FormAlojamiento from './FormAlojamiento';
import FormMateriales from './FormMateriales';
import { TextareaAutosize} from '@material-ui/core';
import ButtonNavigation from './ButtonNavigation';
import CardPendientes from './CardPendientes';
import {getcTOBS_Id,getRol} from '../../funciones-auxiliares/viaticos';
import { useLazyQuery,useMutation } from 'react-apollo';
import { GET_EVENTOSBYVIATICOS,GET_EVENTO_BY_CREU_ID } from '../../query/query';
import {DYNAMIC_INSERT,DYNAMIC_UPDATE} from '../../mutation/mutation';
import { MyContext } from '../Context';
import ToggleButtons from './ToggleButons';



const usePendientes=({cEMP_Id,handleComponent,title})=>{
  const [reuniones,setReuniones]=useState([]);
  const [data,setData]=useState({titulo:'',texto1:'',texto2:'',texto3:''});
  const [data2,setData2]=useState({titulo:'',texto1:'',texto2:'',texto3:''});
  const [value,setValue]=useState('Ida y vuelta');
  const [tramos,setTramos]=useState(
    [
      {
        titulo:'Tramo 1'
      },
    ]
  )
  const [viaje,setViaje]=useState(
    [
      {
        origen:'',
        destino:'',
        aerolinea:'',
        vuelo:'',
        cotizacion:'',
        fecha:'',
        horario:'',
        precio:''
      },
      {
        origen:'',
        destino:'',
        aerolinea:'',
        vuelo:'',
        cotizacion:'',
        fecha:'',
        horario:'',
        precio:''
      },
    ]);
    const [alojamiento,setAlojamiento]=useState(
      [
        {
          hotel:'',
          urlCotizacion:'',
          fecha:'',
          precio:'',
        },  
      ]
    )

  const [getEventsByViaticos]=useLazyQuery(GET_EVENTOSBYVIATICOS,{
    onCompleted({getReunionbyViaticos}){
      setReuniones(getReunionbyViaticos);
    }
  });

  const [getEventBycREU]=useLazyQuery(GET_EVENTO_BY_CREU_ID,{
    onCompleted({ getReunionBycREUID }){
      setData({
        titulo:getReunionBycREUID.cREU_Titulo,
        texto1:`${getReunionBycREUID.cREU_FechaInicio}-${getReunionBycREUID.cREU_FechaFin}`,
        texto2:`${getReunionBycREUID.Pais}, ${getReunionBycREUID.Estado}`,
        texto3:getReunionBycREUID.cREU_TipoReunion
      })
      setData2({
        titulo:getReunionBycREUID.Nombre_Empleado,
        texto1:`No. empleado ${getReunionBycREUID.cEMP_Id}`,
        texto2:'Suscripción',
        texto3:'Patria RE'
      })
    }
  })
  const context=useContext(MyContext);


  const [insertProducts] = useMutation(DYNAMIC_INSERT,{
    onCompleted({dynamicInsert}){
      if(dynamicInsert.statusCode===200){
        // handleComponent();
      }
    },
    onError({ graphQLErrors, networkError }) {

      graphQLErrors.map(({ message, locations, path }) =>
          console.log(
              `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
          ),
      );

      if (networkError) console.log(`[Network error]: ${networkError}`);
    }
  })

  const [updateStatus] = useMutation(DYNAMIC_UPDATE,{
    onCompleted({dynamicUpdate}){

    },
    onError({ graphQLErrors, networkError }) {

      graphQLErrors.map(({ message, locations, path }) =>
          console.log(
              `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
          ),
      );

      if (networkError) console.log(`[Network error]: ${networkError}`);
    }
  })
  const getInputUpdate = (table, columns, conditions) => {
    return {
        table,
        columns,
        conditions,
    };
}
  const saveObservaciones=async()=>{
    let input
    let observaciones=sessionStorage.getItem('obsevaciones')
    if(observaciones!==null){
      input={
        table:'cOBS_ObservacionesViaticos',
        columns:['cVIT_Id','cEMP_Id','cOBS_Descripcion','cTOBS_Id'],
        detailValues:[{values:[''+context.state.viaticosProps.cVIT_Id,''+cEMP_Id.cEMP_Id,sessionStorage.getItem('obsevaciones'),''+getcTOBS_Id(cEMP_Id.profile)]}]
      }
      await insertProducts({variables:{input}})
    }
    let columns=[
      {
        setkey:'cVIT_Status_VIAT',
        setval:'2'
      }
    ]
    let conditions=[
      {
        valone:"cREU_Id",
        valtwo:''+context.state.viaticosProps.cREU_Id,
        condition:"="
      },
      {
        valone:"cVIT_Id",
        valtwo:''+context.state.viaticosProps.cVIT_Id,
        condition:"=",
        logic:"AND"
      }
    ]
    input=getInputUpdate('cVIT_Viaticos',columns,conditions)
    await updateStatus({variables:{input}}).then(({data})=>{
        if(data.dynamicUpdate.statusCode===200){
          handleComponent()
        }
    })
  }


  const getViaticos=()=>{
    switch(context.state.viaticosProps.cTIP_Id){
      case 1:setValue('Ida y vuelta')
            break;
      case 2:setValue('Solo ida')
            break;
      case 3:setValue('Múltiples destinos')
            break;
      default:console.log('Nada');
    }
    const viajes=
      context.state.viaticosProps.viajes.map(viaje=>{
        return({
          ...viaje,
          origen:viaje.cVIA_Origen,
          destino:viaje.cVIA_Destino,
          aerolinea:viaje.cVIA_Areolinea,
          vuelo:viaje.cVIA_NoVuelo,
          cotizacion:viaje.cVIA_URLVueloCot,
          fecha:viaje.cVIA_FechaVuelo.slice(0,10),
          horario:viaje.cVIA_HorarioVuelo.slice(11,16),
          precio:viaje.cVIA_PrecioVuelo
        })
      })
      const alojamientos=context.state.viaticosProps.alojamientos.map(alojamiento=>{
        return(
          {
            ...alojamiento,
            hotel:alojamiento.cALO_Hotel,
            urlCotizacion:alojamiento.cALO_URLAlojamientoCot,
            fecha:alojamiento.cALO_FechaAlojamiento.slice(0,10),
            precio:alojamiento.cALO_Precio,
          })
      })
    console.log('get viajes ======',viajes)
    setViaje(viajes);
    setAlojamiento(alojamientos);
  }

  const handleChange=({target})=>{
    console.log(target.value)
    sessionStorage.setItem('obsevaciones',target.value)
  }

  useEffect(()=>{
    sessionStorage.clear();
    getEventsByViaticos({variables:{cEMP_Id:cEMP_Id.cEMP_Id,cREU_FechaInicio:new Date().toISOString().slice(0,10)}})
    sessionStorage.setItem('cTIP_Id',1)
    console.log(cEMP_Id.profile)
    console.log('context====>',context.state.viaticosProps)
    if(context.state.viaticosProps.cVIT_Id!==""){
      getViaticos();
      getEventBycREU({variables:{cREU_Id:context.state.viaticosProps.cREU_Id}})
    }

  },[])
  return(
    <PageContainer marginTop='20px' width='70%' id='button-navigation'>
      <Subtitle color='#000'>{"Revisa solicitud de los viáticos de viaje" || title}</Subtitle>
      <CardPendientes cVIT_Status_Viat={context.state.viaticosProps.cVIT_Status_VIAT} cREU_Id={context.state.viaticosProps.cREU_Id} cVIT_Status_Comp={context.state.viaticosProps.cVIT_Status_COMP} />
      <ContainerTwoInputs width='100%' margin='20px 0px' id='viaticos-solicitud'>
        <Card data={data} img={calendario}/>
        <Card data={data2} img={user}/>
      </ContainerTwoInputs>
      <ButtonsInformacion title='Información de transporte' text1='Ida y vuelta' text2='Solo ida' text3='Múltiples destinos'  cTIp_Id={context.state.viaticosProps.cTIP_Id} />
      {
        value==='Múltiples destinos'?null:<FormTravels title='Viaje de ida' id={0} valueInputs={viaje} asistente={true}/>
      }
      {
        value==='Ida y vuelta'?<FormTravels title='Viaje de vuelta' id={1} valueInputs={viaje} asistente={true} />:null
      }
      {
        value==='Múltiples destinos'?
        <Fragment>
          {
            tramos.map((tramo,index)=><FormTravels title={tramo.titulo} id={index} valueInputs={viaje} asistente={true}/>)
          }
        </Fragment>:null

      }
      {
        value==='Múltiples destinos'?
        <Fragment>
          {
            tramos.map((tramo,index)=><FormAlojamiento title={`Alojamiento - ${tramo.titulo}`} id={index} valueInputs={alojamiento} asistente={true}/>)
          }
        </Fragment>
        :<FormAlojamiento title='Información de alojamiento' id={0} valueInputs={alojamiento} asistente={true}/>
      }
      <FormMateriales title='Equipo o materiales para viaje' cambio={value} asistente={true} />
      {
        context.state.viaticosProps.observaciones.map((data)=>{
          console.log('Data observaciones',data)
          return(
            <Fragment>
              <div style={{marginTop:'20px'}}>
                <h3 style={{textAlign:'left'}}>{`Observaciones - ${getRol(data.cTOBS_Id)}`}</h3>
              </div>
              <TextareaAutosize rowsMin={10} style={{width:"90%"}}  id='text-mensaje' name='area' value={`${data2.titulo} :
${data.cOBS_Descripcion}`} disabled/>
            </Fragment>
          )
        })
      }
      <div style={{marginTop:'20px'}}>
              <h3 style={{textAlign:'left'}}>observaciones</h3>
      </div>
      <TextareaAutosize rowsMin={10} style={{width:"90%"}}  id='text-mensaje' name="observaciones" onChange={handleChange} value={data.cOBS_Descripcion} placeholder='Escribe comentarios del viaje ...'/>
            <ToggleButtons/>
      <div style={{marginTop:'20px',justifyContent:'center',display:'flex'}}>
      <ButtonNavigation text="Guardar" width='45%' backgroundColor='#3898EC' border='none' color='#fff' justifyContent='center' onClick={saveObservaciones} />
    </div>
    <h3 style={{marginTop:"25px"}}><strong>DESCARGAR DETALLES DE VIAJE</strong></h3>
    <p>Realiza la descarga de la solicitud de viáticos con el detalle de precios y observaciones.</p>
    <div style={{color:"#3898Ec",display:'flex',alignItems:"center"}}>
      <img src={descargar} alt='download' width='100px'/>
      <p>Descargar solicitud</p>
    </div>
    </PageContainer>
  )
}

export default usePendientes;