import React, { Fragment, useMemo, useState, useEffect } from 'react';
import ButtonNavigation from '../Viaticos/ButtonNavigation';
import { useMutation } from 'react-apollo';
import { DINAMIC_GET } from '../../mutation/mutation';
import DatePicker from 'react-date-picker';
import { NativeSelect, TextareaAutosize, Chip } from '@material-ui/core';
import Areas_Inv from './Areas_Inv';
import DivContainer from '../../layouts/Acuerdos/DivContainer';
import ImputResumen from './ImputResumen';
import '../components.css';
import { LabelCard } from '../StylesComponents/common-styled';
import { ArrowBackIos, Clear } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';

//components
import LastStep from './componentsRelacion/LastStep';
import LastStepWithStatus from './componentsRelacion/LastStepWithStatus';
import LastStepWithLabel from './componentsRelacion/LastStepWithLabel';
import Questions from "./componentsRelacion/Questions"
import Comments from './componentsRelacion/Comments';
import DropdownAreas from "./componentsRelacion/DropdownAreas";
import QuestionsOptions from "./componentsRelacion/QuestionsOptions"

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        justifyContent: 'flex-start',
        flexWrap: 'wrap',
        '& > *': {
            margin: theme.spacing(0.5),
        },
    },
}));
const RelacionV2 = ({ onClick }) => {
    const classes = useStyles();
    const [currentQuestion, setCurrentQuestion] = useState('tema');
    const [status, setStatus] = useState('');
    const [temaRegistrar, setTemaRegistrar] = useState('');
    const [step, setStep] = useState(1);
    const [showTitle, setShowTitle] = useState(true);
    const [options, setOptions] = useState([]);
    const [saveAdvanceQuestion, setAdvanceQuestions] = useState([]);
    const [limitDate, setLimitDate] = useState(new Date());
    const [formAreas, setFormAreas] = useState({
        Direccion: '',
        Finanzas: '',
        Operaciones: '',
        Suscripcion: ''
    })
    const [areas, setAreas] = useState([]);
    const [commentPresentPatria, setCommentPresentPatria] = useState('');
    const [programReaseguro, setCommentProgramReasegu] = useState('');
    const [arrayQuestions] = useState({
        tema: '¿De qué tema deseas hacer tu registro?',
        programaReaseguro: 'Fecha límite establecida para entrega de la propuesta.',
        facultativo: '¿Qué deseas registrar sobre ',
        siniestros: 'Escribe lo comentado sobre ',
        pendientesPatria: '¿Sobre qué son los ',
        presentacionPatria: '¿Qué deseas registrar sobre ',
        capacitacionSeminarios: '¿Qué deseas registrar sobre ',
        impuestoRetenidoExt: '¿Cuál es el estatus del Impuesto retenido en el extranjero?',
        expedientePLD: '¿Cuál es el estatus del ',

        afterLimitDate: '¿Qué deseas registrar sobre Programa de Reaseguro?',
        resultados: 'Escribe lo comentado sobre ',
        estrategia: '¿Sobre qué tema de ',
        fortalezasAndAreasMejora: '',
        platicaPresentacion: '¿Sobre qué tema es la ',
        considerarlosProxSemAndOtro: 'Escribe lo comentado sobre ',
        statusImpuestoRetenidoExt: '',
        statusExpedientePLD: 'Escribe lo comentado sobre Expediente PLD',

        sinCambios: 'Escribe lo comentado sobre ',
        cambios: '¿Sobre qué tema son los ',
        oportunidadesPatria: '¿Sobre qué son las ',
        liderYOtros: 'Escribe lo comentado sobre ',
        afterMultComments: 'Escribe lo comentado sobre ',
        afterDropdownStep3: 'Escribe lo comentado sobre ',
        afterTemaPlaticaPresent: 'Escribe lo comentado sobre ',

        afterResultadosComments: 'Resumen de Relación con Patria ',
        afterQuestionsEstrategia: 'Escribe lo comentado sobre ',
        afterQuestFortalAndAreasMejora: 'Escribe lo comentado sobre ',

        afterEstrategiaComments: 'Resumen de Relación con Patria ',
        resumenConsideacionAndOtro: 'Resumen de Relación con Patria',
        afterDesplegPlaticaPrese: 'Resumen de Relación con Patria',
        cambiosComments: 'Escribe lo comentado sobre '
    });
    const [titles] = useState({
        suscripcion: ['Políticas de suscripción', 'Exclusiones', 'Restricciones'],
        apetitoRiesgos: ['Giros/Carteras', 'Coberturas', 'Límites'],
        necesidades: ['Capacidad', 'Precio', 'Términos y Condiciones'],
    });
    const [input, setInput] = useState({
        table: 'cRELP_RelacionPatria',
        columns: ['cRELP_Id', 'cRELP_Descripcion'],
    });

    const [getRelacion] = useMutation(DINAMIC_GET, {
        onCompleted({ dynamicGet }) {
            setOptions(JSON.parse(dynamicGet.response));
        },
    });

    const handleChange = ({ target }) => {
        console.log('handleChange', target.value)
        const { name, value } = target;
        setFormAreas({
            ...formAreas,
            [name]: value
        })
    }

    const handleOnClickBack = (currentQuestion) => {
        let index = saveAdvanceQuestion.indexOf(currentQuestion);
        let stepAux = index === 0 ? index : index - 1;
        if (index === 0) {
            setCurrentQuestion('tema');
            setStep(step - 1);
            saveAdvanceQuestion.splice(index, 1);
        } else {
            setCurrentQuestion(saveAdvanceQuestion[stepAux]);
            setStep(step - 1);
            saveAdvanceQuestion.splice(index, 1);
        }
    }

    const backRelacionPatria = () => {
        setFormAreas({});
        setAreas([]);
        setCurrentQuestion('tema');
        setAdvanceQuestions([]);
    }

    const deleteSubarea = (area) => {
        setAreas(areas.filter(item => item !== area));
    }


    //funciones para el avance de preguntas
    const handleQuestionsAdvanceStep1 = ({ idOption = 0, description = '' }) => {
        setTemaRegistrar('');
        setStep(2);
        switch (idOption) {
            case 1:
                setCurrentQuestion('programaReaseguro');
                setAdvanceQuestions(['programaReaseguro']);
                break;
            case 2:
                setCurrentQuestion('facultativo');
                setTemaRegistrar(`${description}?`);
                setAdvanceQuestions(['facultativo']);
                break;
            case 3:
                setCurrentQuestion('siniestros');
                setTemaRegistrar('Siniestros');
                setAdvanceQuestions(['siniestros']);
                break;
            case 4:
                setCurrentQuestion('pendientesPatria');
                setTemaRegistrar(`${description}?`);
                setAdvanceQuestions(['pendientesPatria']);
                break;
            case 5:
                setCurrentQuestion('presentacionPatria');
                setTemaRegistrar(`${description}?`);
                setAdvanceQuestions(['presentacionPatria']);
                break;
            case 6:
                setCurrentQuestion('capacitacionSeminarios');
                setTemaRegistrar(`${description}?`);
                setAdvanceQuestions(['capacitacionSeminarios']);
                break;
            case 7:
                setCurrentQuestion('impuestoRetenidoExt');
                setAdvanceQuestions(['impuestoRetenidoExt']);
                break;
            case 8:
                setCurrentQuestion('expedientePLD');
                setTemaRegistrar(`${description}?`);
                setAdvanceQuestions(['expedientePLD']);
                break;
            default:
                break;
        }
    }
    const handleQuestionsAdvanceStep2 = ({ idOption = 0, description = '' }) => {
        setStep(3);
        setShowTitle(true);
        setTemaRegistrar('');
        let idOptAux = idOption;
        if (idOptAux === 4 || idOptAux === 5) idOptAux = 'afterSobrePendientesPatria';
        if (idOptAux === 10 || idOptAux === 11) {
            arrayQuestions.fortalezasAndAreasMejora = `¿Sobre qué ${idOptAux === 10 ? 'Fortaleza' : 'Área de mejora'} se comentó?`
            idOptAux = 'fortalezasAndAreasMejora';//Fortaleza/Area de mejora
        };
        if (idOptAux === 12 || idOptAux === 14) {
            idOptAux = 'considerarlosProxSemAndOtro'
        }
        if (idOptAux === 15 || idOptAux === 16) {
            arrayQuestions.statusImpuestoRetenidoExt = idOptAux === 15 ? `Escribe lo comentado sobre Impuesto Retenido en el Extranjero` : `Documentos Requeridos`
            setStatus(description)
            idOptAux = 'statusImpuestoRetenidoExt';
        }
        if (idOptAux === 17 || idOptAux === 18 || idOptAux === 19) {
            setStatus(description);
            idOptAux = 'statusExpedientePLD';
        }
        switch (idOptAux) {
            case 122:
                setCurrentQuestion('suscripcion');
                setShowTitle(false);
                setAdvanceQuestions([...saveAdvanceQuestion, 'suscripcion']);
                break;
            case 22:
                setCurrentQuestion('apetitoRiesgos');
                setShowTitle(false);
                setAdvanceQuestions([...saveAdvanceQuestion, 'apetitoRiesgos']);
                break;
            case 32:
                setCurrentQuestion('necesidades');
                setShowTitle(false);
                setAdvanceQuestions([...saveAdvanceQuestion, 'necesidades']);
                break;
            case 'afterLimitDate':
                setCurrentQuestion('afterLimitDate');
                setAdvanceQuestions([...saveAdvanceQuestion, 'afterLimitDate']);
                break;
            case 'afterSobrePendientesPatria':
                setCurrentQuestion('afterSobrePendientesPatria');
                setShowTitle(false);
                setAdvanceQuestions([...saveAdvanceQuestion, 'afterSobrePendientesPatria']);
                break;
            case 6:
                setCurrentQuestion('resultados');
                setTemaRegistrar('Presentación Patria');
                setAdvanceQuestions([...saveAdvanceQuestion, 'resultados']);

                break;
            case 7:
                setCurrentQuestion('estrategia');
                setTemaRegistrar('Estrategia?');
                setAdvanceQuestions([...saveAdvanceQuestion, 'estrategia']);
                break;
            case 8:
                setCurrentQuestion('resultados');//calificacionFinanciera
                setTemaRegistrar('Presentación Patria');
                setAdvanceQuestions([...saveAdvanceQuestion, 'resultados']);
                break;
            case 9:
                setCurrentQuestion('resultados');//cambiosOrganizacionales
                setTemaRegistrar('Presentación Patria');
                setAdvanceQuestions([...saveAdvanceQuestion, 'resultados']);
                break;
            case 'fortalezasAndAreasMejora':
                setCurrentQuestion('fortalezasAndAreasMejora');
                setAdvanceQuestions([...saveAdvanceQuestion, 'fortalezasAndAreasMejora']);
                break;
            case 'considerarlosProxSemAndOtro':
                setTemaRegistrar(description);
                setCurrentQuestion('considerarlosProxSemAndOtro');
                setAdvanceQuestions([...saveAdvanceQuestion, 'considerarlosProxSemAndOtro']);
                break;
            case 13:
                setCurrentQuestion('platicaPresentacion');
                setTemaRegistrar('Plática/Presentación?');
                setAdvanceQuestions([...saveAdvanceQuestion, 'platicaPresentacion']);
                break;
            case 'statusImpuestoRetenidoExt':
                setCurrentQuestion('statusImpuestoRetenidoExt');
                setAdvanceQuestions([...saveAdvanceQuestion, 'statusImpuestoRetenidoExt']);
                break;
            case 'statusExpedientePLD':
                setCurrentQuestion('statusExpedientePLD');
                setAdvanceQuestions([...saveAdvanceQuestion, 'statusExpedientePLD']);
                break;
            default:
                break;
        }
    }

    const handleQuestionsAdvanceStep3 = ({ idOption = 0, description = '' }) => {
        console.log('handleQuestionsAdvanceStep3', idOption, description)
        setStep(4);
        setShowTitle(true);
        setTemaRegistrar('');
        let idOptAux = idOption;
        if (idOptAux >= 401 && idOptAux <= 404) idOptAux = 'afterQuestionsEstrategia';
        if (idOptAux >= 405 && idOptAux <= 409) idOptAux = 'afterQuestFortalAndAreasMejora';
        if (idOptAux >= 410 && idOptAux <= 412) idOptAux = 'afterTemaPlaticaPresent';

        switch (idOptAux) {
            case 133:
                setCurrentQuestion('sinCambios');
                setTemaRegistrar('Programa de Reaseguro');
                setAdvanceQuestions([...saveAdvanceQuestion, 'sinCambios']);
                break;
            case 134:
                setCurrentQuestion('cambios');
                setTemaRegistrar('cambios?');
                setAdvanceQuestions([...saveAdvanceQuestion, 'cambios']);
                break;
            case 135:
                setCurrentQuestion('oportunidadesPatria');
                setTemaRegistrar('Oportunidades para Patria?');
                setAdvanceQuestions([...saveAdvanceQuestion, 'oportunidadesPatria']);
                break;
            case 136:
                setCurrentQuestion('liderYOtros');
                setTemaRegistrar('Programa de Reaseguro');
                setAdvanceQuestions([...saveAdvanceQuestion, 'liderYOtros']);
                break;
            case 'afterMultComments':
                setCurrentQuestion('afterMultComments');
                setTemaRegistrar('Facultativo');
                setAdvanceQuestions([...saveAdvanceQuestion, 'afterMultComments']);
                break;
            case 'afterDropdownStep3':
                setCurrentQuestion('afterDropdownStep3');
                setTemaRegistrar('Pendientes con Patria');
                setAdvanceQuestions([...saveAdvanceQuestion, 'afterDropdownStep3']);
                break;
            case 'afterResultadosComments':
                setCurrentQuestion('afterResultadosComments');
                setAdvanceQuestions([...saveAdvanceQuestion, 'afterResultadosComments']);
                break;
            case 'afterQuestionsEstrategia':
                setCurrentQuestion('afterQuestionsEstrategia');
                setTemaRegistrar('Presentación Patria');
                setAdvanceQuestions([...saveAdvanceQuestion, 'afterQuestionsEstrategia']);
                break;
            case 'afterQuestFortalAndAreasMejora':
                setCurrentQuestion('afterQuestFortalAndAreasMejora');
                setTemaRegistrar('Presentación Patria');
                setAdvanceQuestions([...saveAdvanceQuestion, 'afterQuestFortalAndAreasMejora']);

                break;
            case 'afterCampoAbiertoConsideracion':
                setCurrentQuestion('afterCampoAbiertoConsideracion');
                setShowTitle(false);
                setAdvanceQuestions([...saveAdvanceQuestion, 'afterCampoAbiertoConsideracion']);
                break;
            case 'afterTemaPlaticaPresent':
                setCurrentQuestion('afterTemaPlaticaPresent');
                setTemaRegistrar('Plática/Presentación');
                setAdvanceQuestions([...saveAdvanceQuestion, 'afterTemaPlaticaPresent']);

                break;

            default:
                break;
        }
    }

    const handleQuestionsAdvanceStep4 = ({ idOption = 0, description = '' }) => {
        setStep(5);
        setShowTitle(true);
        setTemaRegistrar('');
        let idOptAux = idOption;
        if (idOptAux >= 201 && idOptAux <= 208) {
            idOptAux = 'cambiosComments'
        }
        switch (idOptAux) {
            case 'cambiosComments':
                setCurrentQuestion('cambiosComments');
                setTemaRegistrar('Programa de Reaseguro');
                setAdvanceQuestions([...saveAdvanceQuestion, 'cambiosComments']);
                break;
            case 'afterEstrategiaComments':
                setCurrentQuestion('afterEstrategiaComments');
                setTemaRegistrar('Presentación Patria');
                setAdvanceQuestions([...saveAdvanceQuestion, 'afterEstrategiaComments']);
                break;
            case 'desplegableAfterCampoAbierto':
                setCurrentQuestion('resumenConsideacionAndOtro');
                setTemaRegistrar('Presentación Patria');
                setAdvanceQuestions([...saveAdvanceQuestion, 'resumenConsideacionAndOtro']);
                break;
            case 'afterCampoAbiertoPlaticaPresent':
                setCurrentQuestion('afterCampoAbiertoPlaticaPresent');
                setTemaRegistrar('Presentación Patria');
                setAdvanceQuestions([...saveAdvanceQuestion, 'afterCampoAbiertoPlaticaPresent']);
                break;

            default:
                break;
        }
    }

    const handleQuestionsAdvanceStep5 = ({ idOption = 0, description = '' }) => {
        setStep(6);
        setShowTitle(true);
        setTemaRegistrar('');
        let idOptAux = idOption;
        switch (idOptAux) {
            case 'afterDesplegPlaticaPrese':
                setCurrentQuestion('afterDesplegPlaticaPrese');
                break;

            default:
                break;
        }
    }

    useEffect(() => {
        // setStep(1)
        getRelacion({ variables: { input } });
    }, [input]);

    useEffect(() => {
        const valuesObj = Object.values(formAreas);
        setAreas(valuesObj.filter(val => val !== ''));
    }, [formAreas]);

    useEffect(() => {
        console.log("STEP ==", step, "currentQuestion", currentQuestion)
    }, [step])

    return (
        <Fragment>
            <div onClick={() => handleOnClickBack(currentQuestion)} style={{ cursor: 'pointer' }}>
                <ArrowBackIos style={{ color: '#01539C' }} />
            </div>
            <h2>Relación Patria</h2>
            {showTitle ? <Questions questionTxt={`${arrayQuestions[currentQuestion]} ${temaRegistrar}`} /> : null}
            <div style={{
                width: '100%', textAlign: 'center', display: 'flex', flexDirection: 'column', alignItems: 'flex-start'
            }}
            >
                {(currentQuestion === 'tema') &&
                    options.map(({ cRELP_Id, cRELP_Descripcion }) => (
                        <ButtonNavigation
                            text={cRELP_Descripcion}
                            key={cRELP_Id}
                            style={{ cursor: 'pointer' }}
                            onClick={() => handleQuestionsAdvanceStep1({ idOption: cRELP_Id, description: cRELP_Descripcion })}
                        />
                    ))
                }
                {/* #################STEP 2 ######### */}
                {currentQuestion === 'programaReaseguro' && step === 2 && (
                    <Fragment>
                        <DatePicker
                            value={limitDate}
                            onChange={(date) => setLimitDate(date)}
                            className="calendar-button react-date-picker__wrapper"
                        />
                        <ButtonNavigation
                            text="Guardar"
                            style={{ cursor: 'pointer' }}
                            onClick={() => handleQuestionsAdvanceStep2({ idOption: 'afterLimitDate' })}
                        />
                    </Fragment>
                )}
                {(step === 2 && QuestionsOptions[currentQuestion]) && //cuando son preguntas de opciones entra aqui dependiendo tambien del step
                    QuestionsOptions[currentQuestion].map(({ description, id }, index) => (
                        <ButtonNavigation
                            text={description}
                            style={{ cursor: 'pointer' }}
                            key={index}
                            onClick={() => handleQuestionsAdvanceStep2({ idOption: id, description })}
                        />
                    ))
                }
                {(step === 2 && currentQuestion === 'siniestros') &&
                    <LastStep
                        onClick={onClick}
                        setState={setCommentPresentPatria}
                        
                    />
                }
                {/* ############STEP 3############  */}
                {(step === 3 && (currentQuestion === 'suscripcion' || currentQuestion === 'apetitoRiesgos' || currentQuestion === 'necesidades')) &&
                    <Comments
                        titles={titles[currentQuestion]}
                        onClick={handleQuestionsAdvanceStep3}
                        paramsOnclick={{ idOption: 'afterMultComments', description: '' }}
                    />
                }
                {(step === 3 && QuestionsOptions[currentQuestion]) && //currentQuestion !== 'tema' && currentQuestion !== 'programaReaseguro' && QuestionsOptions[currentQuestion] &&
                    QuestionsOptions[currentQuestion].map(({ description, id }, index) => (
                        <ButtonNavigation
                            text={description}
                            style={{ cursor: 'pointer' }}
                            key={index}
                            onClick={() => handleQuestionsAdvanceStep3({ idOption: id, description })}
                        />
                    ))
                }
                {(step === 3 && (currentQuestion === 'afterSobrePendientesPatria')) &&
                    <DropdownAreas
                        onClick={handleQuestionsAdvanceStep3}
                        paramsOnclick={{ idOption: 'afterDropdownStep3', description: '' }}
                        handleChange={handleChange}
                        state={formAreas}
                    />
                }
                {(step === 3 && currentQuestion === 'resultados') &&
                    <LastStep
                        onClick={handleQuestionsAdvanceStep3}
                        paramsOnclick={{ idOption: 'afterResultadosComments', description: '' }}
                        setState={setCommentPresentPatria}
                    />
                }
                {(step === 3 && currentQuestion === 'considerarlosProxSemAndOtro') &&
                    <LastStepWithLabel
                        saveFunction={handleQuestionsAdvanceStep3}
                        params={{ idOption: 'afterCampoAbiertoConsideracion', description: '' }}
                    />
                }
                {(step === 3 && (currentQuestion === 'statusImpuestoRetenidoExt' || currentQuestion === 'statusExpedientePLD')) &&
                    <LastStepWithStatus
                        status={status}
                        onClick={onClick}
                    />
                }

                {/* ############# STEP 4 ############ */}
                {(step === 4 && (currentQuestion === 'sinCambios' || currentQuestion === 'liderYOtros' || currentQuestion === 'afterMultComments')) &&
                    <LastStep
                        onClick={onClick}
                    />
                }
                {(step === 4 && currentQuestion === 'afterDropdownStep3') &&
                    <Fragment>
                        <LastStep
                            onClick={onClick}
                        />
                        {
                            areas.length > 0 ?
                                <div style={{ width: '90%', display: 'flex', flexDirection: 'column', textAlign: 'initial' }}>
                                    <h4 style={{ color: '#3898EC' }}>Áreas</h4>
                                    <div className={classes.root}>
                                        {
                                            areas &&
                                            areas.map((area, index) =>
                                                <Chip label={area} style={{ backgroundColor: '#3898EC', color: '#FFFF' }} onDelete={() => deleteSubarea(area)} deleteIcon={<Clear />} key={index} />
                                            )
                                        }
                                    </div>
                                </div> : null
                        }
                    </Fragment>
                }
                {(step === 4 && QuestionsOptions[currentQuestion]) && //currentQuestion !== 'tema' && currentQuestion !== 'programaReaseguro' && QuestionsOptions[currentQuestion] &&
                    QuestionsOptions[currentQuestion].map(({ description, id }, index) => (
                        <ButtonNavigation
                            text={description}
                            style={{ cursor: 'pointer' }}
                            key={index}
                            onClick={() => handleQuestionsAdvanceStep4({ idOption: id, description })}
                        />
                    ))
                }
                {(step === 4 && currentQuestion === 'afterResultadosComments') &&
                    <Fragment>
                        <LastStep
                            onClick={onClick}
                            txtBtn='Continuar a Seguimiento'
                            state={commentPresentPatria}
                            setState={setCommentPresentPatria}
                        />
                        <ButtonNavigation
                            text='Volver a Relación con Patria'
                            style={{ cursor: 'pointer' }}
                            onClick={() => backRelacionPatria()}
                        />
                    </Fragment>
                }
                {(step === 4 && (currentQuestion === 'afterQuestionsEstrategia' || currentQuestion === 'afterQuestFortalAndAreasMejora')) &&
                    <LastStep
                        onClick={handleQuestionsAdvanceStep4}
                        paramsOnclick={{ idOption: 'afterEstrategiaComments', description: '' }}
                        setState={setCommentPresentPatria}
                    />
                }
                {step === 4 && currentQuestion === 'afterCampoAbiertoConsideracion' &&
                    <DropdownAreas
                        onClick={handleQuestionsAdvanceStep4}
                        paramsOnclick={{ idOption: 'desplegableAfterCampoAbierto', description: '' }}
                        handleChange={handleChange}
                        state={formAreas}
                    />
                }
                {(step === 4 && currentQuestion === 'afterTemaPlaticaPresent') &&
                    <LastStepWithLabel
                        saveFunction={handleQuestionsAdvanceStep4}
                        params={{ idOption: 'afterCampoAbiertoPlaticaPresent', description: '' }}
                    />
                }

                {/* ###########STEP 5###################### */}
                {(step === 5 && (currentQuestion === 'cambiosComments')) &&
                    <LastStep
                        onClick={onClick}
                        // paramsOnclick={{ idOption: 'afterEstrategiaComments', description: '' }}
                        setState={setCommentProgramReasegu}
                    />
                }
                {(step === 5 && (currentQuestion === 'afterEstrategiaComments' || currentQuestion === 'resumenConsideacionAndOtro')) &&
                    <Fragment>
                        <LastStep
                            onClick={onClick}
                            txtBtn='Continuar a Seguimiento'
                            state={commentPresentPatria}
                            setState={setCommentPresentPatria}
                        />
                        <ButtonNavigation
                            text='Volver a Relación con Patria'
                            style={{ cursor: 'pointer' }}
                            onClick={() => backRelacionPatria()}
                        />
                        {
                            areas.length > 0 ?
                                <div style={{ width: '90%', display: 'flex', flexDirection: 'column', textAlign: 'initial' }}>
                                    <h4 style={{ color: '#3898EC' }}>Áreas</h4>
                                    <div className={classes.root}>
                                        {
                                            areas &&
                                            areas.map((area, index) =>
                                                <Chip label={area} style={{ backgroundColor: '#3898EC', color: '#FFFF' }} onDelete={() => deleteSubarea(area)} deleteIcon={<Clear />} key={index} />
                                            )
                                        }
                                    </div>
                                </div> : null
                        }
                    </Fragment>
                }
                {step === 5 && currentQuestion === 'afterCampoAbiertoPlaticaPresent' &&
                    <DropdownAreas
                        onClick={handleQuestionsAdvanceStep5}
                        paramsOnclick={{ idOption: 'afterDesplegPlaticaPrese', description: '' }}
                        handleChange={handleChange}
                        state={formAreas}
                    />
                }
                {/* #########STEP 6 ########### */}
                {step === 6 && currentQuestion === 'afterDesplegPlaticaPrese' &&
                    <Fragment>
                        <LastStep
                            onClick={onClick}
                            txtBtn='Continuar a Seguimiento'
                            state={commentPresentPatria}
                            setState={setCommentPresentPatria}
                        />
                        <ButtonNavigation
                            text='Volver a Relación con Patria'
                            style={{ cursor: 'pointer' }}
                            onClick={() => backRelacionPatria()}
                        />
                        {
                            areas.length > 0 ?
                                <div style={{ width: '90%', display: 'flex', flexDirection: 'column', textAlign: 'initial' }}>
                                    <h4 style={{ color: '#3898EC' }}>Áreas</h4>
                                    <div className={classes.root}>
                                        {
                                            areas &&
                                            areas.map((area, index) =>
                                                <Chip label={area} style={{ backgroundColor: '#3898EC', color: '#FFFF' }} onDelete={() => deleteSubarea(area)} deleteIcon={<Clear />} key={index} />
                                            )
                                        }
                                    </div>
                                </div> : null
                        }
                    </Fragment>
                }
            </div>
        </Fragment>
    );
}

export default RelacionV2;