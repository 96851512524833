import React from 'react';
import {ExpansionPanel,Typography} from '@material-ui/core';
import {ExpandMore} from '@material-ui/icons';
import {ExpansionPanelHeader,IconPanel,ExpansionPanelBody} from '../StylesComponents/common-styled';

const Acordeon=({children,imgSrc,label,margin,expanded,setExpanded,seccion})=>{
    const Expanded=()=>{
        if(seccion){
            setExpanded(seccion);
        }
    }
    return(
        <ExpansionPanel expanded={expanded} >
            <ExpansionPanelHeader expandIcon={<ExpandMore className='Expand-more'/>} aria-controls="panel-content" id="panel-header" style={{alignItems:'center'}}
            onClick={Expanded}
            >
                <IconPanel src={imgSrc} alt={imgSrc} margin={margin}/>
                <Typography>{label}</Typography>
            </ExpansionPanelHeader>
            <ExpansionPanelBody>
                {children}
            </ExpansionPanelBody>
        </ExpansionPanel>
    )
}

export default Acordeon