import React from 'react';
import ButtonNavigation from './ButtonNavigation';
import Ubicación from '../../assets/images/icons/ubicacion.svg';

const Footer =()=>{
  return(
    <div style={{backgroundColor:'#222222',padding:'20px'}}>
      <div style={{width:'70%',display:'flex',justifyContent:'space-evenly'}}>
        <ButtonNavigation backgroundColor='#07C1CF' justifyContent='center' width='25%' color='#fff' border='none' text='Aviso de Privacidad'/>
        <ButtonNavigation backgroundColor='#07C1CF' justifyContent='center' width='25%' color='#fff' border='none' text='Contactanos'/>
        <ButtonNavigation backgroundColor='#07C1CF' justifyContent='center' width='25%' color='#fff' border='none' text='Linea Ética'/>
      </div>
      <div style={{width:'70%',display:'flex',justifyContent:'space-evenly',marginTop:'20px' }}>
        <div style={{width:'20%',display:'flex',justifyContent:'center',alignItems:'end'}}>
          <img src={Ubicación} alt='ubicacion' width='25px'/>
        </div>
        <div style={{width:'70%',color:'#fff',fontSize:'12px'}}>
          <p>Periférico Sur 2771, San Jerónimo Lídice, Jardines del Pedregal, La Magdalena Contreras, 10200 Ciudad de México, CDMX </p>
          <p>info@patriare.com.mx +52 (55) 5683-4900</p>
        </div>
      </div>
    </div>
  )
}

export default Footer;