import React, { useEffect, useState, useContext , Fragment } from "react";
import Homebar from "./Homebar";
import {
  Page,
  PageContainer,
  Title,
  Subtitle,
  DivNavigationButton,
  AcordeonContainer,
} from "./StylesComponents/common-styled";
import { navigation } from "../funciones-auxiliares/programar-reunion";
import newSolicitud from "../assets/images/icons/documento.svg";
import processSolicitud from "../assets/images/icons/lupa.svg";
import comprobarGasto from "../assets/images/icons/calculadora.svg";
import ticket from "../assets/images/icons/tickets.svg";
import NewSolicitud from "./Viaticos/NewSolicitud";
import "./../assets/styles/css/master.css";
import ButtonNavigation from "./Viaticos/ButtonNavigation";
import Footer from "./Viaticos/Footer";
import { useHistory } from "react-router-dom";
import ProgressSolicitud from "./Viaticos/ProgressSolicitud";
import { MyContext } from "./Context";
import Pendientes from "./Viaticos/Pendientes";
import Director from "./Viaticos/Director";
import Comprobacion from "./Viaticos/comprobacion";
import moment from "moment";

const Viaticos = ({ session }) => {
  //Hooks
  const history = useHistory();
  const context = useContext(MyContext);

  const [activeButtons, setActiveButtons] = useState({
    first: true,
    second: false,
    third: false,
    fourth: false,
    five: false,
    six: false,
    sevent: false,
  });
  //Handlers

  useEffect(() => {
    session.obtenerUsuario ? console.log("hola") : history.push("/");
    if (
      context.state.AuthComponentProps.isAuthenticated === null ||
      context.state.AuthComponentProps.isAuthenticated === false
    ) {
      history.push("/secciones");
    }
  });
  return (
    <Fragment>
      <Page>
        <Homebar navigation={navigation} />
        <PageContainer>
          <Title>Viáticos</Title>
          <Subtitle>
            Solicita, registra y autoriza los viáticos de tus viajes.
          </Subtitle>
        </PageContainer>
        <AcordeonContainer
          flexDirection="row"
          justifyContent="space-around"
          id="viaticos-solicitud"
        >
          <DivNavigationButton
            width="20%"
            alignSelf="end"
            id="button-navigation"
          >
            <ButtonNavigation
              text="Nueva solicitud"
              justifyContent="space-between"
              style={{
                padding: "0 30px",
                display: "grid",
                gridTemplateColumns: "50fr 50fr",
                textAlign: "left",
              }}
              img={newSolicitud}
              backgroundColor={
                activeButtons.first &&
                !activeButtons.fourth &&
                !activeButtons.five &&
                !activeButtons.third
                  ? "#3898EC"
                  : null
              }
              border={activeButtons.first ? "#3898EC" : null}
              color={
                activeButtons.first &&
                !activeButtons.fourth &&
                !activeButtons.five &&
                !activeButtons.third
                  ? "#FFF"
                  : null
              }
              onClick={() => {
                setActiveButtons({
                  first: true,
                  second: false,
                  third: false,
                  fourth: false,
                  five: false,
                  six: false,
                  sevent: false,
                });
                context.clearState();
              }}
            />
            <ButtonNavigation
              text="Solicitudes en proceso"
              justifyContent="space-between"
              style={{
                padding: "0 30px",
                display: "grid",
                textAlign: "right",
                gridTemplateColumns: "10fr 15fr",
              }}
              img={processSolicitud}
              backgroundColor={activeButtons.second ? "#3898EC" : null}
              border={activeButtons.second ? "#3898EC" : null}
              color={activeButtons.second ? "#FFF" : null}
              onClick={() =>
                setActiveButtons({
                  first: false,
                  second: true,
                  third: false,
                  fourth: false,
                  five: false,
                  six: false,
                  sevent: false,
                })
              }
            />
            <ButtonNavigation
              text="Comprobar gastos"
              img={comprobarGasto}
              style={{
                padding: "0 30px",
                display: "grid",
                gridTemplateColumns: "50fr 50fr",
                textAlign: "left",
              }}
              justifyContent="space-between"
              backgroundColor={activeButtons.third ? "#3898EC" : null}
              border={activeButtons.third ? "#3898EC" : null}
              color={activeButtons.third ? "#FFF" : null}
              onClick={() =>
                setActiveButtons({
                  first: false,
                  second: false,
                  third: true,
                  fourth: false,
                  five: false,
                  six: false,
                  sevent: false,
                })
              }
            />
            {session.obtenerUsuario.profile === 61 ? (
              <ButtonNavigation
                text="Tickets pendientes"
                img={ticket}
                style={{
                  padding: "0 30px",
                  display: "grid",
                  gridTemplateColumns: "50fr 50fr",
                  textAlign: "left",
                }}
                justifyContent="space-between"
                backgroundColor={activeButtons.fourth ? "#3898EC" : null}
                border={activeButtons.fourth ? "#3898EC" : null}
                color={activeButtons.fourth ? "#FFF" : null}
                onClick={() =>
                  setActiveButtons({
                    first: false,
                    second: false,
                    third: false,
                    fourth: true,
                    five: false,
                    six: false,
                    sevent: false,
                  })
                }
              />
            ) : null}
            {session.obtenerUsuario.profile === 63 ? (
              <ButtonNavigation
                text="Autorizaciones pendientes"
                img={ticket}
                style={{
                  padding: "0 30px",
                  display: "grid",
                  gridTemplateColumns: "50fr 50fr",
                  textAlign: "left",
                }}
                justifyContent="space-between"
                backgroundColor={activeButtons.five ? "#3898EC" : null}
                border={activeButtons.five ? "#3898EC" : null}
                color={activeButtons.five ? "#FFF" : null}
                onClick={() =>
                  setActiveButtons({
                    first: false,
                    second: false,
                    third: false,
                    fourth: false,
                    five: true,
                    six: false,
                    sevent: false,
                  })
                }
              />
            ) : null}
          </DivNavigationButton>
          {activeButtons.first &&
          session.obtenerUsuario &&
          !activeButtons.fourth &&
          !activeButtons.five &&
          !activeButtons.third ? (
            <NewSolicitud
              cEMP_Id={session.obtenerUsuario}
              handleComponent={() =>
                setActiveButtons({
                  first: false,
                  second: true,
                  third: false,
                  fourth: false,
                  five: false,
                  six: false,
                  sevent: false,
                })
              }
            />
          ) : null}
          {activeButtons.second && session.obtenerUsuario ? (
            <ProgressSolicitud
              cEMP_Id={session.obtenerUsuario}
              handleComponent={() =>
                setActiveButtons({
                  first: true,
                  second: false,
                  third: false,
                  fourth: false,
                  five: false,
                  six: false,
                  sevent: false,
                })
              }
              type={0}
            />
          ) : null}
          {activeButtons.third &&
          !activeButtons.first &&
          session.obtenerUsuario ? (
            <ProgressSolicitud
              cEMP_Id={session.obtenerUsuario}
              handleComponent={() =>
                setActiveButtons({
                  first: true,
                  second: false,
                  third: true,
                  fourth: false,
                  five: false,
                  six: false,
                  sevent: false,
                })
              }
              type={2}
              title="Realiza la comprobación de gastos"
              status={2}
            />
          ) : null}
          {activeButtons.fourth &&
          session.obtenerUsuario &&
          !activeButtons.first &&
          !activeButtons.five ? (
            <ProgressSolicitud
              cEMP_Id={session.obtenerUsuario}
              handleComponent={() =>
                setActiveButtons({
                  first: true,
                  second: false,
                  third: false,
                  fourth: true,
                  five: false,
                  six: false,
                  sevent: false,
                })
              }
              type={1}
              title="Revisa solicitud de los viaticos de viaje"
              status={1}
            />
          ) : null}
          {activeButtons.five &&
          session.obtenerUsuario &&
          !activeButtons.first ? (
            <ProgressSolicitud
              cEMP_Id={session.obtenerUsuario}
              handleComponent={() =>
                setActiveButtons({
                  first: true,
                  second: false,
                  third: false,
                  fourth: false,
                  five: true,
                  six: false,
                  sevent: false,
                })
              }
              type={1}
              title="Revisa solicitud de los viaticos de viaje"
              status={2}
            />
          ) : null}
          {activeButtons.fourth &&
          activeButtons.first &&
          session.obtenerUsuario ? (
            <Pendientes
              cEMP_Id={session.obtenerUsuario}
              handleComponent={() =>
                setActiveButtons({
                  first: false,
                  second: true,
                  third: false,
                  fourth: false,
                  five: false,
                  six: false,
                  sevent: false,
                })
              }
            />
          ) : null}
          {activeButtons.five &&
          activeButtons.first &&
          session.obtenerUsuario ? (
            <Director
              cEMP_Id={session.obtenerUsuario}
              handleComponent={() => ({
                first: false,
                second: true,
                third: false,
                fourth: false,
                five: false,
                six: false,
                sevent: false,
              })}
            />
          ) : null}
          {activeButtons.third &&
          activeButtons.first &&
          session.obtenerUsuario ? (
            <Comprobacion
              cEMP_Id={session.obtenerUsuario}
              handleComponent={() => ({
                first: false,
                second: true,
                third: false,
                fourth: false,
                five: false,
                six: false,
                sevent: false,
              })}
            />
          ) : null}
        </AcordeonContainer>
      </Page>
      <Footer />
    </Fragment>
  );
};

export default Viaticos;
