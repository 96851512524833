import React, { useState, useEffect, useContext } from 'react';
import '../components.css';
import ButtonNavigation from '../Viaticos/ButtonNavigation';
import DateSelect from '../DateSelect/DateSelect';
import { getDateString } from '../../funciones-auxiliares/acuerdos';
import { useLazyQuery } from 'react-apollo';
import { MyContext } from '../Context';
import { GET_REUNIONES_BYFECHA } from '../../query/query';
import moment from 'moment';
import { ArrowBackIos, TrainOutlined } from '@material-ui/icons';

export default function NewReuProgramada({ cEMP_Id, onClick }) {
  const context = useContext(MyContext);
  const [date, setDate] = useState(moment());
  const [dateSelected, setDateSelected] = useState(false);
  const [reuniones, setReuniones] = useState([]);

  const setDates = (fechA) => {
    setDate(moment(fechA));
    setDateSelected(TrainOutlined);
  };

  const [getReuniones] = useLazyQuery(GET_REUNIONES_BYFECHA, {
    onCompleted({ getReunionesByfecha }) {
      console.log(getReunionesByfecha);
      setReuniones(getReunionesByfecha);
      // const acuerdosProps=getReunionesByfecha;
      // context.getData({acuerdosProps:getReunionesByfecha})
    },
  });

  useEffect(() => {
    getReuniones({
      variables: {
        cEMP_Id: cEMP_Id,
        cREU_FechaInicio: date.format('YYYY-MM-DD'),
      },
    });
  }, [date]);

  const handleOnClick = ({ target }, reunion) => {
    if (dateSelected) {
      context.getData({ acuerdosProps: reuniones[target.id] });
      context.setSaveAcuerdos({
        cREU_Id: reunion.cREU_Id,
        cEMP_Id: cEMP_Id,
      });
      onClick();
    } else return;
  };

  const onGoBack = () => {};

  return (
    <div style={{ width: '100%' }} className="registro-container">
      <div onClick={onGoBack} style={{ cursor: 'pointer' }}>
        <ArrowBackIos style={{ color: '#01539C' }} />
      </div>
      <div style={{ display: 'flex' }}>
        <h4 style={{ color: '#01539C' }}>Nuevo Reporte de Visita </h4>
        <h4 style={{ color: '#858585' }}>` &gt; Reunión Programada`</h4>
      </div>
      <h3>Elige de la agenda la cita que tuviste</h3>
      <DateSelect date={date} setDates={setDates} />
      <div
        style={{
          textAlign: 'center',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        {reuniones && reuniones.length > 0 ? (
          reuniones.map((reunion, index) => {
            return (
              <ButtonNavigation
                width="70%"
                text={`${reunion.cREU_Titulo} [${reunion.cREU_Lugar} , ${reunion.cREU_FechaInicio} , ${reunion.cREU_HoraInicio}]`}
                backgroundColor={index == 0 ? '#3898EC' : null}
                border={index == 0 ? '#3898EC' : null}
                color={index == 0 ? '#FFF' : null}
                key={index}
                name={index}
                style={{ cursor: 'pointer' }}
                id={index}
                onClick={(event) => handleOnClick(event, reunion)}
              />
            );
          })
        ) : (
          <h3>No hay reuniones agendadas</h3>
        )}
      </div>
    </div>
  );
}
