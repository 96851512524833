import {
  Checkbox,
  NativeSelect,
  Paper,
  TextField,
  Typography,
} from '@material-ui/core';
import React, { useState, useContext, useEffect } from 'react';
import ButtonNavigation from '../Viaticos/ButtonNavigation';
import { useQuery, useLazyQuery, useMutation } from 'react-apollo';
import {
  GETCOUNTRIES_SIREC,
  GETCITIES_SIREC,
  GETCOMPANI_SIREC,
  GETCORREDOR_SIREC,
  GETRETROCESSIONARY_SIREC,
} from '../../query/query';
import { DYNAMIC_INSERT, DINAMIC_GET } from '../../mutation/mutation';
import { MyContext } from '../Context';

import '../components.css';
import { ContainerTwoInputs } from '../StylesComponents/common-styled';
import { BootstrapInput } from '../StylesComponents/material-ui-styled';
import DivContainer from '../../layouts/Acuerdos/DivContainer';
import { Autocomplete } from '@material-ui/lab';
import { ArrowBackIos, Search } from '@material-ui/icons';
import {
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from '@material-ui/core';

const tipoReunion = [
  {
    tipo: 'Visita',
    id: 1,
  },
  {
    tipo: 'Llamada',
    id: 2,
  },
  {
    tipo: 'Evento Sector',
    id: 3,
  }, //cREU_TipoReunion
];

const sector = [
  {
    tipo: 'Presencial',
    id: 1,
  },
  {
    tipo: 'Virtual',
    id: 2,
  }, //cREu_lugar
];

const sede = [
  {
    tipo: 'Oficinas',
    id: 1,
  },
  {
    tipo: 'Hotel',
    id: 2,
  },
  {
    tipo: 'Restaurante',
    id: 3,
  },
  {
    tipo: 'Lugar Público',
    id: 4,
  },
];

const comida = [
  {
    tipo: 'Desayuno',
    id: 1,
  },
  {
    tipo: 'Almuerzo',
    id: 2,
  },
  {
    tipo: 'Comida',
    id: 3,
  },
  {
    tipo: 'Cena',
    id: 4,
  },
];

const persona = [
  {
    tipo: 'Cedente',
    id: 1,
  },
  {
    tipo: 'Corredor',
    id: 2,
  },
  {
    tipo: 'Reasegurador',
    id: 3,
  },
  {
    tipo: 'Autoridad',
    id: 4,
  },
  {
    tipo: 'Consultor',
    id: 5,
  },
];

const columns = [
  {
    name: '',
  },
  {
    name: 'Nombre',
  },
  {
    name: 'Correo',
  },
];

const NewReuCasual = ({ onClick1, onGoBack }) => {
  const [reunion, setReunion] = useState({
    cREU_FechaInicio: '',
    cREU_TipoReunion: '',
    cREU_Lugar: '',
    cPAI_Id: '',
    cEDO_Id: 1,
    cREU_Comida: '',
    tipoInvitado: '',
    infoInvitado: '',
    invitados: [],
  });
  const [invitados, setInvitados] = useState([]);
  const [value, setValue] = useState('');
  const [dataCountries, setDataCountries] = useState([]);
  const [dataCities, setDataCities] = useState([]);
  const [idCountrie, setIdCountrie] = useState(0);
  const [idCities, setIdCities] = useState(1);
  const [info, setInfo] = useState([]);
  const [infoValue, setInfoValue] = useState('');
  const [input, setInput] = useState({
    table: 'rEjexORG',
    columns: [
      'cTORG_Id',
      'cORG_Id',
      'cORG_Ver',
      'cEje_Id',
      'cEje_Nombre',
      'cEje_Cargo',
      'cEje_Mail',
    ],
    conditions: [
      {
        valone: 'cORG_Id',
        valtwo: '-1',
        condition: '=',
      },
      {
        valone: 'cTORG_Id',
        valtwo: '-1',
        condition: '=',
        logic: 'AND',
      },
    ],
  });

  useQuery(GETCOUNTRIES_SIREC, {
    onCompleted({ idCatCountries }) {
      setDataCountries(idCatCountries);
      console.log(idCatCountries);
    },
  });

  const [getCitiesSirec] = useLazyQuery(GETCITIES_SIREC, {
    onCompleted({ idCatCity }) {
      setDataCities(idCatCity);
    },
  });

  const [getInfo] = useMutation(DINAMIC_GET, {
    onCompleted({ dynamicGet }) {
      console.log(JSON.parse(dynamicGet.response));
      setInfo(JSON.parse(dynamicGet.response));
    },
  });

  const [getInvitados] = useMutation(DINAMIC_GET, {
    onCompleted({ dynamicGet }) {
      console.log(dynamicGet);
      setInvitados(JSON.parse(dynamicGet.response));
    },
  });

  const limpiarInput = () => {
    setInfoValue('');
  };

  const getCedente = (id) => {
    console.log(id);
    let input;
    if (id === 1) {
      input = {
        table: 'cRET_Retrocesionario',
        columns: ['cRET_Id', 'cRET_RazSocial'],
      };
    }
    if (id === 2) {
      input = {
        table: 'cCOR_Corredor',
        columns: ['cCOR_Id', 'cCOR_RazSocial'],
      };
    }
    if (id === 3) {
      input = {
        table: ' cCIA_Compañia',
        columns: ['cCIA_Id', 'cCIA_RazSocial'],
      };
    }
    if (id <= 3) {
      getInfo({
        variables: {
          input: input,
        },
      });
    }
  };

  const onChange = ({ target }) => {
    if (target.id === 'pais-reunion') {
      setReunion({ ...reunion, cPAI_Id: parseInt(target.value, 10) });
      setIdCountrie(parseInt(target.value, 10));
      getCitiesSirec({ variables: { cPAI_Id: parseInt(target.value, 10) } });
    }
    if (target.id === 'estado-reunion') {
      setReunion({ ...reunion, cEDO_Id: parseInt(target.value, 10) });
      setIdCities(parseInt(target.value, 10));
    }
  };

  useEffect(() => {
    getInvitados({
      variables: {
        input: input,
      },
    });
  }, [input]);

  return (
    <div style={{ width: '100%' }} className="registro-container">
      <div onClick={() => onGoBack()} style={{ cursor: 'pointer' }}>
        <ArrowBackIos style={{ color: '#01539C' }} />
      </div>
      <div style={{ display: 'flex' }}>
        <h4 style={{ color: '#01539C' }}>Nuevo Reporte de Visita </h4>
        <h4 style={{ color: '#858585' }}> &gt; Reunión Casual</h4>
      </div>
      {value === '' ? (
        <DivContainer
          title="Reunión Casual"
          subtitle="¿Cuando se llevó a cabo la reunión?"
        >
          <TextField
            id="reunion"
            type="date"
            variant="outlined"
            style={{ marginBottom: '20px' }}
            value={reunion.fecha}
            onChange={({ target }) =>
              setReunion({ ...reunion, cREU_FechaInicio: target.value })
            }
          />
          <ButtonNavigation
            text="Guardar"
            width="25%"
            backgroundColor="#3898EC"
            border="#3898EC"
            color="#FFFFFF"
            onClick={() =>
              reunion.cREU_FechaInicio !== '' ? setValue('reunion') : null
            }
            id="button-casual"
          />
        </DivContainer>
      ) : null}
      {value === 'reunion' ? (
        <DivContainer title="Reunión Casual" subtitle="La reunión fue un(a)">
          {tipoReunion.map((reu, index) => (
            <ButtonNavigation
              text={reu.tipo}
              width="25%"
              id="button-casual"
              key={index}
              onClick={() => {
                setReunion({ ...reunion, cREU_TipoReunion: reu.tipo });
                reu.id === 3
                  ? setValue('sector')
                  : reu.id === 2
                  ? setValue('sede')
                  : setValue('lugar');
              }}
            />
          ))}
        </DivContainer>
      ) : null}
      {value === 'sector' ? (
        <DivContainer
          title="Reunión Casual"
          subtitle="El evento fue presencial o virtual"
        >
          {sector.map((sec, index) => (
            <ButtonNavigation
              text={sec.tipo}
              width="25%"
              id="button-casual"
              key={index}
              onClick={() => {
                setReunion({ ...reunion, cREU_Lugar: sec.tipo });
                setValue('lugar');
              }}
            />
          ))}
        </DivContainer>
      ) : null}
      {value === 'lugar' ? (
        <DivContainer
          title="Reunión Casual"
          subtitle="¿En donde se llevo a cabo la reunión?"
        >
          <ContainerTwoInputs flexDirection="column">
            <Typography>País</Typography>
            <NativeSelect
              input={<BootstrapInput />}
              style={{ width: '100%' }}
              id="pais-reunion"
              onChange={onChange}
            >
              {dataCountries.map(({ cPAI_Id, cPAI_NOMBRE }, index) => (
                <option key={index} value={cPAI_Id}>
                  {cPAI_NOMBRE}
                </option>
              ))}
            </NativeSelect>
          </ContainerTwoInputs>
          <ContainerTwoInputs
            flexDirection="column"
            style={{ marginBottom: '50px' }}
          >
            <Typography>Estado</Typography>
            <NativeSelect
              input={<BootstrapInput />}
              style={{ width: '100%' }}
              id="estado-reunion"
              onChange={onChange}
            >
              {dataCities.map(({ cEDO_Id, cEDO_Nombre }, index) => (
                <option key={index} value={cEDO_Id}>
                  {cEDO_Nombre}
                </option>
              ))}
            </NativeSelect>
          </ContainerTwoInputs>
          <ButtonNavigation
            text="Guardar"
            width="25%"
            backgroundColor="#3898EC"
            border="#3898EC"
            color="#FFFFFF"
            onClick={() => (idCities != 0 && idCountrie != 0 ? setValue('sede') : null)}
            id="button-casual"
          />
        </DivContainer>
      ) : null}
      {value === 'sede' ? (
        <DivContainer title="Reunión Casual" subtitle="¿Cual fue la sede?">
          {sede.map((sed, index) => (
            <ButtonNavigation
              text={sed.tipo}
              width="25%"
              id="button-casual"
              key={index}
              onClick={() => {
                setReunion({
                  ...reunion,
                  cREU_Lugar: reunion.cREU_Lugar + ',' + sed.tipo,
                });
                setValue(sed.id === 3 ? 'comida' : 'tipoInvitado');
              }}
            />
          ))}
        </DivContainer>
      ) : null}
      {value === 'comida' ? (
        <DivContainer title="Reunión Casual" subtitle="¿Qué comida?">
          {comida.map((com, index) => (
            <ButtonNavigation
              text={com.tipo}
              width="25%"
              id="button-casual"
              key={index}
              onClick={() => {
                setReunion({ ...reunion, cREU_Comida: com.tipo });
                setValue('tipoInvitado');
              }}
            />
          ))}
        </DivContainer>
      ) : null}
      {value === 'tipoInvitado' ? (
        <DivContainer
          title="Reunión Casual"
          subtitle="¿Con quien se tuvo la reunión?"
        >
          {persona.map((tipoInvitado, index) => (
            <ButtonNavigation
              text={tipoInvitado.tipo}
              width="25%"
              id="button-casual"
              key={index}
              onClick={() => {
                setValue('nameCedente');
                getCedente(tipoInvitado.id);
                setReunion({ ...reunion, tipoInvitado: tipoInvitado.tipo });
              }}
            />
          ))}
        </DivContainer>
      ) : null}
      {value === 'nameCedente' ? (
        <DivContainer
          title="Reunión Casual"
          subtitle={`¿Cuál es el nombre del ${reunion.tipoInvitado}?`}
        >
          <Autocomplete
            freeSolo
            disableClearable
            onChange={async (e, { cRET_Id, cCOR_Id, cCIA_Id }) => {
              const auxCondition = input.conditions;
              if (cRET_Id !== undefined) {
                setInput({
                  ...input,
                  conditions: auxCondition.map((condition, index) => {
                    if (index === 0) {
                      return { ...condition, valtwo: '' + cRET_Id };
                    } else {
                      return { ...condition, valtwo: '3' };
                    }
                  }),
                });
              }
              if (cCOR_Id !== undefined) {
                setInput({
                  ...input,
                  conditions: auxCondition.map((condition, index) => {
                    if (index === 0) {
                      return { ...condition, valtwo: '' + cCOR_Id };
                    } else {
                      return { ...condition, valtwo: '2' };
                    }
                  }),
                });
              }
              if (cCIA_Id !== undefined) {
                setInput({
                  ...input,
                  conditions: auxCondition.map((condition, index) => {
                    if (index === 0) {
                      return { ...condition, valtwo: '' + cCIA_Id };
                    } else {
                      return { ...condition, valtwo: '1' };
                    }
                  }),
                });
              }
              limpiarInput();
            }}
            inputValue={infoValue}
            options={info}
            style={{ width: '90%' }}
            getOptionLabel={({
              cRET_RazSocial,
              cCOR_RazSocial,
              cCIA_RazSocial,
            }) => cRET_RazSocial || cCOR_RazSocial || cCIA_RazSocial}
            renderInput={(params) => (
              <TextField
                {...params}
                onChange={({ target }) => setInfoValue(target.value)}
                margin="normal"
                placeholder={`Buscar ${reunion.tipoInvitado}`}
                variant="outlined"
                InputProps={{
                  ...params.InputProps,
                  type: 'search',
                  endAdornment: <Search />,
                }}
              />
            )}
          />
          <TableContainer>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell></TableCell>
                  <TableCell>Nombre</TableCell>
                  <TableCell>Correo</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {invitados.map((invitado, index) => (
                  <TableRow key={index}>
                    <TableRow>
                      <Checkbox />
                    </TableRow>
                    <TableCell>{invitado.cEje_Nombre}</TableCell>
                    <TableCell>{invitado.cEje_Mail}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <ButtonNavigation
            text="Guardar"
            width="25%"
            backgroundColor="#3898EC"
            border="#3898EC"
            color="#FFFFFF"
            onClick={() => infoValue && onClick1()}
            id="button-casual"
          />
        </DivContainer>
      ) : null}
    </div>
  );
};

export default NewReuCasual;
