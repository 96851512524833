import React from 'react';
import { Card } from './styles.styled';

const Styles = {
    card: '',
    col7: '',
    Row: {
        display: 'flex'
    }
}

export default ({ data, img ,setSection }) => {
    const { titulo , texto1 , texto2 , texto3 } = data;
    return (
        <Card width='45%'>
            <div style={{
                ...Styles.Row, borderBottomWidth: 1,
                borderBottomColor: '#eaeaea',
                borderBottomStyle: 'solid',
                paddingBottom: 5,
                margin: 0
            }}>
                <div style={{ ...Styles.col7 }}>
                    <p style={{ fontWeight: 'bold' }}>{titulo}</p>
                </div>
            </div>
            <div style={{ ...Styles.Row, marginVertical: 0, paddingBottom: 0 }}>
            <div style={{ width: '50%', padding: 10 }}>
                    <div style={{ flex: 1, justifyContent: 'center', alignItems: 'center', width: '100%' }}>
                        <div style={{ display:'flex',flex: 1, alignItems: 'center', justifyContent: 'center', borderRadius: 100, height: 95, width: 95 }}>
                            <img src={img} alt='imagen' style={{width:'150px !important', maxWidth:"150px"}}/>
                        </div>
                    </div>
                </div>
                <div style={{ width: '60%', borderRightWidth: 1, borderRightColor: '#eaeaea', paddingRight: 10 }}>
                    <p style={{ fontSize: 13 }}>{texto1}</p>
                    <p style={{ fontSize: 13 }}>{texto2}</p>
                    <p style={{ fontSize: 13 }}>{texto3}</p>
                </div>
                
            </div>
        </Card>
    )

} 