import {makeStyles,withStyles,InputBase,TableCell} from '@material-ui/core';

export const useStyles=makeStyles(()=>({
  root:{
      width:"90%",
      display:"flex",
      flexDirection:"column",
      marginBottom:"25px",
  },
}));

export const BootstrapInput = withStyles((theme)=>({
  root:{
      'label + &':{
          marginTop:theme.spacing(3),
      },
  },
  input:{
      borderRadius:4,
      position:'relative',
      backgroundColor:'#F3F3F3',
      border:'1px solid #ced4da',
      fontSize:16,
      padding:'10px 26px 10px 12px',
      transition: theme.transitions.create(['border-color', 'box-shadow']),
      '&:facus':{
          borderRadius:4,
          borderColor:'#80bdff',
          boxShadow:'0 0 0.2rem rgba(0,123,255,.25)',
      }
  }
}))(InputBase);

export const tableStyles=makeStyles({
  table:{
      width:"100%",
  },
  body:{
      fontSize:14,
  },
  container:{
    width:"90%",
    marginBottom:"20px",
  },
})

export const StyledTableCell=withStyles(()=>({
  head:{
      backgroundColor:"#444444",
      color:"#fff",
      textAlign:"center",
  }
}))(TableCell);