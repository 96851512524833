import { IconButton } from "@material-ui/core";
import {
  ArrowBackIos,
  ArrowDropDown,
  ArrowForwardIos,
} from "@material-ui/icons";
import { Calendar } from "antd";
import Modal from "antd/lib/modal/Modal";
import React, { useState, Fragment } from "react";

const moment = require("moment-timezone");

export default function DateSelect({ date, setDates }) {
  const [isVisible, setIsVisible] = useState(false);

  const setDate = (value) => {
    setDates(value.format("YYYY-MM-DD"));
  };

  const befort = () => {
    let inicio = date;
    inicio.subtract(1, "day").format("YYYY-MM-DD");
    setDates(inicio);
  };

  const next = () => {
    let inicio = date;
    inicio.add(1, "day").format("YYYY-MM-DD");
    setDates(inicio);
  };

  const onOk = () => {
    setIsVisible(false);
  };
  const oncancel = () => {
    setDates(new Date());
    setIsVisible(false);
  };

  return (
    <Fragment>
      <Modal visible={isVisible} onOk={onOk} onCancel={oncancel}>
        <Calendar fullscreen={false} onSelect={setDate} value={moment(date)} />
      </Modal>
      <div style={{ width: "100%", display: "flex" }}>
        <div style={{ width: "10%" }}>
          <IconButton onClick={befort}>
            <ArrowBackIos />
          </IconButton>
        </div>
        <div style={{ textAlign: "center", width: "80%", alignSelf: "center" }}>
          {date.format("YYYY-MM-DD") === moment().format("YYYY-MM-DD") && (
            <h5 style={{ margin: 0, color: "#01539C" }}>Hoy</h5>
          )}
          <div
            style={{
              display: "flex",
              textAlign: "center",
              justifyContent: "center",
            }}
            onClick={() => {
              setIsVisible(true);
            }}
          >
            <h4 style={{ margin: 0 }}>{date.format("YYYY-MM-DD")}</h4>
            <ArrowDropDown />
          </div>
        </div>
        <div style={{ width: "10%" }}>
          <IconButton onClick={next}>
            <ArrowForwardIos />
          </IconButton>
        </div>
      </div>
    </Fragment>
  );
}
