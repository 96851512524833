export const getDateFormat=(date)=>{
  const options = { month: 'short', day: '2-digit', weekday: 'short'};
  const regex = / de/gi;
  if(date!==undefined){
    let inicio = date;
    inicio.setDate(inicio.getDate());
    inicio = inicio.toISOString().slice(0,10);
    inicio = new Date(`${inicio.slice(0,9)}${parseInt(inicio.slice(9,10))}`).toLocaleDateString('es-MX',options);
    inicio = inicio.replace(regex,'');
    return inicio;
  }
  else{
    return null;
  }
}

export const getDateString=(date)=>{
  let inicio=date;
  inicio.setDate(inicio.getDate())
  return inicio.toISOString().slice(0,10);
}