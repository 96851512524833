
import React, { useState, Fragment } from 'react';
import '../components.css';
import ButtonNavigation from '../Viaticos/ButtonNavigation';
import NotaInput from './NotaInput';

export default function Nota() {
  const [check,setCheck]=useState(false)
  const [stateSave,setStateSave]=useState(false)
  const [state,setState]=useState({title:"",nota:""})
  const [data,setData]=useState([]);

  const onChange=({target})=>{
    setState({...state,[target.name]:target.value})
    setCheck(true)
  }

  const onClick=()=>{
    setState({title:"",nota:""})
    setData([state,...data])
    setCheck(false)
    setStateSave(true)
    console.log(data)
  }
  return (
    <Fragment>
      <h3 style={{order:2}}>Nota rápida</h3>
      <div className='div-inputs-nota'>
      <NotaInput onChange={onChange} onClick={onClick} state={state} check={check} classes="order-input"/>
        {
          data.map((state2)=>(
            <NotaInput state={state2} disabled={false}/>
          ))
        }
      </div>
      {
        stateSave?
        <div className="container-button-nota">
          <ButtonNavigation text='Registrar nota' backgroundColor='#3898EC' border='#3898EC' color='#FFF'onClick={()=>{
              console.log("hola")
            }} width="133px"/>
        </div>
        
        :null
      }
    </Fragment>
  )
}
