import React from "react";
import { DivNavigationButton } from "../StylesComponents/common-styled";
import ButtonNavigation from "../Viaticos/ButtonNavigation";
import { ArrowBackIos } from "@material-ui/icons";

import "../components.css";

export default function RegistroVisita({ name, onClick1, onClickReuniones }) {
  return (
    <div style={{ width: "100%" }} className="registro-container">
      <div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <h1 style={{ margin: "0", fontFamily: "sans-serif", fontSize: 24 }}>
            <strong>Hola, {name}</strong>
          </h1>
        </div>
        <h2 style={{ fontFamily: "sans-serif", fontSize: 18 }}>
          ¿Qué deseas registrar?
        </h2>
      </div>
      <DivNavigationButton
        style={{ alignItems: "flex-start", width: "40%" }}
        className="navigation-button-registro"
      >
        <ButtonNavigation
          text="Nuevo Reporte de Visita"
          backgroundColor="#3898EC"
          border="#3898EC"
          color="#FFF"
          onClick={onClick1}
          style={{ cursor: "pointer" }}
        />
        <ButtonNavigation
          text="Actualizar el Seguimiento de un Reporte"
          style={{ cursor: "pointer" }}
          onClick={onClickReuniones}
        />
        <ButtonNavigation
          text="Actualizar Datos de una Cedente"
          style={{ cursor: "pointer" }}
        />
      </DivNavigationButton>
    </div>
  );
}
