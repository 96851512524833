import { TextareaAutosize } from "@material-ui/core";
import React, { useState, useEffect, useContext, Fragment } from "react";
import ToggleButtons from "./ToggleButons";
import { MyContext } from "../Context";

const FormObservaciones = ({ title, cEMP_Id }) => {
  const [observations, setObservations] = useState([
    {
      cOBS_Id: "",
      cVIT_Id: "",
      cEMP_Id: cEMP_Id,
      cTOBS_Id: "",
      cOBS_Descripcion: "",
    },
  ]);

  const context = useContext(MyContext);

  const handleChange = (e) => {
    console.log(e.target.name);
    setObservations(
      observations.map((data, index) => {
        if (index === parseInt(e.target.name, 10)) {
          //Se Cambio por==
          console.log("adentro");
          data = { ...data, cOBS_Descripcion: e.target.value };
        }
        return data;
      })
    );
    sessionStorage.setItem(
      "observations",
      JSON.stringify(
        observations.map((data, index) => {
          if (index == e.target.name) {
            data = { ...data, cOBS_Descripcion: e.target.value };
          }
          return data;
        })
      )
    );
  };

  useEffect(() => {
    if (context.state.viaticosProps.cREU_Id !== "") {
      if (context.state.viaticosProps.observaciones.length === 0) {
        setObservations([
          {
            cOBS_Id: "",
            cVIT_Id: "",
            cEMP_Id: cEMP_Id,
            cTOBS_Id: "",
            cOBS_Descripcion: "",
          },
        ]);
      } else {
        setObservations([
          ...context.state.viaticosProps.observaciones,
          {
            cOBS_Id: "",
            cVIT_Id: "",
            cEMP_Id: cEMP_Id,
            cTOBS_Id: "",
            cOBS_Descripcion: "",
          },
        ]);
      }
    }
  }, []);

  return (
    <Fragment>
      {observations.map((data, index) => {
        return (
          <Fragment>
            <div style={{ marginTop: "20px" }}>
              <h3 style={{ textAlign: "left" }}>{title}</h3>
            </div>
            {index < observations.length - 1 ? (
              <TextareaAutosize
                rowsMin={10}
                style={{ width: "90%" }}
                id="text-mensaje"
                name={index}
                onChange={handleChange}
                value={data.cOBS_Descripcion}
                placeholder="Escribe comentarios del viaje ..."
                disabled
              />
            ) : (
              <Fragment>
                <TextareaAutosize
                  rowsMin={10}
                  style={{ width: "90%" }}
                  id="text-mensaje"
                  name={index}
                  onChange={handleChange}
                  value={data.cOBS_Descripcion}
                  placeholder="Escribe comentarios del viaje ..."
                />
              </Fragment>
            )}
          </Fragment>
        );
      })}
    </Fragment>
  );
};

export default FormObservaciones;
