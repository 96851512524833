import React from 'react';
import { ContainerTwoInputs } from '../StylesComponents/common-styled';
import {  Typography } from '@material-ui/core';


const FormTypography=({text,children,direction,margin})=>{
  return(
    <ContainerTwoInputs flexDirection={direction ||'column'} margin={margin}>
    <Typography>{text}</Typography>
      {children}
    </ContainerTwoInputs>
  );
}

export default FormTypography;